import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';
import { Button } from 'reactstrap';

import AcceptNominatedTradeMutation from 'src/mutations/AcceptNominatedTradeMutation';
import CancelNominatedTradeMutation from 'src/mutations/CancelNominatedTradeMutation';
import CloseNominatedTradeMutation from 'src/mutations/CloseNominatedTradeMutation';
import RejectNominatedTradeMutation from 'src/mutations/RejectNominatedTradeMutation';
import FlashesStore from 'src/stores/FlashesStore';
import {
  TRADE_RULE_ACCEPT, TRADE_RULE_CANCEL, TRADE_RULE_CLOSE, TRADE_RULE_REJECT,
  TRADE_RULE_STATE_ACCEPTED, TRADE_RULE_STATE_CANCELLED, TRADE_RULE_STATE_CLOSED,
  TRADE_RULE_STATE_PROPOSED, TRADE_RULE_STATE_REJECTED,
  TRADE_TYPE_CONTRACTED, TRADE_TYPE_NOMINATED, TRADE_TYPE_RESIDUAL,
} from 'src/util/constants';

import TradeRuleTimelineStepAccepted from './TradeRuleTimelineStepAccepted';
import TradeRuleTimelineStepCancelled from './TradeRuleTimelineStepCancelled';
import TradeRuleTimelineStepClosed from './TradeRuleTimelineStepClosed';
import TradeRuleTimelineStepProposed from './TradeRuleTimelineStepProposed';
import TradeRuleTimelineStepRejected from './TradeRuleTimelineStepRejected';
import { getTradeRuleState, tradeRuleActions } from '../TradeRuleHelpers';

class TradeRuleTimeline extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      processing: false,
    };
  }

  // eslint-disable-next-line class-methods-use-this -- consistency in handling mutations.
  handleNominatedTradeMutationFailure = (error) => {
    this.setState({ processing: false });
    FlashesStore.flash(FlashesStore.ERROR, error);
  };

  // eslint-disable-next-line class-methods-use-this -- consistency in handling mutations.
  handleCancelNominatedTradeMutationSuccess = (response, formatMessage) => {
    this.setState({ processing: false });
    const { uuid } = response.cancelNominatedTrade;

    FlashesStore.flash(
      FlashesStore.SUCCESS,
      formatMessage({ id: 'trade_rule.trade_rule_timeline.trade_rule_cancelled.response_message.success', defaultMessage: 'Cancelled peer-to-peer trade rule proposal with id: {uuid}.' }, { uuid }),
    );
  };

  // eslint-disable-next-line class-methods-use-this -- consistency in handling mutations.
  handleRejectNominatedTradeMutationSuccess = (response, formatMessage) => {
    this.setState({ processing: false });
    const { uuid } = response.rejectNominatedTrade;

    FlashesStore.flash(
      FlashesStore.SUCCESS,
      formatMessage({ id: 'trade_rule.trade_rule_timeline.trade_rule_rejected.response_message.success', defaultMessage: 'Rejected peer-to-peer trade rule proposal with id: {uuid}.' }, { uuid }),
    );
  };

  // eslint-disable-next-line class-methods-use-this -- consistency in handling mutations.
  handleAcceptNominatedTradeMutationSuccess = (response, formatMessage) => {
    this.setState({ processing: false });
    const { uuid } = response.acceptNominatedTrade;

    FlashesStore.flash(
      FlashesStore.SUCCESS,
      formatMessage({ id: 'trade_rule.trade_rule_timeline.trade_rule_accepted.response_message.success', defaultMessage: 'Accepted peer-to-peer trade rule proposal with id: {uuid}.' }, { uuid }),
    );
  };

  // eslint-disable-next-line class-methods-use-this -- consistency in handling mutations.
  handleCloseNominatedTradeMutationSuccess = (response, formatMessage) => {
    this.setState({ processing: false });
    const { uuid } = response.closeNominatedTrade;

    FlashesStore.flash(
      FlashesStore.SUCCESS,
      formatMessage({ id: 'trade_rule.trade_rule_timeline.trade_rule_closed.response_message.success', defaultMessage: 'Closed peer-to-peer trade rule with id: {uuid}.' }, { uuid }),
    );
  };

  tradeRuleAction = (rule, action, formatMessage, opts = {}) => {
    const { id, uuid } = rule;
    const tradeRuleIdSet = { id, uuid };
    switch (action) {
      case TRADE_RULE_CANCEL:
        this.setState({ processing: true });
        CancelNominatedTradeMutation(
          tradeRuleIdSet,
          this.handleCancelNominatedTradeMutationSuccess,
          this.handleNominatedTradeMutationFailure,
          formatMessage,
        );
        break;
      case TRADE_RULE_REJECT:
        this.setState({ processing: true });
        RejectNominatedTradeMutation(
          tradeRuleIdSet,
          this.handleRejectNominatedTradeMutationSuccess,
          this.handleNominatedTradeMutationFailure,
          formatMessage,
        );
        break;
      case TRADE_RULE_ACCEPT:
        this.setState({ processing: true });
        AcceptNominatedTradeMutation(
          { ...tradeRuleIdSet, tradePointId: opts.tradePointId },
          this.handleAcceptNominatedTradeMutationSuccess,
          this.handleNominatedTradeMutationFailure,
          formatMessage,
        );
        break;
      case TRADE_RULE_CLOSE:
        this.setState({ processing: true });
        CloseNominatedTradeMutation(
          tradeRuleIdSet,
          this.handleCloseNominatedTradeMutationSuccess,
          this.handleNominatedTradeMutationFailure,
          formatMessage,
        );
        return;
      default:
        FlashesStore.flash(
          FlashesStore.ERROR,
          formatMessage({ id: 'trade_rule.trade_rule_timeline.response_message.error', defaultMessage: 'Action {action} is not possible.' }, { action }),
        );
    }
  };

  /**
   * Generates the appropriate trade rule action buttons.
   * @param {object} rule - The trade rule object containing details about the trade rule.
   * @param {object} metersMap - A map of trade point IDs to meter objects.
   * @param {string} tradeRuleState - The current state of the trade rule.
   * @param {Function} formatMessage - A function to format internationalized messages.
   * @returns {React.ReactComponentElement} - Buttons to render
   */

  tradeRuleActionButton = (rule, metersMap, tradeRuleState, formatMessage) => {
    const {
      tradeType, buyer, seller, proposedBy,
    } = rule || {};
    const { processing } = this.state;
    const trader = (buyer.tradePoint.uuid in metersMap) ? buyer : seller;
    let buttons;
    if (tradeType === TRADE_TYPE_NOMINATED) {
      if (tradeRuleState === TRADE_RULE_STATE_PROPOSED) {
        if (proposedBy.uuid === trader.user.uuid) {
          buttons = (
            <Button color="primary" onClick={() => this.tradeRuleAction(rule, TRADE_RULE_CANCEL, formatMessage)} disabled={processing}>
              <FormattedMessage id="trade_rule.trade_rule_timeline.cancel_trade.label" defaultMessage="Cancel trade rule" />
            </Button>
          );
        }
      } else if (tradeRuleState === TRADE_RULE_STATE_ACCEPTED) {
        buttons = (
          <Button color="primary" onClick={() => this.tradeRuleAction(rule, TRADE_RULE_CLOSE, formatMessage)} disabled={processing}>
            <FormattedMessage id="trade_rule.trade_rule_timeline.close_trade.label" defaultMessage="Close trade rule" />
          </Button>
        );
      }
    } else if (tradeType === TRADE_TYPE_CONTRACTED || tradeType === TRADE_TYPE_RESIDUAL) {
      if (tradeRuleState === TRADE_RULE_STATE_PROPOSED
        || tradeRuleState === TRADE_RULE_STATE_ACCEPTED) {
        buttons = <FormattedMessage id="trade_rule.trade_rule_timeline.no_actions.label" defaultMessage="'No actions available'" />;
      }
    }

    return tradeRuleActions(buttons);
  };

  render() {
    const { intl, property, rule } = this.props;
    const { formatMessage } = intl;

    // Build a map of tradePointId => Meter for display in the trade rule timeline event
    const { meters, timezone } = property;
    const { edges: metersEdges } = meters;
    const metersMap = {};
    metersEdges.forEach(({ node }) => {
      metersMap[node.tradePointId] = node;
    });

    // Extract the trade rule information required for each event
    const {
      uuid, tradeType, clauses, buyer, seller, proposedAt, proposedBy, acceptedAt, acceptedBy,
      rejectedAt, rejectedBy, cancelledAt, cancelledBy, closedAt, closedBy,
    } = rule;

    const tradeRuleState = getTradeRuleState(rule);

    const buttons = this.tradeRuleActionButton(rule, metersMap, tradeRuleState, formatMessage);

    return (
      <ol className="m-0 p-0">
        <TradeRuleTimelineStepProposed
          buyer={buyer}
          clauses={clauses}
          hasNextStep={tradeRuleState !== TRADE_RULE_STATE_PROPOSED || !!buttons}
          isCurrentStep={tradeRuleState === TRADE_RULE_STATE_PROPOSED}
          ruleId={uuid}
          seller={seller}
          timestamp={proposedAt}
          timezone={timezone}
          tradeType={tradeType}
          user={proposedBy}
        />
        {acceptedAt && (
          <TradeRuleTimelineStepAccepted
            buyer={buyer}
            clauses={clauses}
            hasNextStep={tradeRuleState !== TRADE_RULE_STATE_ACCEPTED || !!buttons}
            isCurrentStep={tradeRuleState === TRADE_RULE_STATE_ACCEPTED}
            metersMap={metersMap}
            propertyTimeZone={timezone}
            ruleId={uuid}
            seller={seller}
            timestamp={acceptedAt}
            tradeType={tradeType}
            user={acceptedBy}
          />
        )}
        {closedAt && (
          <TradeRuleTimelineStepClosed
            buyer={buyer}
            isCurrentStep={tradeRuleState === TRADE_RULE_STATE_CLOSED}
            hasNextStep={tradeRuleState !== TRADE_RULE_STATE_CLOSED || !!buttons}
            metersMap={metersMap}
            propertyTimeZone={timezone}
            ruleId={uuid}
            seller={seller}
            timestamp={closedAt}
            tradeType={tradeType}
            user={closedBy}
          />
        )}
        {cancelledAt && (
          <TradeRuleTimelineStepCancelled
            isCurrentStep={tradeRuleState === TRADE_RULE_STATE_CANCELLED}
            hasNextStep={tradeRuleState !== TRADE_RULE_STATE_CANCELLED || !!buttons}
            propertyTimeZone={timezone}
            ruleId={uuid}
            timestamp={cancelledAt}
            tradeType={tradeType}
            user={cancelledBy}
          />
        )}
        {rejectedAt && (
          <TradeRuleTimelineStepRejected
            isCurrentStep={tradeRuleState === TRADE_RULE_STATE_REJECTED}
            hasNextStep={tradeRuleState !== TRADE_RULE_STATE_REJECTED || !!buttons}
            timezone={timezone}
            ruleId={uuid}
            tradeType={tradeType}
            timestamp={rejectedAt}
            user={rejectedBy}
          />
        )}
        {buttons}
      </ol>
    );
  }
}

TradeRuleTimeline.propTypes = {
  property: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  rule: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  intl: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default injectIntl(createFragmentContainer(
  TradeRuleTimeline,
  {
    property: graphql`
      fragment TradeRuleTimeline_property on Property {
      uuid
      title
      timezone
        address {
        line1
        line2
        city
        state
        postcode
          gps {
          latitude
          longitude
        }
      }
        meters {
          edges {
            node {
            uuid
            identifier
            title
            tradePointId
          }
        }
      }
    }
    `,
    rule: graphql`
      fragment TradeRuleTimeline_rule on TradeRule {
      id
      uuid
      tradeType
        buyer {
          user { uuid email givenName familyName }
          community { uuid title }
        residualId
          tradePoint {
          uuid
          type
            meter {
            uuid
            identifier
            title
              property {
              uuid
              title
            }
          }
        }
      }
        seller {
          user { uuid email givenName familyName }
          community { uuid title }
        residualId
          tradePoint {
          uuid
          type
            meter {
            uuid
            identifier
            title
              property {
              uuid
              title
            }
          }
        }
      }
        clauses {
          edges {
            node {
            monthsOfYear
            daysOfWeek
              timesOfDay {
                start  { hours minutes seconds }
                finish { hours minutes seconds }
            }
            ignoreDaylightSavings
            ignorePublicHolidays
            price
            timezone
          }
        }
      }
      proposedAt
      proposedBy { uuid email givenName familyName }
      acceptedAt
      acceptedBy { uuid email givenName familyName }
      rejectedAt
      rejectedBy { uuid email givenName familyName }
      cancelledAt
      cancelledBy { uuid email givenName familyName }
      closedAt
      closedBy { uuid email givenName familyName }
    }
    `,
  },
));
