import { Fragment } from 'react';
import { commitMutation, graphql } from 'react-relay';

import environment from 'src/util/environment';

const mutation = graphql`
  mutation CancelNominatedTradeMutation($uuid: ID!) {
    cancelNominatedTrade(uuid: $uuid) {
      id
      uuid
      cancelledAt
      cancelledBy {
        uuid
        email
        givenName
        familyName
      }
    }
  }
`;

/**
 * Cancel nominated trade mutation.
 * @param {object} tradeRuleIdSet id and UUID of the trade rule to be cancelled.
 * @param {Function} onCompleted Function to call on success.
 * @param {Function} onError Function to call on error.
 * @param {string|Fragment} formatMessage message to return?
 */
const CancelNominatedTradeMutation = (tradeRuleIdSet, onCompleted, onError, formatMessage) => {
  const { id, uuid } = tradeRuleIdSet || {};

  commitMutation(
    environment,
    {
      mutation,
      variables: { uuid },
      onCompleted: (response) => { onCompleted(response, formatMessage); },
      onError,
      updater: (store) => {
        // Get the current trade rule record from the store.
        const ruleProxy = store.get(id);
        ruleProxy.setValue('TRADE_RULE_STATE_CANCELLED', 'state');
      },
    },
  );
};

export default CancelNominatedTradeMutation;
