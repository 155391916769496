import Big from 'big.js';
import { DateTime } from 'luxon';
import React from 'react';

import {
  buildTradeViewKey, byAggregatedVolumeFunc,
  byTradeTypeAndAggregatedVolumeFunc, convertToNumber, getCounterParty,
  getDirection, getTraderProperty, hasVolume, sumValuesByObjKeys, timestampToIsoString,
} from 'src/components/Dashboard/helpers/common';
import { CardsLabel, meterIdentifier } from 'src/enosikit/components/Chart/helpers/cards';
import {
  BUY, CARBON, DATA_AGGREGATE_BY_METER, DATA_AGGREGATE_BY_PROPERTY,
  DATA_GROUP_BY_COUNTERPARTY, DATA_GROUP_BY_TRADE_TYPE, DIRECTIONS, FLAGS,
  IDENTIFIER, TRADE_TYPE_COMMUNITY, TRADE_TYPE_CONTRACTED, TRADE_TYPE_NOMINATED,
  TRADE_TYPE_RESIDUAL, UNTRADED_ENERGY_KEY, VALUE,
} from 'src/util/constants';
import username from 'src/util/decorators/username';
import { getTradeTypeLabel } from 'src/util/i18n/helpers';
import { isNumber } from 'src/util/math';
import getTradeType from 'src/util/trade';

const DEFAULT_DISPLAY_VALUE = { default: 0 };

/**
 * Renders the label for the meter cards in the property dashboard.
 * @param {string} title - The title to display.
 * @param {string} identifier - The identifier to display.
 * @returns {React.ReactElement} - The rendered meter content property label.
 */
export const meterViewCardLabel = (title, identifier) => {
  if (!title && !identifier) {
    return null;
  }

  if (!title) {
    return <h6>{identifier}</h6>;
  }

  if (!identifier) {
    return (
      <h5 style={{ color: '#adb5bd' }} className="mb-2">
        {title}
      </h5>
    );
  }

  return (
    <>
      <h5 style={{ color: '#adb5bd' }} className="mb-2">
        {title}
      </h5>
      <h6>{identifier}</h6>
    </>
  );
};

/**
 * Returns the aggregated value of the given key and valueKey
 * @param {object} data
 * @param {string} key
 * @param {string} valueKey
 * @returns {Array} - aggregated value of the given key and valueKey
 */
export const aggregateValuesByObjKeys = (data, key, valueKey) => {
  if (!data || !key || !valueKey) return [];

  return (data.reduce(
    (acc, element) => {
      if (!element[key]) return acc;
      element[key].forEach((el) => {
        if (acc.map((x) => x[valueKey]).indexOf(el[valueKey]) === -1) {
          acc.push(el);
        }
      });
      return acc;
    },
    [],
  ));
};

// Meter data - helpers

/**
 * Builds chart meter data for aggregated view.
 * @param {object} mainData - chart primary data.
 * @param {string} timezone
 * @returns {object} - meter data (timestamp based).
 */
export const buildMeterViewChartDataAggregated = (mainData, timezone) => {
  const finalResp = {};
  if (!mainData) return finalResp;
  let value = Big(0);
  let carbon = Big(0);

  DIRECTIONS.forEach((dir) => {
    finalResp[dir] = {};
    let flags = [];
    if (mainData[dir]) {
      Object.keys(mainData[dir].data).forEach((timestamp) => {
        const { meterDataAggregates } = mainData[dir].data[timestamp];
        if (Object.keys(meterDataAggregates).length === 1) {
          const meterId = Object.keys(meterDataAggregates)[0];
          carbon = Big(meterDataAggregates[meterId].carbon);
          value = meterDataAggregates[meterId].value;
          flags = meterDataAggregates[meterId].flags;
        }
        if (Object.keys(meterDataAggregates).length > 1) {
          value = sumValuesByObjKeys(Object.values(meterDataAggregates), VALUE);
          carbon = sumValuesByObjKeys(Object.values(meterDataAggregates), CARBON);
          flags = aggregateValuesByObjKeys(Object.values(meterDataAggregates), FLAGS, IDENTIFIER);
        }

        const formattedTimestamp = timestampToIsoString(timestamp);
        const meterDataSeries = {
          timestamp: DateTime.fromISO(formattedTimestamp, { zone: timezone }),
          value: Number(value),
          flags,
          carbon,
        };
        finalResp[dir][formattedTimestamp] = meterDataSeries;
      });
    }
  });

  return finalResp;
};

/**
 * Builds chart meter data for non aggregated view.
 * @param {object} mainData - main data object
 * @param {string} timezone
 * @returns {object} - object expected byt chart meter for the non aggregated view.
 */
export const buildMeterViewChartDataByMeter = (mainData, timezone) => {
  const finalResp = {};

  if (!mainData) return finalResp;

  Object.values(mainData.meters)?.forEach((meter) => {
    const { uuid: meterId, title, identifier } = meter;

    DIRECTIONS.forEach((dir) => {
      if (!finalResp[meterId]) {
        finalResp[meterId] = {
          buy: {},
          sell: {},
          title,
          identifier,
        };
      }

      Object.keys(mainData[dir].data).forEach((timestamp) => {
        const dataPoint = mainData[dir].data[timestamp];
        const { meterDataAggregates } = dataPoint;
        const { value, flags, carbon } = meterDataAggregates[meterId] || {};
        if (!flags) return;
        const formattedTimestamp = new Date(Number(timestamp)).toISOString();
        const timestampData = {
          timestamp: DateTime.fromISO(formattedTimestamp, { zone: timezone }),
          value,
          flags,
          carbon: Big(carbon),
        };

        finalResp[meterId][dir][formattedTimestamp] = timestampData;
      });
    });
  });
  return finalResp;
};

/**
 * Energy origin label when grouped by counterparty.
 * @param {import('react-intl').IntlShape} intl
 * @param {string | null} key
 * @returns {string | null} - translated label, or key or null
 */
export const getEnergyOriginByCounterpartyLabel = (intl, key) => {
  if (!key || !intl) return null;

  if ([TRADE_TYPE_COMMUNITY, TRADE_TYPE_RESIDUAL, UNTRADED_ENERGY_KEY].includes(key)) {
    return getTradeTypeLabel(intl, key);
  }

  return key;
};

/**
 * Energy origin label when grouped by trade type.
 * @param {import('react-intl').IntlShape} intl
 * @param {string | null} key
 * @returns {string | null} - translated label, or key or null
 */
export const getEnergyOriginByTradeTypeLabel = (intl, key) => {
  if (!key || !intl) return null;

  return getTradeTypeLabel(intl, key);
};

/**
 * Returns the translated label for Property's cards.
 * @param {import('react-intl').IntlShape} intl - The intl object for translation.
 * @param {TRADE_TYPE_CONTRACTED | TRADE_TYPE_COMMUNITY |
 * TRADE_TYPE_NOMINATED | TRADE_TYPE_RESIDUAL |UNTRADED_ENERGY_KEY} key
 * @param {object} chartView
 * @param {DATA_AGGREGATE_BY_METER | DATA_AGGREGATE_BY_PROPERTY} chartView.aggregateBy
 * @param {DATA_GROUP_BY_COUNTERPARTY| DATA_GROUP_BY_TRADE_TYPE} chartView.groupBy
 * @returns {string | null} - The translated label.
 */
export const getEnergyOriginLabel = (intl, key, chartView) => {
  const { groupBy } = chartView;
  if (!intl || !key || !groupBy) return null;

  if (groupBy === DATA_GROUP_BY_TRADE_TYPE) {
    return getEnergyOriginByTradeTypeLabel(intl, key);
  }

  return getEnergyOriginByCounterpartyLabel(intl, key);
};

/**
 * Enrich the label with the property data if set.
 * @param {import('react-intl').IntlShape} intl - i18n react-intl
 * @param {object} cardDatum - provide data for rendering the label.
 * @param {string} cardDatum.title - title of the property or the meter
 * @param {string} cardDatum.identifier - meter identifier
 * @param {object} cardDatum.property - property object
 * @param {string} cardDatum.label - trade type or property address
 * @param {string} cardDatum.subLabel - counterparty name
 * @param {object} chartView - grouping and aggregation options for the chart.
 * @param {DATA_AGGREGATE_BY_PROPERTY | DATA_AGGREGATE_BY_METER} chartView.aggregateBy
 * @param {DATA_GROUP_BY_COUNTERPARTY | DATA_GROUP_BY_TRADE_TYPE} chartView.groupBy
 * @returns {React.ReactElement} - property label container.
 */
export const tradeViewCardLabel = (
  intl,
  cardDatum,
  chartView,
) => {
  const { groupBy } = chartView;
  const {
    title, identifier, property, label, subLabel,
  } = cardDatum;
  const meterLabel = title !== undefined && `${title}${identifier && meterIdentifier(title, identifier)}`;
  const energyOriginLabel = getEnergyOriginLabel(intl, label, chartView);

  if (!property || groupBy === DATA_GROUP_BY_TRADE_TYPE) {
    return (
      <>
        {meterLabel && <CardsLabel>{meterLabel}</CardsLabel>}
        <div>{energyOriginLabel}</div>
      </>
    );
  }

  const { uuid } = property;

  return (
    <>
      {meterLabel && <CardsLabel>{meterLabel}</CardsLabel>}
      <a href={`/properties/${uuid}`}>{energyOriginLabel}</a>
      <div className="mt-2">{subLabel}</div>
    </>
  );
};

/**
 * Build and returns the chart meter data.
 * @param {object} mainData - chart primary data.
 * @param {string} timezone
 * @param {object} chartView
 * @returns {object} - chart meter data.
 */
export const buildMeterViewChartData = (mainData, timezone, chartView) => {
  if (!mainData || !chartView) {
    return {};
  }

  const { aggregateBy } = chartView;
  switch (aggregateBy) {
    case DATA_AGGREGATE_BY_METER:
      return buildMeterViewChartDataByMeter(mainData, timezone);
    case DATA_AGGREGATE_BY_PROPERTY:
      return buildMeterViewChartDataAggregated(mainData, timezone);
    default:
      console.error(`aggregate by '${aggregateBy}' is not valid for the property`);
      return {};
  }
};

/**
 * Conslidate all the trades.
 * @param {object} tradeData
 * @returns {object} - consolidated trades.
 */
export const consolidateTrades = (tradeData) => {
  const { buy: tradeBuy, sell: tradeSell } = tradeData || {};
  const resp = {
    buy: [
      ...Object.values(tradeBuy.contracted),
      ...Object.values(tradeBuy.nominated),
      ...Object.values(tradeBuy.community),
      ...Object.values(tradeBuy.residual),
      ...Object.values(tradeBuy.untraded),
    ],
    sell: [
      ...Object.values(tradeSell.contracted),
      ...Object.values(tradeSell.nominated),
      ...Object.values(tradeSell.community),
      ...Object.values(tradeSell.residual),
      ...Object.values(tradeSell.untraded),
    ],
  };
  return resp;
};

/**
 * Get the entity id, falling to the default value in its absence.
 * @param {TRADE_TYPE_CONTRACTED| TRADE_TYPE_NOMINATED
 * | TRADE_TYPE_COMMUNITY} tradeType The trade type.
 * @param {object} counterParty The counter party object.
 * @param {object} counterParty.user The counter party user.
 * @param {object} counterParty.tradePoint The counter party trade point.
 * @param {string} dataKey The trade type.
 * @param {boolean} groupByCounterParty Whether it's grouped by counterparty or trade type.
 * @returns {{label: string, key: string, sublabel: string | null, user: object, property: object,
 * data: object} | object} Data object for the dashboard cards.
 */
export const getDataObject = (tradeType, counterParty, dataKey, groupByCounterParty) => {
  if (!tradeType) {
    return {};
  }

  const { user } = counterParty || {};
  const property = getTraderProperty(counterParty);

  let label = username(user);
  let subLabel = null;

  if (property) {
    subLabel = label;
    label = property.title;
  }

  switch (tradeType) {
    case TRADE_TYPE_CONTRACTED:
      if (groupByCounterParty) {
        return {
          label, subLabel, key: dataKey, user, property, data: {}, tradeType,
        };
      }

      return {
        label: TRADE_TYPE_CONTRACTED,
        key: dataKey,
        subLabel: null,
        user: null,
        property: null,
        data: {},
        tradeType,
      };
    case TRADE_TYPE_NOMINATED:
      if (groupByCounterParty) {
        return {
          label, subLabel, key: dataKey, user, property, data: {}, tradeType,
        };
      }

      return {
        label: TRADE_TYPE_NOMINATED,
        key: dataKey,
        subLabel: null,
        user: null,
        property: null,
        data: {},
        tradeType,
      };

    case TRADE_TYPE_COMMUNITY:
      return {
        label: TRADE_TYPE_COMMUNITY,
        key: dataKey,
        subLabel: null,
        user: null,
        property: null,
        data: {},
        tradeType,
      };
    default: // TRADE_TYPE_RESIDUAL
      return {
        label: TRADE_TYPE_RESIDUAL,
        key: dataKey,
        subLabel: null,
        user: null,
        property: null,
        data: {},
        tradeType,
      };
  }
};

/**
 * Prepares the chart data grouped by counterparty and aggregated by meter
 * @param {object} mainData
 * @param {string} timezone
 * @returns {object} chart data.
 */
export const buildTradeViewChartDataByMeterAndCounterParty = (mainData, timezone) => {
  const resp = {
    buy: {
      contracted: {},
      nominated: {},
      community: {},
      residual: {},
      summary: { value: 0, volume: 0, carbon: 0 },
      untraded: {},
    },
    sell: {
      contracted: {},
      nominated: {},
      community: {},
      residual: {},
      summary: { value: 0, volume: 0, carbon: 0 },
      untraded: {},
    },
  };
  if (!mainData) {
    return resp;
  }
  const chartView = {
    aggregateBy: DATA_AGGREGATE_BY_METER,
    groupBy: DATA_GROUP_BY_COUNTERPARTY,
  };
  const { meters = {} } = mainData;

  DIRECTIONS.forEach((dir) => {
    if (!mainData[dir].data) {
      return;
    }

    Object.keys(mainData[dir].data)?.forEach((timestamp) => {
      const { tradeSetSummaries, untradedDataAggregates } = mainData[dir].data[timestamp] || {};
      if (!tradeSetSummaries) {
        return;
      }
      const date = timestampToIsoString(timestamp);
      Object.keys(tradeSetSummaries)?.forEach((ruleId) => {
        const trade = tradeSetSummaries[ruleId] || {};
        const {
          meterId, type, carbon = 0, value = 0, volume = 0,
        } = trade || {};
        const { aggregation } = meters[meterId] || {};

        if (!type) {
          return;
        }

        const tradeType = getTradeType(type);
        const { rules } = mainData[dir];
        const rule = rules[ruleId] || {};
        const counterParty = getCounterParty(rule, type, getDirection(dir));
        const counterPartyProperty = getTraderProperty(counterParty);
        const t = resp[dir][tradeType];
        const dataKey = buildTradeViewKey(
          meterId,
          chartView,
          tradeType,
          {
            counterParty,
            counterPartyProperty,
            ruleId,
          },
        );
        if (!(dataKey in t)) {
          t[dataKey] = getDataObject(type, counterParty, dataKey, true);
        }

        // Add counterparty property data if available
        t[dataKey].property = counterPartyProperty;

        // Add meter details
        const { buyer, seller } = rule || {};
        const trader = dir === BUY ? buyer : seller;
        const { title, identifier } = trader?.tradePoint?.meter || {};
        t[dataKey].meter = { title, identifier };

        const dataSeries = t[dataKey].data;
        dataSeries[date] ||= {
          interval: {
            timestamp: DateTime.fromMillis(
              parseFloat(timestamp),
              { zone: timezone },
            ),
            length: aggregation,
          },
          value: 0,
          volume: 0,
          carbon: 0,
        };

        const aggregateCarbonByDate = Big(dataSeries[date].carbon).plus(carbon);
        const aggregateValueByDate = Big(dataSeries[date].value).plus(value);
        const aggregateVolumeByDate = Big(dataSeries[date].volume).plus(volume);

        dataSeries[date].carbon = Number(aggregateCarbonByDate);
        dataSeries[date].value = Number(aggregateValueByDate);
        dataSeries[date].volume = Number(aggregateVolumeByDate);

        const aggregateCarbonSummary = Big(resp[dir].summary.carbon).plus(carbon);
        const aggregateValueSummary = Big(resp[dir].summary.value).plus(value);
        const aggregateVolumeSummary = Big(resp[dir].summary.volume).plus(volume);

        resp[dir].summary.carbon = Number(aggregateCarbonSummary);
        resp[dir].summary.value = Number(aggregateValueSummary);
        resp[dir].summary.volume = Number(aggregateVolumeSummary);
      });

      Object.keys(untradedDataAggregates)?.forEach((meterId) => {
        const {
          carbon = 0, value = 0, volume = 0,
        } = untradedDataAggregates[meterId] || {};
        const finalCarbon = convertToNumber(carbon, DEFAULT_DISPLAY_VALUE);
        const finalValue = convertToNumber(value, DEFAULT_DISPLAY_VALUE);
        const finalVolume = convertToNumber(volume, DEFAULT_DISPLAY_VALUE);
        const key = `${UNTRADED_ENERGY_KEY}_${meterId}`;
        const { aggregation, identifier, title } = meters[meterId] || {};
        if (!resp[dir][UNTRADED_ENERGY_KEY][key]) {
          resp[dir][UNTRADED_ENERGY_KEY][key] = {
            data: {},
            meter: { title, identifier },
            label: UNTRADED_ENERGY_KEY,
            tradeType: UNTRADED_ENERGY_KEY,
            key,
          };
        }

        resp[dir][UNTRADED_ENERGY_KEY][key].data[date] ||= {
          interval: {
            timestamp: DateTime.fromMillis(
              parseFloat(timestamp),
              { zone: timezone },
            ),
            length: aggregation,
          },
          value: 0,
          volume: 0,
          carbon: 0,

        };
        const datum = resp[dir][UNTRADED_ENERGY_KEY][key].data[date];
        const aggregateCarbonByDate = Big(datum.carbon).plus(finalCarbon);
        const aggregateValueByDate = Big(datum.value).plus(finalValue);
        const aggregateVolumeByDate = Big(datum.volume).plus(finalVolume);

        datum.carbon = Number(aggregateCarbonByDate);
        datum.value = Number(aggregateValueByDate);
        datum.volume = Number(aggregateVolumeByDate);
      });
    });
  });
  DIRECTIONS.forEach((dir) => {
    Object.keys(resp[dir][UNTRADED_ENERGY_KEY]).forEach((key) => {
      const { data } = resp[dir][UNTRADED_ENERGY_KEY][key] || {};
      if (hasVolume(data)) { return; }

      delete (resp[dir][UNTRADED_ENERGY_KEY][key]);
    });
  });
  return resp;
};

/**
 * Prepares the chart data grouped by trade type and aggregated by meter
 * @param {object} mainData
 * @param {string} timezone
 * @returns {object} chart data.
 */
export const buildTradeViewChartDataByMeterAndTradeType = (mainData, timezone) => {
  const resp = {
    buy: {
      contracted: {},
      nominated: {},
      community: {},
      residual: {},
      summary: { value: 0, volume: 0, carbon: 0 },
      untraded: {},
    },
    sell: {
      contracted: {},
      nominated: {},
      community: {},
      residual: {},
      summary: { value: 0, volume: 0, carbon: 0 },
      untraded: {},
    },
  };
  if (!mainData) {
    return resp;
  }
  const chartView = { aggregateBy: DATA_AGGREGATE_BY_METER, groupBy: DATA_GROUP_BY_TRADE_TYPE };
  const { meters = {} } = mainData;

  DIRECTIONS.forEach((dir) => {
    if (!mainData[dir].data) {
      return;
    }

    Object.keys(mainData[dir].data)?.forEach((timestamp) => {
      const { tradeSetSummaries, untradedDataAggregates } = mainData[dir].data[timestamp] || {};
      if (!tradeSetSummaries) {
        return;
      }
      const date = timestampToIsoString(timestamp);
      Object.keys(tradeSetSummaries)?.forEach((ruleId) => {
        const trade = tradeSetSummaries[ruleId] || {};
        const {
          meterId, type, carbon = 0, value = 0, volume = 0,
        } = trade || {};
        const { aggregation } = meters[meterId] || {};

        if (!type) {
          return;
        }

        const tradeType = getTradeType(type);
        const { rules } = mainData[dir];
        const rule = rules[ruleId] || {};
        const counterParty = getCounterParty(rule, type, getDirection(dir));
        const counterPartyProperty = getTraderProperty(counterParty);
        const t = resp[dir][tradeType];

        const dataKey = buildTradeViewKey(
          meterId,
          chartView,
          tradeType,
          {},
        );

        if (!(dataKey in t)) {
          t[dataKey] = getDataObject(type, counterParty, dataKey, false);
        }

        // Add counterparty property data if available
        t[dataKey].property = counterPartyProperty;

        // Add meter details
        const { buyer, seller } = rule || {};
        const trader = dir === BUY ? buyer : seller;
        const { title, identifier } = trader?.tradePoint?.meter || {};
        t[dataKey].meter = { title, identifier };

        const dataSeries = t[dataKey].data;
        dataSeries[date] ||= {
          interval: {
            timestamp: DateTime.fromMillis(
              parseFloat(timestamp),
              { zone: timezone },
            ),
            length: aggregation,
          },
          value: 0,
          volume: 0,
          carbon: 0,
        };

        const aggregateCarbonByDate = Big(dataSeries[date].carbon).plus(carbon);
        const aggregateValueByDate = Big(dataSeries[date].value).plus(value);
        const aggregateVolumeByDate = Big(dataSeries[date].volume).plus(volume);

        dataSeries[date].carbon = Number(aggregateCarbonByDate);
        dataSeries[date].value = Number(aggregateValueByDate);
        dataSeries[date].volume = Number(aggregateVolumeByDate);

        const aggregateCarbonSummary = Big(resp[dir].summary.carbon).plus(carbon);
        const aggregateValueSummary = Big(resp[dir].summary.value).plus(value);
        const aggregateVolumeSummary = Big(resp[dir].summary.volume).plus(volume);

        resp[dir].summary.carbon = Number(aggregateCarbonSummary);
        resp[dir].summary.value = Number(aggregateValueSummary);
        resp[dir].summary.volume = Number(aggregateVolumeSummary);
      });

      Object.keys(untradedDataAggregates)?.forEach((meterId) => {
        const {
          carbon = 0, value = 0, volume = 0,
        } = untradedDataAggregates[meterId] || {};
        const finalCarbon = convertToNumber(carbon, DEFAULT_DISPLAY_VALUE);
        const finalValue = convertToNumber(value, DEFAULT_DISPLAY_VALUE);
        const finalVolume = convertToNumber(volume, DEFAULT_DISPLAY_VALUE);
        const key = `${UNTRADED_ENERGY_KEY}_${meterId}`;
        const { aggregation, identifier, title } = meters[meterId] || {};
        if (!resp[dir][UNTRADED_ENERGY_KEY][key]) {
          resp[dir][UNTRADED_ENERGY_KEY][key] = {
            data: {},
            meter: { title, identifier },
            label: UNTRADED_ENERGY_KEY,
            tradeType: UNTRADED_ENERGY_KEY,
            key,
          };
        }

        resp[dir][UNTRADED_ENERGY_KEY][key].data[date] ||= {
          interval: {
            timestamp: DateTime.fromMillis(
              parseFloat(timestamp),
              { zone: timezone },
            ),
            length: aggregation,
          },
          value: 0,
          volume: 0,
          carbon: 0,

        };
        const datum = resp[dir][UNTRADED_ENERGY_KEY][key].data[date];
        const aggregateCarbonByDate = Big(datum.carbon).plus(finalCarbon);
        const aggregateValueByDate = Big(datum.value).plus(finalValue);
        const aggregateVolumeByDate = Big(datum.volume).plus(finalVolume);

        datum.carbon = Number(aggregateCarbonByDate);
        datum.value = Number(aggregateValueByDate);
        datum.volume = Number(aggregateVolumeByDate);
      });
    });
  });
  DIRECTIONS.forEach((dir) => {
    Object.keys(resp[dir][UNTRADED_ENERGY_KEY]).forEach((key) => {
      const { data } = resp[dir][UNTRADED_ENERGY_KEY][key] || {};
      if (hasVolume(data)) { return; }

      delete (resp[dir][UNTRADED_ENERGY_KEY][key]);
    });
  });
  return resp;
};

/**
 * Prepares the chart data grouped by counterparty and aggregated by property
 * @param {object} mainData
 * @param {string} timezone
 * @returns {object} chart data.
 */
export const buildTradeViewChartDataByPropertyAndCounterParty = (mainData, timezone) => {
  const resp = {
    buy: {
      contracted: {},
      nominated: {},
      community: {},
      residual: {},
      summary: { value: 0, volume: 0, carbon: 0 },
      untraded: {},
    },
    sell: {
      contracted: {},
      nominated: {},
      community: {},
      residual: {},
      summary: { value: 0, volume: 0, carbon: 0 },
      untraded: {},
    },
  };
  if (!mainData) {
    return resp;
  }

  const chartView = {
    aggregateBy: DATA_AGGREGATE_BY_PROPERTY,
    groupBy: DATA_GROUP_BY_COUNTERPARTY,
  };
  const { meters = {}, property } = mainData;
  const { uuid: propertyId } = property;

  DIRECTIONS.forEach((dir) => {
    if (!mainData[dir].data) {
      return;
    }

    Object.keys(mainData[dir].data)?.forEach((timestamp) => {
      const { tradeSetSummaries, untradedDataAggregates } = mainData[dir].data[timestamp] || {};
      if (!tradeSetSummaries) {
        return;
      }
      const date = timestampToIsoString(timestamp);
      Object.keys(tradeSetSummaries)?.forEach((ruleId) => {
        const trade = tradeSetSummaries[ruleId] || {};
        const {
          meterId, type, carbon = 0, value = 0, volume = 0,
        } = trade || {};
        const { aggregation } = meters[meterId] || {};

        if (!type) {
          return;
        }

        const tradeType = getTradeType(type);
        const { rules } = mainData[dir];
        const rule = rules[ruleId] || {};
        const counterParty = getCounterParty(rule, type, getDirection(dir));
        const counterPartyProperty = getTraderProperty(counterParty);
        const t = resp[dir][tradeType];
        const dataKey = buildTradeViewKey(
          propertyId,
          chartView,
          tradeType,
          {
            counterParty,
            counterPartyProperty,
          },
        );
        if (!(dataKey in t)) {
          t[dataKey] = getDataObject(type, counterParty, dataKey, true);
        }

        // Add counterparty property data if available
        t[dataKey].property = counterPartyProperty;

        // Add meter details
        const { buyer, seller } = rule || {};
        const trader = dir === BUY ? buyer : seller;
        const { title, identifier } = trader?.tradePoint?.meter || {};
        t[dataKey].meter = { title, identifier };

        const dataSeries = t[dataKey].data;
        dataSeries[date] ||= {
          interval: {
            timestamp: DateTime.fromMillis(
              parseFloat(timestamp),
              { zone: timezone },
            ),
            length: aggregation,
          },
          value: 0,
          volume: 0,
          carbon: 0,
        };

        const aggregateCarbonByDate = Big(dataSeries[date].carbon).plus(carbon);
        const aggregateValueByDate = Big(dataSeries[date].value).plus(value);
        const aggregateVolumeByDate = Big(dataSeries[date].volume).plus(volume);

        dataSeries[date].carbon = Number(aggregateCarbonByDate);
        dataSeries[date].value = Number(aggregateValueByDate);
        dataSeries[date].volume = Number(aggregateVolumeByDate);

        const aggregateCarbonSummary = Big(resp[dir].summary.carbon).plus(carbon);
        const aggregateValueSummary = Big(resp[dir].summary.value).plus(value);
        const aggregateVolumeSummary = Big(resp[dir].summary.volume).plus(volume);

        resp[dir].summary.carbon = Number(aggregateCarbonSummary);
        resp[dir].summary.value = Number(aggregateValueSummary);
        resp[dir].summary.volume = Number(aggregateVolumeSummary);
      });

      Object.keys(untradedDataAggregates)?.forEach((meterId) => {
        const {
          carbon = 0, value = 0, volume = 0,
        } = untradedDataAggregates[meterId] || {};
        const finalCarbon = convertToNumber(carbon, DEFAULT_DISPLAY_VALUE);
        const finalValue = convertToNumber(value, DEFAULT_DISPLAY_VALUE);
        const finalVolume = convertToNumber(volume, DEFAULT_DISPLAY_VALUE);
        const key = UNTRADED_ENERGY_KEY;
        const { aggregation, identifier, title } = meters[meterId] || {};
        if (!resp[dir][UNTRADED_ENERGY_KEY][key]) {
          resp[dir][UNTRADED_ENERGY_KEY][key] = {
            data: {},
            meter: { title, identifier },
            label: UNTRADED_ENERGY_KEY,
            tradeType: UNTRADED_ENERGY_KEY,
            key,
          };
        }

        resp[dir][UNTRADED_ENERGY_KEY][key].data[date] ||= {
          interval: {
            timestamp: DateTime.fromMillis(
              parseFloat(timestamp),
              { zone: timezone },
            ),
            length: aggregation,
          },
          value: 0,
          volume: 0,
          carbon: 0,

        };
        const datum = resp[dir][UNTRADED_ENERGY_KEY][key].data[date];
        const aggregateCarbonByDate = Big(datum.carbon).plus(finalCarbon);
        const aggregateValueByDate = Big(datum.value).plus(finalValue);
        const aggregateVolumeByDate = Big(datum.volume).plus(finalVolume);

        datum.carbon = Number(aggregateCarbonByDate);
        datum.value = Number(aggregateValueByDate);
        datum.volume = Number(aggregateVolumeByDate);
      });
    });
  });
  DIRECTIONS.forEach((dir) => {
    Object.keys(resp[dir][UNTRADED_ENERGY_KEY]).forEach((key) => {
      const { data } = resp[dir][UNTRADED_ENERGY_KEY][key] || {};
      if (hasVolume(data)) { return; }

      delete (resp[dir][UNTRADED_ENERGY_KEY][key]);
    });
  });
  return resp;
};

/**
 * Prepares the chart data grouped by tradetype and aggregated by property
 * @param {object} mainData
 * @param {string} timezone
 * @returns {object} chart data.
 */
export const buildTradeViewChartDataByPropertyAndTradeType = (mainData, timezone) => {
  const resp = {
    buy: {
      contracted: {},
      nominated: {},
      community: {},
      residual: {},
      summary: { value: 0, volume: 0, carbon: 0 },
      untraded: {},
    },
    sell: {
      contracted: {},
      nominated: {},
      community: {},
      residual: {},
      summary: { value: 0, volume: 0, carbon: 0 },
      untraded: {},
    },
  };
  if (!mainData) {
    return resp;
  }
  const chartView = { aggregateBy: DATA_AGGREGATE_BY_PROPERTY, groupBy: DATA_GROUP_BY_TRADE_TYPE };
  const { meters = {}, property = {} } = mainData;
  const { uuid: propertyId } = property;
  DIRECTIONS.forEach((dir) => {
    if (!mainData[dir].data) {
      return;
    }

    Object.keys(mainData[dir].data)?.forEach((timestamp) => {
      const { tradeSetSummaries, untradedDataAggregates } = mainData[dir].data[timestamp] || {};
      if (!tradeSetSummaries) {
        return;
      }
      const date = timestampToIsoString(timestamp);
      Object.keys(tradeSetSummaries)?.forEach((ruleId) => {
        const trade = tradeSetSummaries[ruleId] || {};
        const {
          meterId, type, carbon = 0, value = 0, volume = 0,
        } = trade || {};
        const { aggregation } = meters[meterId] || {};

        if (!type) {
          return;
        }

        const tradeType = getTradeType(type);
        const { rules } = mainData[dir];
        const rule = rules[ruleId] || {};
        const counterParty = getCounterParty(rule, type, getDirection(dir));
        const counterPartyProperty = getTraderProperty(counterParty);
        const t = resp[dir][tradeType];
        const dataKey = buildTradeViewKey(
          propertyId,
          chartView,
          tradeType,
          {},
        );

        if (!(dataKey in t)) {
          t[dataKey] = getDataObject(type, counterParty, dataKey, false);
        }

        // Add counterparty property data if available
        t[dataKey].property = counterPartyProperty;

        // Add meter details
        const { buyer, seller } = rule || {};
        const trader = dir === BUY ? buyer : seller;
        const { title, identifier } = trader?.tradePoint?.meter || {};
        t[dataKey].meter = { title, identifier };

        const dataSeries = t[dataKey].data;
        dataSeries[date] ||= {
          interval: {
            timestamp: DateTime.fromMillis(
              parseFloat(timestamp),
              { zone: timezone },
            ),
            length: aggregation,
          },
          value: 0,
          volume: 0,
          carbon: 0,
        };

        const aggregateCarbonByDate = Big(dataSeries[date].carbon).plus(carbon);
        const aggregateValueByDate = Big(dataSeries[date].value).plus(value);
        const aggregateVolumeByDate = Big(dataSeries[date].volume).plus(volume);

        dataSeries[date].carbon = Number(aggregateCarbonByDate);
        dataSeries[date].value = Number(aggregateValueByDate);
        dataSeries[date].volume = Number(aggregateVolumeByDate);

        const aggregateCarbonSummary = Big(resp[dir].summary.carbon).plus(carbon);
        const aggregateValueSummary = Big(resp[dir].summary.value).plus(value);
        const aggregateVolumeSummary = Big(resp[dir].summary.volume).plus(volume);

        resp[dir].summary.carbon = Number(aggregateCarbonSummary);
        resp[dir].summary.value = Number(aggregateValueSummary);
        resp[dir].summary.volume = Number(aggregateVolumeSummary);
      });

      Object.keys(untradedDataAggregates)?.forEach((meterId) => {
        const {
          carbon = 0, value = 0, volume = 0,
        } = untradedDataAggregates[meterId] || {};
        const finalCarbon = convertToNumber(carbon, DEFAULT_DISPLAY_VALUE);
        const finalValue = convertToNumber(value, DEFAULT_DISPLAY_VALUE);
        const finalVolume = convertToNumber(volume, DEFAULT_DISPLAY_VALUE);
        const key = UNTRADED_ENERGY_KEY;
        const { aggregation, identifier, title } = meters[meterId] || {};
        if (!resp[dir][UNTRADED_ENERGY_KEY][key]) {
          resp[dir][UNTRADED_ENERGY_KEY][key] = {
            data: {},
            meter: { title, identifier },
            label: UNTRADED_ENERGY_KEY,
            tradeType: UNTRADED_ENERGY_KEY,
            key,
          };
        }

        resp[dir][UNTRADED_ENERGY_KEY][key].data[date] ||= {
          interval: {
            timestamp: DateTime.fromMillis(
              parseFloat(timestamp),
              { zone: timezone },
            ),
            length: aggregation,
          },
          value: 0,
          volume: 0,
          carbon: 0,

        };
        const datum = resp[dir][UNTRADED_ENERGY_KEY][key].data[date];
        const aggregateCarbonByDate = Big(datum.carbon).plus(finalCarbon);
        const aggregateValueByDate = Big(datum.value).plus(finalValue);
        const aggregateVolumeByDate = Big(datum.volume).plus(finalVolume);

        datum.carbon = Number(aggregateCarbonByDate);
        datum.value = Number(aggregateValueByDate);
        datum.volume = Number(aggregateVolumeByDate);
      });
    });
  });
  DIRECTIONS.forEach((dir) => {
    Object.keys(resp[dir][UNTRADED_ENERGY_KEY]).forEach((key) => {
      const { data } = resp[dir][UNTRADED_ENERGY_KEY][key] || {};
      if (hasVolume(data)) { return; }

      delete (resp[dir][UNTRADED_ENERGY_KEY][key]);
    });
  });
  return resp;
};

/**
 * Builds the chart data for the trade view, given the chart view aggregation and grouping options.
 * @param {object} mainData
 * @param {string} timezone
 * @param {object} chartView
 * @param {DATA_AGGREGATE_BY_METER | DATA_AGGREGATE_BY_PROPERTY} chartView.aggregateBy
 * @param {DATA_GROUP_BY_COUNTERPARTY| DATA_GROUP_BY_TRADE_TYPE} chartView.groupBy
 * @returns {object} the trade view chart data.
 */
export const buildTradeViewChartData = (mainData, timezone, chartView) => {
  if (!mainData || !chartView) {
    return {
      buy: {
        contracted: {},
        nominated: {},
        community: {},
        residual: {},
        summary: { value: 0, volume: 0, carbon: 0 },
        untraded: {},
      },
      sell: {
        contracted: {},
        nominated: {},
        community: {},
        residual: {},
        summary: { value: 0, volume: 0, carbon: 0 },
        untraded: {},
      },
    };
  }

  const { aggregateBy, groupBy } = chartView;

  switch (`${aggregateBy}_${groupBy}`) {
    case `${DATA_AGGREGATE_BY_METER}_${DATA_GROUP_BY_COUNTERPARTY}`:
      return buildTradeViewChartDataByMeterAndCounterParty(mainData, timezone);
    case `${DATA_AGGREGATE_BY_METER}_${DATA_GROUP_BY_TRADE_TYPE}`:
      return buildTradeViewChartDataByMeterAndTradeType(mainData, timezone);
    case `${DATA_AGGREGATE_BY_PROPERTY}_${DATA_GROUP_BY_COUNTERPARTY}`:
      return buildTradeViewChartDataByPropertyAndCounterParty(mainData, timezone);
    case `${DATA_AGGREGATE_BY_PROPERTY}_${DATA_GROUP_BY_TRADE_TYPE}`:
      return buildTradeViewChartDataByPropertyAndTradeType(mainData, timezone);

    default:
      console.error(`aggregate by '${aggregateBy}' is not valid for the property`);
      return {};
  }
};

/**
 * Build the chart data, given the main data, the chart view and whether we are in
 * meter view or trade view.
 * @param {boolean} isMeterView
 * @param {object} mainData
 * @param {string} timezone
 * @param {object} chartView
 * @param {DATA_AGGREGATE_BY_PROPERTY | DATA_AGGREGATE_BY_METER} chartView.aggregateBy
 * @param {DATA_GROUP_BY_COUNTERPARTY| DATA_GROUP_BY_TRADE_TYPE} chartView.groupBy
 * @returns {object} the chart view data.
 */
export const buildChartData = (isMeterView, mainData, timezone, chartView) => {
  if (isMeterView) {
    return buildMeterViewChartData(mainData, timezone, chartView);
  }
  const tradeData = buildTradeViewChartData(mainData, timezone, chartView);
  return consolidateTrades(tradeData);
};
/**
 * Build data for meter cards - shown in meter view below the meter chart.
 * @param {object} chartData
 * @returns {object} - meter cards data.
 */

export const buildMeterCardsData = (chartData) => {
  if (!chartData || Object.keys(chartData).length === 0) {
    return null;
  }
  const finalResp = {};

  Object.keys(chartData).forEach((key) => {
    const { identifier, title } = chartData[key];
    DIRECTIONS.forEach((dir) => {
      const data = chartData[key][dir];
      Object.keys(data).forEach((ts) => {
        const { value } = chartData[key][dir][ts];
        if (!isNumber(value) && !(value instanceof Big)) {
          return;
        }

        if (!finalResp[key]) {
          finalResp[key] = {
            key,
            title,
            identifier,
            buy: { volume: Big(0), count: 0 },
            sell: { volume: Big(0), count: 0 },
          };
        }

        const { volume, count } = finalResp[key][dir];
        finalResp[key][dir] = {
          ...finalResp[key][dir],
          volume: volume.plus(value),
          count: count + 1,
        };
      });
    });
  });

  return Object.values(finalResp).sort(byAggregatedVolumeFunc);
};
/**
 * Builds the trade view cards data.
 * @param {object} tradeData The data prepared for the chart.
 * @param {boolean} isByMeter is the trade view is by meter.
 * @returns {object[]} An array of trade cards data, based on the dataset in the chart.
 */
export const buildTradeViewCardsData = (tradeData, isByMeter) => {
  if (!tradeData) {
    return [];
  }
  const counterParties = {};
  DIRECTIONS.forEach((dir) => {
    if (!tradeData[dir]) {
      return;
    }
    Object.values(tradeData[dir])?.forEach((series) => {
      const {
        key, label, subLabel, user, data, property, meter, tradeType,
      } = series;
      const { title, identifier } = meter;

      if (!key) { return; }
      if (!(key in counterParties)) {
        counterParties[key] = {
          key,
          tradeType,
          label,
          subLabel,
          property,
          user,
          title: isByMeter ? title : '',
          identifier: isByMeter ? identifier : '',
          buy: { value: 0, volume: 0, count: 0 },
          sell: { value: 0, volume: 0, count: 0 },
        };
      }
      Object.values(data).forEach((datum) => {
        const { value, volume } = datum;
        counterParties[key][dir].count += 1;
        counterParties[key][dir].value = Number(Big(counterParties[key][dir].value)
          .plus(value));
        counterParties[key][dir].volume = Number(Big(counterParties[key][dir].volume)
          .plus(volume));
      });
    });
  });
  return Object.values(counterParties)
    .sort(byTradeTypeAndAggregatedVolumeFunc);
};

/**
 * Call the appropriate method depending on the aggregation to build meter card data.
 * When viewing meter data at the portfolio aggregation, there are no cards to show.
 * Thus we return an empty array.
 * @param {object} chartData - The chart data to generate the trade cards data from.
 * @param {object} chartData.buy - The chart data buy object.
 * @param {object} chartData.sell - The chart data sell object.
 * @param {object} chartView - The chart view configuration.
 * @param {DATA_AGGREGATE_BY_METER | DATA_AGGREGATE_BY_PROPERTY} chartView.aggregateBy
 * @returns {object[]} - An array of meter cards data.
 */
export const buildMeterViewCardsData = (chartData, chartView) => {
  if (!chartData || Object.keys(chartData)?.length === 0 || !chartView) {
    return [];
  }

  const { aggregateBy } = chartView;
  switch (aggregateBy) {
    case DATA_AGGREGATE_BY_METER:
      return buildMeterCardsData(chartData);
    case DATA_AGGREGATE_BY_PROPERTY:
      return [];
    default:
      console.error(`aggregate by '${aggregateBy}' is not valid for the property`);
      return [];
  }
};

/**
 * Transform the chart data into the cards data.
 * @param {boolean} isMeterView Flag if is meter view.
 * @param {object} chartData
 * @param {object} chartView
 * @param {DATA_AGGREGATE_BY_METER | DATA_AGGREGATE_BY_PROPERTY} chartView.aggregateBy
 * @param {DATA_GROUP_BY_COUNTERPARTY| DATA_GROUP_BY_TRADE_TYPE} chartView.groupBy
 * @returns {object} The chart cards' dataset.
 */
export const buildCardsData = (isMeterView, chartData, chartView) => {
  if (isMeterView) {
    return buildMeterViewCardsData(chartData, chartView);
  }
  const { aggregateBy } = chartView;
  const isByMeter = aggregateBy === DATA_AGGREGATE_BY_METER;
  return buildTradeViewCardsData(chartData, isByMeter);
};
