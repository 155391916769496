import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { FormattedMessage, useIntl } from 'react-intl';
import { graphql, useFragment } from 'react-relay';

import Loading from 'src/components/Loading';
import {
  getEligiblePropertiesAndTradePointIds,
  sortPropertiesByTitle,
} from 'src/components/TradeRule/TradeRuleHelpers';
import Breadcrumbs from 'src/enosikit/components/Breadcrumbs';
import ProposeNominatedTradeMutation from 'src/mutations/ProposeNominatedTradeMutation';
import FlashesStore from 'src/stores/FlashesStore';
import {
  AllDaysOfWeek, AllMonthsOfYear, AllTimesOfDay,
  TRADE_DIRECTION_UNSPECIFIED, TradeDirections,
  USER_TYPE_ADMIN,
} from 'src/util/constants';
import { getMemberNodes } from 'src/util/mainDataBuilder';

import FlatPricingForm from './FlatPricingForm';
import TimeOfUsePricingForm from './TimeOfUsePricingForm';
import {
  setInitialProposerTradePointId,
} from './helpers';

const TradeRuleProposalNominatedGeneralisedFragment = graphql`
  fragment TradeRuleProposalNominatedGeneralised_viewer on Viewer {
    id
    viewerUser {
      uuid
      type
      userProperties {
        edges {
          node {
            uuid
            active { start finish }
            property {
              active { start finish }
              uuid
              timezone
              publicHolidayRegion
              title
              meters {
                edges {
                  node {
                    uuid
                    tradePointId
                    active { start finish }
                    title 
                    identifier
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * @param {object} props
 * @returns {React.ReactComponentElement} TradeRuleProposalNominatedGeneralised component
 */
function TradeRuleProposalNominatedGeneralised(props) {
  if (!props || Object.keys(props).length === 0) {
    return <Loading />;
  }

  const {
    queryCounterpartyEmail,
    queryPrice,
    queryTradeDirection,
    queryProposerTradePointId,
    router,
    viewer,
  } = props;
  const intl = useIntl();

  const data = useFragment(TradeRuleProposalNominatedGeneralisedFragment, viewer);
  const { viewerUser } = data;
  const { userProperties, type: userType } = viewerUser || {};

  const { eligibleProperties, eligibleTradePointIds } = getEligiblePropertiesAndTradePointIds(
    getMemberNodes(userProperties),
  );
  const properties = sortPropertiesByTitle(eligibleProperties);

  const initialPrice = parseFloat(queryPrice) || null;
  const {
    proposerTradePointId: initialProposerTradePointId,
    message,
  } = setInitialProposerTradePointId(
    queryProposerTradePointId,
    properties,
    eligibleTradePointIds,
  );
  const initialDirection = TradeDirections.includes(queryTradeDirection)
    ? queryTradeDirection : TRADE_DIRECTION_UNSPECIFIED;

  const [timeOfUsePricing, setTimeOfUsePricing] = useState(false);

  const initialTradeRule = {
    proposerTradePointId: initialProposerTradePointId || '',
    recipientUserEmail: queryCounterpartyEmail || '',
    direction: initialDirection,
    clauses: [
      {
        price: initialPrice,
        publicHolidayRegion: null,
        timezone: null,
        ignoreDaylightSavings: true,
        ignorePublicHolidays: true,
        monthsOfYear: AllMonthsOfYear,
        daysOfWeek: AllDaysOfWeek,
        timesOfDay: [AllTimesOfDay],
      },
    ],
  };
  const [tradeRule, setTradeRule] = useState(initialTradeRule);
  const [processing, setProcessing] = useState(false);
  const { proposerTradePointId } = tradeRule || {};

  const onPricingButtonClick = () => {
    if (timeOfUsePricing) {
      setTradeRule({
        ...tradeRule,
        clauses: [
          {
            ...tradeRule.clauses[0],
            timezone: null,
            publicHolidayRegion: null,
            ignoreDaylightSavings: true,
            ignorePublicHolidays: true,
            monthsOfYear: AllMonthsOfYear,
            daysOfWeek: AllDaysOfWeek,
            timesOfDay: [AllTimesOfDay],
          },
        ],
      });
    }
    setTimeOfUsePricing(!timeOfUsePricing);
  };

  const mutationName = 'proposeNominatedTrade';

  const proposerTradePointIdInvalidMsg = intl.formatMessage({ id: 'trade_rule.trade_rule_proposal_nominated_generalised.proposer_trade_point_id_invalid.message', defaultMessage: 'The trade point you have requested is invalid. Please select another trade point.' });

  const noActiveTradePointMsg = intl.formatMessage({ id: 'trade_rule.trade_rule_proposal_nominated_generalised.form.no_active_trade_point_disabled.message', defaultMessage: 'Unfortunately we do not currently have any active trade point for this user. If you believe that this is an error please make use of our support site to raise an issue.' });

  const userTypeAdminDisableMsg = (
    <FormattedMessage
      id="trade_rule.trade_rule_proposal_nominated_generalised.form.user_type_admin_disabled.message"
      defaultMessage="Unfortunately you can not propose a trade rule as you are not a <strong>customer user</strong>. If you believe that this is an error please make use of our support site to raise an issue."
      values={{
        strong: (chunks) => <strong>{chunks}</strong>,
      }}
    />
  );

  const isUserAdmin = userType === USER_TYPE_ADMIN;
  const isTradePointIdEligible = eligibleTradePointIds.includes(proposerTradePointId);

  useEffect(
    () => {
      const { type } = message || {};
      if (type === 'proposerTradePointIdInvalid' && !isUserAdmin && !isTradePointIdEligible) {
        FlashesStore.flash(FlashesStore.WARNING, proposerTradePointIdInvalidMsg, mutationName);
      }
      if (type === 'noActiveTradePoint' && !isUserAdmin) {
        FlashesStore.flash(FlashesStore.WARNING, noActiveTradePointMsg, mutationName);
      }
      if (isUserAdmin) {
        FlashesStore.flash(FlashesStore.WARNING, userTypeAdminDisableMsg, mutationName);
      }
    },
    [message],
  );

  const handleTradeRuleProposeNominatedSuccess = (response, errors, options) => {
    setProcessing(false);
    const { propertyId } = options;

    if (errors && errors.length > 0) {
      errors.forEach((error) => FlashesStore.flash(FlashesStore.ERROR, {
        message: FlashesStore.ERROR,
        source: {
          errors: [error],
        },
      }, mutationName));
      return;
    }

    const { uuid } = response.proposeNominatedTrade;
    const successMsg = intl.formatMessage({ id: 'trade_rule.trade_rule_proposal_nominated_generalised.response_message.success', defaultMessage: 'A new peer-to-peer trade rule proposal has been sent with id {uuid}.' }, { uuid });

    FlashesStore.flash(
      FlashesStore.SUCCESS,
      successMsg,
      mutationName,
    );

    router.push(`/properties/${propertyId}/trade-rules/active`);
  };

  const handleTradeRuleProposeNominatedFailure = (error) => {
    setProcessing(false);

    FlashesStore.flash(FlashesStore.ERROR, error, mutationName);
  };

  const handleTradeRuleProposeNominated = (input, options) => {
    setProcessing(true);
    FlashesStore.reset();
    ProposeNominatedTradeMutation(
      input,
      handleTradeRuleProposeNominatedSuccess,
      handleTradeRuleProposeNominatedFailure,
      options,
    );
  };

  const disableForm = isUserAdmin || !properties?.length;

  const pageTitle = intl.formatMessage({ id: 'trade_rule.trade_rule_proposal_nominated_generalised.page.title', defaultMessage: 'Enosi: propose a nominated trade rule' });

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{pageTitle}</title>
      </Helmet>
      <Breadcrumbs
        breadcrumbs={[
          {
            name: intl.formatMessage({ id: 'trade_rule.trade_rule_proposal_nominated_generalised.breadcrumbs.trade_rules.label', defaultMessage: 'Trade Rules' }), path: '/trade-rules',
          },
          { name: intl.formatMessage({ id: 'trade_rule.trade_rule_proposal_nominated_generalised.breadcrumbs.propose_peer_to_peer_trade_rule.label', defaultMessage: 'Propose peer-to-peer trade rule' }) },
        ]}
      />
      <h1>{intl.formatMessage({ id: 'trade_rule.trade_rule_proposal_nominated_generalised.trade_rule_propose_peer_to_peer.heading', defaultMessage: 'Trade rule - Propose Peer-to-Peer' })}</h1>

      {
        timeOfUsePricing
          ? (
            <TimeOfUsePricingForm
              disabled={disableForm}
              handleTradeRuleProposeNominated={handleTradeRuleProposeNominated}
              onPricingButtonClick={onPricingButtonClick}
              processing={processing}
              properties={properties}
              router={router}
              setTradeRule={setTradeRule}
              tradeRule={tradeRule}
            />
          ) : (
            <FlatPricingForm
              disabled={disableForm}
              handleTradeRuleProposeNominated={handleTradeRuleProposeNominated}
              onPricingButtonClick={onPricingButtonClick}
              processing={processing}
              properties={properties}
              router={router}
              setTradeRule={setTradeRule}
              tradeRule={tradeRule}
            />
          )
      }
    </>
  );
}

TradeRuleProposalNominatedGeneralised.propTypes = {
  queryCounterpartyEmail: PropTypes.string,
  queryPrice: PropTypes.string,
  queryProposerTradePointId: PropTypes.string,
  queryTradeDirection: PropTypes.string,
  router: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  viewer: PropTypes.shape({
    id: PropTypes.string,
    viewerUser: PropTypes.shape({
      uuid: PropTypes.string,
      email: PropTypes.string,
      givenName: PropTypes.string,
      familyName: PropTypes.string,
      active: PropTypes.shape({
        start: PropTypes.number,
        finish: PropTypes.number,
      }),
      updatedAt: PropTypes.number,
    }),
  }).isRequired,
};

TradeRuleProposalNominatedGeneralised.defaultProps = {
  queryCounterpartyEmail: null,
  queryPrice: null,
  queryProposerTradePointId: null,
  queryTradeDirection: null,
};

export default TradeRuleProposalNominatedGeneralised;
