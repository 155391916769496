import { trace } from '@opentelemetry/api';
import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { injectIntl } from 'react-intl';
import { Container, Navbar } from 'reactstrap';

import LogoImage from 'src/assets/images/branding/enosi-logo.svg';
import FlashesRenderer from 'src/components/Flashes/FlashesRenderer';
import Footer from 'src/enosikit/components/Footer';
import Logo from 'src/enosikit/components/Logo';
import { tracingProvider } from 'src/tracing/setup';
import { tracerName } from 'src/tracing/wrappers';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    console.log({ name: 'error', error }); // eslint-disable-line no-console
    // Update state so the next render will show the fallback UI.
    return { error: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.log({ error, errorInfo }); // eslint-disable-line no-console

    // Capture the error via telemetry
    let endSpan = false;
    let span = trace.getActiveSpan();
    if (span === undefined) {
      const tracer = tracingProvider.getProvider().getTracer(tracerName);
      span = tracer.startSpan('ErrorBoundary');
      endSpan = true;
    }
    span.recordException(error);
    span.setAttribute('errorInfo', errorInfo.toString());
    span.setAttribute('componentStack', errorInfo.componentStack);
    if (endSpan) {
      span.end();
    }

    // logErrorToMyService(error, errorInfo);
    this.setState({
      hasError: error, errorInfo,
    });
  }

  retry = () => {
    this.setState({ hasError: false });
  };

  render() {
    const { children, fallback, intl } = this.props;
    const { hasError, errorInfo } = this.state;

    const pageTitle = intl.formatMessage({ id: 'error_boundary.page.title', defaultMessage: 'Uhoh, something went wrong...' });
    const header = intl.formatMessage({ id: 'error_boundary.header.text', defaultMessage: 'Something went wrong.' });
    const errorStack = intl.formatMessage({ id: 'error_boundary.error_stack.text', defaultMessage: 'Error Stack' });
    const componentStack = intl.formatMessage({ id: 'error_boundary.component_stack.text', defaultMessage: 'Component Stack' });

    if (hasError) {
      if (typeof fallback === 'function') {
        return (
          <>
            <Helmet>
              <meta charSet="utf-8" />
              <title>{pageTitle}</title>
            </Helmet>

            <div className="flex flex-column" style={{ minHeight: '80vh' }}>
              <Navbar light expand="lg">
                <Container className="d-flex flex-wrap justify-content-between px-0 px-sm-3">
                  <a href="/" className="navbar-brand">
                    <Logo image={LogoImage} />
                  </a>
                </Container>
              </Navbar>
              <FlashesRenderer />
              <div className="flex-auto">
                <div className="container text-center">
                  <h1>{header}</h1>
                  <div className="text-left">
                    <h2>{errorStack}</h2>
                    <pre>{hasError.stack}</pre>
                    <h2>{componentStack}</h2>
                    <pre>{errorInfo.componentStack}</pre>
                  </div>
                  {fallback(this.retry)}
                </div>
              </div>
              <Footer versionUI={VERSION} />
            </div>
          </>
        );
      }
      return fallback;
    }
    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  fallback: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

ErrorBoundary.defaultProps = {
  children: null,
};

export default injectIntl(ErrorBoundary);
