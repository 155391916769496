import { Link } from 'found';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';
import {
  Button, Card, CardBody, CardFooter, CardHeader, Col,
  Form, FormFeedback, FormGroup, FormText, Input, Label, Row,
} from 'reactstrap';
import isEmail from 'validator/es/lib/isEmail';
import isIn from 'validator/es/lib/isIn';
import isUUID from 'validator/es/lib/isUUID';

import FlashesStore from 'src/stores/FlashesStore';
import {
  AllDaysOfWeek, AllMonthsOfYear, AllTimesOfDay,
  TRADE_DIRECTION_BUY, TRADE_DIRECTION_SELL, TRADE_DIRECTION_UNSPECIFIED,
} from 'src/util/constants';
import isActive from 'src/util/isActive';
import { isValidPrice } from 'src/util/math';

import { getTradePointLabel } from '../TradeRuleHelpers';

class TradeRuleProposeNominatedFlatForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      // Values
      direction: TRADE_DIRECTION_UNSPECIFIED,
      price: '',
      proposerTradePointId: null,
      recipientUserEmail: '',
      // Validation
      directionValid: null,
      priceValid: null,
      proposerTradePointIdValid: null,
      recipientUserEmailValid: null,
    };
  }

  validateDirection = () => {
    const { direction } = this.state;
    const directionValid = isIn(direction, [TRADE_DIRECTION_BUY, TRADE_DIRECTION_SELL]);

    this.setState({ directionValid });
  };

  validatePrice = () => {
    const { price } = this.state;
    const priceValid = isValidPrice(price);
    this.setState({ priceValid });
  };

  validateProposerTradePointId = () => {
    const { proposerTradePointId } = this.state;
    const proposerTradePointIdValid = proposerTradePointId ? isUUID(proposerTradePointId) : false;
    this.setState({ proposerTradePointIdValid });
  };

  validateRecipientUserEmail = () => {
    const { recipientUserEmail } = this.state;
    const recipientUserEmailValid = isEmail(recipientUserEmail);
    this.setState({ recipientUserEmailValid });
  };

  validateForm = () => {
    this.validateDirection();
    this.validatePrice();
    this.validateProposerTradePointId();
    this.validateRecipientUserEmail();
  };

  handleSubmit = (event) => {
    event.preventDefault();

    const {
      handleTradeRuleProposeNominated, processing, property, intl,
    } = this.props;
    const { timezone, publicHolidayRegion } = property;

    const processingMsg = intl.formatMessage({ id: 'trade_rule.trade_rule_propose_nominated_flat_form.response_message.processing', defaultMessage: 'We are still processing your request...' });

    const errorMsg = intl.formatMessage({ id: 'trade_rule.trade_rule_propose_nominated_flat_form.response_message.error', defaultMessage: 'Form data not valid. Please see below.' });
    const mutationName = 'proposeNominatedTrade';

    if (processing) {
      FlashesStore.flash(FlashesStore.INFO, processingMsg, mutationName);
      return;
    }

    this.validateForm();

    if (!this.isValid()) {
      FlashesStore.flash(FlashesStore.ERROR, errorMsg, mutationName);
      return;
    }

    const {
      direction, price, proposerTradePointId, recipientUserEmail,
    } = this.state;

    const input = {
      direction,
      clauses: [
        {
          price: parseFloat(price) / 100000, // do this once here due to floating point errors
          timezone,
          publicHolidayRegion,
          ignoreDaylightSavings: false,
          ignorePublicHolidays: false,
          monthsOfYear: AllMonthsOfYear,
          daysOfWeek: AllDaysOfWeek,
          timesOfDay: [AllTimesOfDay],
        },
      ],
      proposerTradePointId,
      recipientUserEmail,
    };

    handleTradeRuleProposeNominated(input);
  };

  handleDirectionChange = (event) => {
    const { value: direction } = event.target;
    const directionValid = isIn(direction, [TRADE_DIRECTION_BUY, TRADE_DIRECTION_SELL]);

    this.setState({ direction, directionValid });
  };

  handlePriceChange = (event) => {
    const { value: price } = event.target;
    const priceValid = isValidPrice(price);

    this.setState({ price, priceValid });
  };

  handleRecipientUserEmailChange = (event) => {
    const { value: recipientUserEmail } = event.target;
    const recipientUserEmailValid = isEmail(recipientUserEmail);

    this.setState({ recipientUserEmail, recipientUserEmailValid });
  };

  handleProposerTradePointIdChange = (event) => {
    const { value: proposerTradePointId } = event.target;
    const proposerTradePointIdValid = isUUID(proposerTradePointId);

    this.setState({ proposerTradePointId, proposerTradePointIdValid });
  };

  isValid = () => {
    const {
      directionValid,
      priceValid,
      proposerTradePointIdValid,
      recipientUserEmailValid,
    } = this.state;

    return priceValid !== null && priceValid
      && directionValid !== null && directionValid
      && proposerTradePointIdValid !== null && proposerTradePointIdValid
      && recipientUserEmailValid !== null && recipientUserEmailValid;
  };

  render() {
    const {
      property, processing, router, intl,
    } = this.props;
    const { meters } = property;
    const meterNodes = meters && meters.edges.map((edge) => (edge.node));
    const {
      direction, price, proposerTradePointId, recipientUserEmail,
      directionValid, priceValid, proposerTradePointIdValid, recipientUserEmailValid,
    } = this.state;

    const tradePointLabel = getTradePointLabel(intl, direction);
    return (
      <Form onSubmit={this.handleSubmit} disabled={processing}>
        <Card>
          <CardHeader className="d-flex flex-wrap">
            <h2 className="mb-0">
              <FormattedMessage id="trade_rule.trade_rule_propose_nominated_flat_form.propose_peer_to_peer_trade.heading.label" defaultMessage="Propose a peer-to-peer trade" />
            </h2>
            <Link to={`/properties/${property.uuid}/trade-rules/propose/nominated/time-of-use`} className="btn btn-darken ms-auto">
              <FormattedMessage id="trade_rule.trade_rule_propose_nominated_flat_form.time_of_use_pricing.label" defaultMessage="Time-of-use pricing" />
            </Link>
          </CardHeader>
          <CardBody>
            <FormGroup>
              <Label for="direction"><FormattedMessage id="trade_rule.trade_rule_propose_nominated_flat_form.direction.label" defaultMessage="Are you buying or selling?" /></Label>
              <Input type="select" name="direction" id="direction" defaultValue={direction} onChange={this.handleDirectionChange} disabled={processing} valid={directionValid} invalid={directionValid !== null && !directionValid}>
                <option value="">
                  <FormattedMessage id="trade_rule.trade_rule_propose_nominated_flat_form.direction.placeholder" defaultMessage="Select 'Buy' or 'Sell'" />
                </option>
                <option value={TRADE_DIRECTION_BUY}>
                  <FormattedMessage id="trade_rule.trade_rule_propose_nominated_flat_form.direction.trade_direction_buy.text" defaultMessage="Buy" />
                </option>
                <option value={TRADE_DIRECTION_SELL}>
                  <FormattedMessage id="trade_rule.trade_rule_propose_nominated_flat_form.direction.trade_direction_sell.text" defaultMessage="Sell" />
                </option>
              </Input>
              <FormFeedback><FormattedMessage id="trade_rule.trade_rule_propose_nominated_flat_form.direction.invalid_direction.feedback" defaultMessage="Invalid direction" /></FormFeedback>
              <FormText>
                <FormattedMessage id="trade_rule.trade_rule_propose_nominated_flat_form.direction.hint" defaultMessage="Are you proposing to sell your energy, or buy energy from a friend?" />
              </FormText>
            </FormGroup>
            <FormGroup>
              <Label for="price"><FormattedMessage id="trade_rule.trade_rule_propose_nominated_flat_form.price.label" defaultMessage="Price" /></Label>
              <Input type="number" name="price" id="price" value={price} onChange={this.handlePriceChange} disabled={processing} valid={priceValid} invalid={priceValid !== null && !priceValid} />
              <FormFeedback><FormattedMessage id="trade_rule.trade_rule_propose_nominated_flat_form.price.invalid_price.feedback" defaultMessage="Invalid price" /></FormFeedback>
              <FormText>
                <FormattedMessage id="trade_rule.trade_rule_propose_nominated_flat_form.price.hint" defaultMessage="What price, in c/kWh, do you want to propose?" />
              </FormText>
            </FormGroup>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label for="proposerTradePointId">{tradePointLabel}</Label>
                  <Input type="select" name="select" id="proposerTradePointId" defaultValue={proposerTradePointId} onChange={this.handleProposerTradePointIdChange} disabled={processing} valid={proposerTradePointIdValid} invalid={proposerTradePointIdValid !== null && !proposerTradePointIdValid}>
                    <option key="meter-id-null" value="">
                      <FormattedMessage id="trade_rule.trade_rule_propose_nominated_flat_form.proposer_trade_point_id.default.label" defaultMessage="Select a Trade Point" />
                    </option>
                    {meterNodes && meterNodes.map((meter) => (
                      <option
                        value={meter.tradePointId}
                        disabled={!isActive(meter.active)}
                        key={meter.uuid}
                      >
                        {`${meter.title} (${meter.identifier})`}
                      </option>
                    ))}
                  </Input>
                  <FormFeedback><FormattedMessage id="trade_rule.trade_rule_propose_nominated_flat_form.proposer_trade_point_id.invalid_trade_point.feedback" defaultMessage="Invalid trade point selection" /></FormFeedback>
                  <FormText>
                    <FormattedMessage id="trade_rule.trade_rule_propose_nominated_flat_form.proposer_trade_point_id.hint" defaultMessage="Which trade point are you using?" />
                  </FormText>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="recipientUserEmail"><FormattedMessage id="trade_rule.trade_rule_propose_nominated_flat_form.recipient_user_email.label" defaultMessage="Recipient email" /></Label>
                  <Input type="email" name="recipientUserEmail" id="recipientUserEmail" value={recipientUserEmail} onChange={this.handleRecipientUserEmailChange} disabled={processing} valid={recipientUserEmailValid} invalid={recipientUserEmailValid !== null && !recipientUserEmailValid} />
                  <FormFeedback><FormattedMessage id="trade_rule.trade_rule_propose_nominated_flat_form.recipient_user_email.invalid_email.feedback" defaultMessage="Invalid email" /></FormFeedback>
                  <FormText>
                    <FormattedMessage id="trade_rule.trade_rule_propose_nominated_flat_form.recipient_user_email.hint" defaultMessage="Who do you want to propose to trade with?" />
                  </FormText>
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
          <CardFooter>
            <Button disabled={processing}>
              <FormattedMessage id="trade_rule.trade_rule_propose_nominated_flat_form.submit.label" defaultMessage="Propose" />
            </Button>
            <Button color="" onClick={() => (router.go(-1))} disabled={processing}>
              <FormattedMessage id="trade_rule.trade_rule_propose_nominated_flat_form.cancel.label" defaultMessage="Cancel" />
            </Button>
          </CardFooter>
        </Card>
      </Form>
    );
  }
}

TradeRuleProposeNominatedFlatForm.propTypes = {
  handleTradeRuleProposeNominated: PropTypes.func,
  intl: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  property: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  processing: PropTypes.bool,
  router: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types

};

TradeRuleProposeNominatedFlatForm.defaultProps = {
  handleTradeRuleProposeNominated: null,
  property: null,
  processing: false,
};

export default injectIntl(createFragmentContainer(
  TradeRuleProposeNominatedFlatForm,
  {
    property: graphql`
      fragment TradeRuleProposeNominatedFlatForm_property on Property {
        uuid
        title
        timezone
        publicHolidayRegion
        meters {
          edges {
            node {
              uuid
              identifier
              title
              tradePointId
              active { start finish }
            }
          }
        }
      }
    `,
  },
));
