import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';

import FlashesRenderer from 'src/components/Flashes/FlashesRenderer';
import Loading from 'src/components/Loading';
import AnonymousNavigation from 'src/components/Navigation/AnonymousNavigation';
import Footer from 'src/enosikit/components/Footer';

/**
 * Description
 * @param {any} props
 * @returns {React.ReactComponentElement} - AnonymousLayout component
 */
function AnonymousLayout(props) {
  if (!props) {
    return <Loading />;
  }

  const { children } = props;
  const intl = useIntl();

  const pageTitle = intl.formatMessage({ id: 'layout.anonymous_layout.page.title', defaultMessage: 'Enosi - Login to get started' });

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{pageTitle}</title>
      </Helmet>

      <div className="flex flex-column" style={{ minHeight: '80vh' }}>
        <AnonymousNavigation />
        <FlashesRenderer />
        <div className="flex-auto">{children}</div>
      </div>

      <Footer versionUI={VERSION} />
    </>
  );
}

AnonymousLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

AnonymousLayout.defaultProps = {
  children: <Loading />,
};

export default AnonymousLayout;
