import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';

import isActive from 'src/util/isActive';

/**
 * Renders a single meter option for a property.
 * @param {object} meter - The meter object.
 * @returns {React.ReactElement} - A single meter option element.
 */
export const renderMeter = ((meter) => {
  if (!meter || Object.keys(meter)?.length === 0) {
    return null;
  }

  const {
    identifier, title, tradePointId, uuid,
  } = meter;

  return (
    <option
      value={tradePointId}
      key={uuid}
    >
      {`${title} (${identifier})`}
    </option>
  );
});

/**
 * Renders a list of meters grouped by the property they belong to.
 * @param {object} property - The meters object.
 * @param {object} property.meters - The meters object.
 * @param {string} property.title - The title of the property.
 * @param {string} property.uuid - The unique identifier of the property.
 * @returns {React.ReactElement} - A grouped list of meter options.
 */
export const renderActivePropertyMeters = (({ meters, title, uuid }) => {
  const activeMeters = meters?.edges?.filter(({ node }) => isActive(node?.active));

  return (
    <optgroup label={title} key={uuid}>
      {activeMeters?.map(({ node: meter }) => (renderMeter(meter)))}
    </optgroup>
  );
});

/**
 * Renders a list of meters grouped by property they belong to.
 * @param {object} props
 * @param {Array<object>} props.properties
 * @returns {React.ReactComponentElement} - MetersByPropertyOptions component
 */
function MetersByPropertyOptions({ properties }) {
  if (!properties || properties?.length === 0) {
    return null;
  }
  const intl = useIntl();

  const tradePointIdDefaultOption = intl.formatMessage({ id: 'trade_rule.meters_by_property_options.proposer_trade_point_id.default.label', defaultMessage: 'Select a Trade Point' });

  return (
    <>
      <option key="meter-id-null" value="">
        {tradePointIdDefaultOption}
      </option>
      {properties.map((property) => (
        (renderActivePropertyMeters(property))
      ))}
    </>
  );
}

MetersByPropertyOptions.propTypes = {
  properties: PropTypes.arrayOf(PropTypes.shape({
    property: PropTypes.shape({
      meters: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.shape({
          node: PropTypes.shape({
            active: PropTypes.shape({
              start: PropTypes.number,
              finish: PropTypes.number,
            }),
            identifier: PropTypes.string,
            title: PropTypes.string,
            tradePointId: PropTypes.string,
            uuid: PropTypes.string,
          }),
        })),
      }),
    }),
  })).isRequired,
};

export default MetersByPropertyOptions;
