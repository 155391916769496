import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import {
  Button, Card, CardBody, CardFooter, CardHeader,
  FormFeedback, FormGroup, FormText, Input, Label, Form as ReactstrapForm,
} from 'reactstrap';

import { activeStartStringToDateTime, validateExternalIdentifierInput } from 'src/components/Form/Input/utils';

/**
 * @param {object} props
 * @param {Function} props.handleAddPortfolio function handling the mutation.
 * @param {boolean} props.processing whether the mutation is being processed.
 * @param {DateTime} props.minStart timestamp from which the portfolio can be active.
 * @param {string} props.timezone partner's timezone.
 * @returns {React.ReactComponentElement} Create portfolio form component.
 */
function Form({
  handleAddPortfolio,
  minStart,
  processing,
  timezone,
}) {
  const minStartString = minStart.toUTC().toISO();
  const [activeStart, setActiveStart] = useState(minStartString);
  const [title, setTitle] = useState('');
  const [titleInvalid, setTitleInvalid] = useState(false);
  const [activeStartInvalid, setActiveStartInvalid] = useState(false);
  const [externalIdentifier, setExternalIdentifier] = useState('');
  const [externalIdentifierInvalid, setExternalIdentifierInvalid] = useState(false);
  const [externalIdentifierFeedback, setExternalIdentifierFeedback] = useState([]);

  const intl = useIntl();
  const maximumLength = 1024;

  const errorMsgs = {
    MAXIMUM_LIMIT_EXCEEDED: intl.formatMessage({
      id: 'admin.admin_portfolio.admin_portfolio_create.form.form.external_identifier.invalid_external_identifier.max_length_exceeded.feedback',
      defaultMessage: 'External identifier cannot have more than {maximumLength} characters',
    }, { maximumLength }),
    HAS_LEADING_SPACE: intl.formatMessage({
      id: 'admin.admin_portfolio.admin_portfolio_create.form.form.external_identifier.invalid_external_identifier_has_leading_whitespace.feedback',
      defaultMessage: 'External identifier cannot have leading whitespace',
    }),
    HAS_TRAILING_SPACE: intl.formatMessage({
      id: 'admin.admin_portfolio.admin_portfolio_create.form.form.external_identifier.invalid_external_identifier.has_trailing_whitespace.feedback',
      defaultMessage: 'External identifier cannot have trailing whitespace',
    }),
  };

  const validateTitle = () => {
    if (!(title?.trim())) {
      setTitleInvalid(true);
      return;
    }
    setTitleInvalid(false);
  };

  const validateActiveStart = (activeStartString) => {
    const ts = activeStartStringToDateTime(activeStartString, timezone);
    if (!ts) {
      setActiveStartInvalid(true);
      return;
    }

    if (ts < minStart || ts > DateTime.now()) {
      setActiveStartInvalid(true);
      return;
    }
    setActiveStartInvalid(false);
  };

  const validateExternalIdentifier = () => {
    const feedbacks = validateExternalIdentifierInput(
      externalIdentifier,
      maximumLength,
    );

    setExternalIdentifierInvalid(feedbacks?.length > 0);
  };

  const validateForm = () => {
    validateActiveStart(activeStart);
    validateTitle();
    validateExternalIdentifier();
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    validateForm();

    if (titleInvalid || activeStartInvalid || externalIdentifierInvalid) {
      return;
    }

    const input = {
      activeStart: activeStart
        ? DateTime.fromISO(activeStart).toSeconds() : null,
      externalIdentifier: externalIdentifier || null,
      title,
    };

    handleAddPortfolio(input);
  };

  const handleExtIDChange = (event) => {
    const { value: inputExternalIdentifier } = event.target;
    const feedbacks = validateExternalIdentifierInput(
      inputExternalIdentifier,
      maximumLength,
    );
    const feedbackMsg = feedbacks?.map((item) => errorMsgs[item]);

    setExternalIdentifierInvalid(feedbacks?.length > 0);
    setExternalIdentifier(inputExternalIdentifier);
    setExternalIdentifierFeedback(feedbackMsg);
  };

  const handleTitleChange = (event) => {
    const { value: inputTitle } = event.target;
    setTitle(inputTitle);
    setTitleInvalid(!inputTitle);
  };

  const handleActiveStartBlur = () => {
    if (!activeStart) {
      setActiveStart(minStart.toUTC().toISO());
      setActiveStartInvalid(false);
      return;
    }
    const ts = activeStartStringToDateTime(activeStart, timezone);
    if (!ts) {
      setActiveStartInvalid(true);
      return;
    }

    if (activeStart !== ts.toUTC().toISO()) {
      validateActiveStart(ts.toUTC().toISO());
    }
    validateActiveStart(activeStart);
    if (!activeStartInvalid) {
      setActiveStart(ts.toUTC().toISO());
      setActiveStartInvalid(false);
    }
  };

  /**
   * Handles the change event for the active start date/time input field.
   * Validates the input value and sets the `timestampInvalid` state accordingly.
   * @param {React.ChangeEvent<HTMLInputElement>} event - The change event object.
   * @param {object} event.target - The change event object.
   * @param {string} event.target.value - The active start input value in string format.
   */
  const handleActiveStartChange = (event) => {
    const { value: inputActiveStart } = event.target;
    setActiveStart(inputActiveStart);
    validateActiveStart(inputActiveStart);
  };

  const submissionDisabled = processing || !title || titleInvalid || externalIdentifierInvalid;
  const beforePlatformLaunchMessage = intl.formatMessage({ id: 'admin.admin_portfolio.admin_portfolio_create.form.active_start.before_platform_lauch.feedback', defaultMessage: 'The start cannot be before the platform launch {minStart}' }, { minStart: minStartString });
  const futureStartMessage = intl.formatMessage({ id: 'admin.admin_portfolio.admin_portfolio_create.form.active_start.future_start.feedback', defaultMessage: 'The start cannot be in the future' });
  const invalidTimestampMessage = DateTime.fromISO(activeStart) > DateTime.now()
    ? futureStartMessage : beforePlatformLaunchMessage;

  const activeStartHint = intl.formatMessage({ id: 'admin.admin_portfolio.admin_portfolio_create.form.active_start.hint', defaultMessage: '<rfc3339Link>RFC3339 time format</rfc3339Link> <code>YYYY-MM-DDTHH:MM:SSZ</code> (for example, {minStart}' }, { minStart: minStartString, code: (str) => (<code>{str}</code>), rfc3339Link: (str) => (<a href="https://www.rfc-editor.org/rfc/rfc3339">{str}</a>) });

  return (
    <ReactstrapForm onSubmit={handleSubmit} disabled={processing}>
      <Card>
        <CardHeader tag="h2">
          {intl.formatMessage({ id: 'admin.admin_portfolio.admin_portfolio_create.form.heading', defaultMessage: 'Add portfolio' })}
        </CardHeader>
        <CardBody>
          <FormGroup>
            <Label for="title">{intl.formatMessage({ id: 'admin.admin_portfolio.admin_portfolio_create.form.title.label', defaultMessage: 'Title' })}</Label>
            <Input type="text" name="title" id="title" invalid={titleInvalid} value={title} onChange={handleTitleChange} disabled={processing} />
            <FormFeedback>{intl.formatMessage({ id: 'admin.admin_portfolio.admin_portfolio_create.form.invalid_title.feedback', defaultMessage: 'Please enter a title for the portfolio' })}</FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label for="externalIdentifier">{intl.formatMessage({ id: 'admin.admin_portfolio.admin_portfolio_create.form.external_identifier.label', defaultMessage: 'External identifier (optional)' })}</Label>
            <Input type="text" name="external identifier" id="externalIdentifier" value={externalIdentifier} onChange={handleExtIDChange} disabled={processing} invalid={externalIdentifierInvalid} />
            <FormText>{intl.formatMessage({ id: 'admin.admin_portfolio.admin_portfolio_create.form.external_identifier.hint', defaultMessage: 'External identifier can have a maximum of {maximumLength} characters, and cannot have leading or trailing whitespace.' }, { maximumLength })}</FormText>
            {externalIdentifierFeedback?.length > 0
              && externalIdentifierFeedback.map((feedback) => (
                <FormFeedback key={feedback}>{feedback}</FormFeedback>
              ))}
          </FormGroup>
          <FormGroup>
            <Label for="activeStart">
              {intl.formatMessage({ id: 'admin.admin_portfolio.admin_portfolio_create.form.active_start.label', defaultMessage: 'Start (optional) - default to platform launch' })}
            </Label>
            <Input id="activeStart" onChange={handleActiveStartChange} value={activeStart} type="text" name="activeStart" disabled={processing} invalid={activeStartInvalid} onBlur={handleActiveStartBlur} />
            <FormText>{activeStartHint}</FormText>
            <FormFeedback>{invalidTimestampMessage}</FormFeedback>
          </FormGroup>

        </CardBody>
        <CardFooter>
          <Button type="submit" disabled={submissionDisabled}>{intl.formatMessage({ id: 'admin.admin_portfolio.admin_portfolio_create.form.submit.label', defaultMessage: 'Add portfolio' })}</Button>
        </CardFooter>
      </Card>
    </ReactstrapForm>
  );
}

export default Form;

Form.propTypes = {
  handleAddPortfolio: PropTypes.func.isRequired,
  minStart: PropTypes.instanceOf(DateTime).isRequired,
  processing: PropTypes.bool.isRequired,
  timezone: PropTypes.string.isRequired,
};
