import PropTypes from 'prop-types';
import React from 'react';
import { NavLink } from 'reactstrap';

/**
 * Returns a custom link component with configurable link and target
 * @param {object} props
 * @param {React.ReactElement} props.children - child component to be shown
 * @param {string} props.className - className to be added to the component (optional)
 * @param {string} props.href - hyperlink/external url
 * @param {string| ''} props.target - determines whether to load the url in same tab or a new one
 * @returns {React.ReactComponentElement} - customLink component
 */
function ExternalNavLink(props) {
  const {
    children, className, href, target,
  } = props || {};
  if (!href || !children) {
    return null;
  }
  return (
    <NavLink className={className} target={target} href={href}>
      {children}
    </NavLink>
  );
}
ExternalNavLink.propTypes = {
  props: PropTypes.shape({
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    href: PropTypes.string.isRequired,
    target: PropTypes.string,
  }),
};

ExternalNavLink.defaultProps = {
  props: {
    className: '',
    target: '',
  },
};

export default ExternalNavLink;
