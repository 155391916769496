import { Link } from 'found';
import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { FormattedMessage, injectIntl } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';
import { ButtonGroup, ButtonToolbar } from 'reactstrap';

import Address from 'src/components/Address';
import Loading from 'src/components/Loading';
import Breadcrumbs from 'src/enosikit/components/Breadcrumbs';
import Heading from 'src/enosikit/components/Heading';
import LinkInI18nBlock from 'src/enosikit/components/LinkInI18nBlock';
import { allTariffs } from 'src/util/meterTariff';

import TariffInfo from '../TariffInfo';

class TariffList extends React.Component {
  renderPropertyControls = () => {
    const { property } = this.props;
    return (
      <ButtonToolbar>
        <ButtonGroup className="ms-2">
          <Link to={`/properties/${property.uuid}/settings`} className="btn btn-darken">
            <FormattedMessage id="tariff.tariff_list.property_settings.label" defaultMessage="Property Settings" />
          </Link>
        </ButtonGroup>
      </ButtonToolbar>
    );
  };

  render() {
    if (this.error) {
      return (
        <div>
          <FormattedMessage id="error.title" defaultMessage="Error!" />
        </div>
      );
    }
    if (!this.props) {
      return <Loading />;
    }

    const { property, intl } = this.props;
    const {
      uuid, address, title, meters,
    } = property;

    const addr = new Address(address);

    const pageTitle = intl.formatMessage({ id: 'tariff.tariff_list.page.title', defaultMessage: 'Enosi - Property: Tariffs' });
    const breadcrumbProperties = intl.formatMessage({ id: 'tariff.tariff_list.properties.breadcrumb', defaultMessage: 'Properties' });
    const breadcrumbTariffs = intl.formatMessage({ id: 'tariff.tariff_list.tariffs.breadcrumb', defaultMessage: 'Tariffs' });
    const headingTariffs = intl.formatMessage({ id: 'tariff.tariff_list.tariffs.heading', defaultMessage: 'Tariffs' });

    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{pageTitle}</title>
        </Helmet>

        <Breadcrumbs
          breadcrumbs={[
            {
              name: breadcrumbProperties, path: '/properties',
            },
            { name: title, path: `/properties/${uuid}` },
            { name: breadcrumbTariffs },
          ]}
        />
        <Heading
          title={title}
          subtitle={addr.simpleString()}
          context={headingTariffs}
          controls={this.renderPropertyControls()}
        />
        <p>
          <FormattedMessage
            id="tariff.tariff_list.tariffs.help_text"
            defaultMessage="Your electricity tariffs are listed here and are GST exclusive."
            values={{
              link: (chunks) => (
                <LinkInI18nBlock
                  url={intl.formatMessage({ id: 'tariff.tariff_list.tariffs.help_text.url', defaultMessage: 'tariff.tariff_list.tariffs.help_text.url' })}
                  urlKey="tariff.tariff_list.tariffs.help_text.url"
                  chunks={chunks}
                />
              ),
            }}
          />
        </p>
        <div className="property-all-tariffs-list">
          {meters.edges.map((el) => el.node).map((meter) => (
            <>
              <hr />
              <div key={meter.uuid}>
                <h3>
                  {meter.title}
                  <br />
                  <small>{meter.identifier}</small>
                </h3>

                {meter.tariffs && meter.tariffs.edges
                  && allTariffs(meter.tariffs.edges).map((tariff) => (
                    <TariffInfo tariff={tariff} key={tariff.uuid} />
                  ))}
              </div>
            </>
          ))}
        </div>
      </>
    );
  }
}

TariffList.propTypes = {
  property: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  intl: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default injectIntl(createFragmentContainer(
  TariffList,
  {
    property: graphql`
      fragment TariffList_property on Property {
        uuid
        title
        address {
          line1
          line2
          city
          state
          country
          postcode
        }
        meters(first:500) {
          edges {
            node {
              uuid
              identifier
              title
              tariffs(first:500) {
                edges {
                  node {
                    uuid
                    identifier
                    tariffType
                    title
                    active { start finish }
                    ...TariffInfo_tariff
                  }
                }
              }
            }
          }
        }
      }
    `,
  },
));
