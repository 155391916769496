import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import authenticateUser from 'src/auth';
import FlashesRenderer from 'src/components/Flashes/FlashesRenderer';
import HTTP404 from 'src/components/HTTP404';
import Loading from 'src/components/Loading';
import CustomerPortfolioNavigation from 'src/components/Navigation/CustomerPortfolioNavigation';
import Footer from 'src/enosikit/components/Footer';
import { withTracingHOC } from 'src/tracing';

/**
 * Description
 * @param {any} props
 * @returns {React.ReactComponentElement} - CustomerPortfolioLayout component
 */
function CustomerPortfolioLayout(props) {
  const {
    children, portfolio, router, viewer,
  } = props;

  if (!portfolio) {
    return <HTTP404 />;
  }

  const intl = useIntl();

  if (!authenticateUser(intl, viewer, null, router)) {
    return <Loading />;
  }

  const { title: portfolioTitle } = portfolio;
  const { portfolios } = viewer;
  const pageTitle = intl.formatMessage({ id: 'layout.customer_layout.customer_portfolio_layout.page.title', defaultMessage: 'Enosi - Portfolio: {portfolioTitle}' }, { portfolioTitle });

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{pageTitle}</title>
      </Helmet>

      <div className="flex flex-column" style={{ minHeight: '80vh' }}>
        <CustomerPortfolioNavigation
          viewer={viewer}
          portfolios={portfolios}
          portfolio={portfolio}
        />
        <section id="main">
          <FlashesRenderer />
          <div className="container">
            <div className="flex-auto">{children}</div>
          </div>
        </section>
      </div>

      <Footer versionUI={VERSION} />
    </>
  );
}

CustomerPortfolioLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  portfolios: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  portfolio: PropTypes.shape({
    uuid: PropTypes.string,
    title: PropTypes.string,
  }),
  router: PropTypes.shape({
    replace: PropTypes.func,
  }).isRequired,
  viewer: PropTypes.shape({
    id: PropTypes.string,
    portfolios: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  }),
};

CustomerPortfolioLayout.defaultProps = {
  portfolio: null,
  portfolios: null,
  viewer: null,
};

export default withTracingHOC('CustomerPortfolioLayout', createFragmentContainer(CustomerPortfolioLayout, {
  viewer: graphql`
    fragment CustomerPortfolioLayout_viewer on Viewer {
      id
      viewerUser {
        uuid
        type
      }
      ...CustomerPortfolioNavigation_viewer
      portfolios {
        ...CustomerPortfolioNavigation_portfolios
      }
    }
  `,
  portfolio: graphql`
    fragment CustomerPortfolioLayout_portfolio on Portfolio {
      title
      ...CustomerPortfolioNavigation_portfolio
    }
  `,
}));
