import React from 'react';

import Flash from './Flash';
import { useFlashes } from './FlashesProvider';

/**
 * Renders a list of flash messages.
 * Uses the flashes context to display and manage flash notifications.
 * @returns {React.ReactComponentElement | null } FlashesRenderer component.
 */
function FlashesRenderer() {
  const { flashes, removeFlash } = useFlashes();

  if (flashes.length === 0) {
    return null;
  }

  return (
    <div className="container mb4">
      {flashes.map((flash) => (
        <Flash
          key={flash.id}
          flash={flash}
          onRemoveClick={removeFlash}
        />
      ))}
    </div>
  );
}

export default FlashesRenderer;
