import { faPlayCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { APIConfig } from 'src/config';
import { getTradePointInlineLabel } from 'src/helpers/tradeHelpers';
import {
  PLATFORM_MODE_REBATE, TRADE_DIRECTION_BUY, TRADE_DIRECTION_SELL, TRADE_RULE_STATE_PROPOSED,
} from 'src/util/constants';
import { convertEnergyPrice } from 'src/util/conversions';
import { i18nDecimalFormat } from 'src/util/i18n/handler';
import { timeOfUseConditions, timeOfUseDetailsI18n, timeOfUseIsSimple } from 'src/util/timeOfUse';
import { tradeRulePriceRange } from 'src/util/tradeRule';

import TradeRuleTimelineStep from './TradeRuleTimelineStep';
import { getTradeData, getUserName } from '../TradeRuleHelpers';

/**
 * Generates the trade rule(buy) price information for a given trade rule
 * @param {object} root0
 * @param {number} root0.maximum
 * @param {number} root0.minimum
 * @param {'PLATFORM_MODE_REBATE'|'PLATFORM_TRADE'} root0.mode
 * @param {React.ReactElement} root0.units
 * @returns {React.ReactComponentElement} - i18n component that carries the (buy) pricing info
 */
export const buyPriceInformation = ({
  maximum, minimum, mode, units,
}) => {
  const tradeDirectionBuy = (
    <FormattedMessage
      id="trade_rule.trade_rule_timeline.trade_rule_timeline_step_proposed.trade_direction_buy.label"
      defaultMessage="Buying"
    />
  );
  if (mode === PLATFORM_MODE_REBATE && minimum === maximum) {
    return (
      <FormattedMessage
        id="trade_rule.trade_rule_timeline.trade_rule_timeline_step_proposed.trade_direction_buy.trade_rule_price.flat.platform_mode_rebate"
        defaultMessage="{tradeDirection} at a discount of {price} {units}"
        values={{
          // TO DO: Implement d3-format locale support (PT-1124)
          price: <strong>{i18nDecimalFormat(convertEnergyPrice(minimum).toFixed(3))}</strong>,
          tradeDirection: <strong>{tradeDirectionBuy}</strong>,
          units,
        }}
      />
    );
  }

  if (mode !== PLATFORM_MODE_REBATE && minimum === maximum) {
    return (
      <FormattedMessage
        id="trade_rule.trade_rule_timeline.trade_rule_timeline_step_proposed.trade_direction_buy.trade_rule_price.flat.platform_mode_trade"
        defaultMessage="{tradeDirection} at a price of {price} {units}"
        values={{
          // TO DO: Implement d3-format locale support (PT-1124)
          price: <strong>{i18nDecimalFormat(convertEnergyPrice(minimum).toFixed(3))}</strong>,
          tradeDirection: <strong>{tradeDirectionBuy}</strong>,
          units,
        }}
      />
    );
  }

  if (mode === PLATFORM_MODE_REBATE && minimum !== maximum) {
    return (
      <FormattedMessage
        id="trade_rule.trade_rule_timeline.trade_rule_timeline_step_proposed.trade_direction_buy.trade_rule_price.variable.platform_mode_rebate"
        defaultMessage="{tradeDirection} at a discount of {minimum} to {maximum} {units}"
        values={{
          // TO DO: Implement d3-format locale support (PT-1124)
          maximum: <strong>{convertEnergyPrice(maximum).toFixed(3)}</strong>,
          minimum: <strong>{convertEnergyPrice(minimum).toFixed(3)}</strong>,
          tradeDirection: <strong>{tradeDirectionBuy}</strong>,
          units,
        }}
      />
    );
  }

  if (mode !== PLATFORM_MODE_REBATE && minimum !== maximum) {
    return (
      <FormattedMessage
        id="trade_rule.trade_rule_timeline.trade_rule_timeline_step_proposed.trade_direction_buy.trade_rule_price.variable.platform_mode_trade"
        defaultMessage="{tradeDirection} at a price of {minimum} to {maximum} {units}"
        values={{
          // TO DO: Implement d3-format locale support (PT-1124)
          maximum: <strong>{convertEnergyPrice(maximum).toFixed(3)}</strong>,
          minimum: <strong>{convertEnergyPrice(minimum).toFixed(3)}</strong>,
          tradeDirection: <strong>{tradeDirectionBuy}</strong>,
          units,
        }}
      />
    );
  }

  return null;
};

/**
 * Generates the trade rule(sell) price information for a given trade rule
 * @param {object} root0
 * @param {number} root0.maximum
 * @param {number} root0.minimum
 * @param {'PLATFORM_MODE_REBATE'|'PLATFORM_TRADE'} root0.mode
 * @param {React.ReactElement} root0.units
 * @returns {React.ReactComponentElement} - i18n component that carries the (sell) pricing info
 */
export const sellPriceInformation = ({
  maximum, minimum, mode, units,
}) => {
  const tradeDirectionSell = (
    <FormattedMessage
      id="trade_rule.trade_rule_timeline.trade_rule_timeline_step_proposed.trade_direction_sell.label"
      defaultMessage="Selling"
    />
  );
  if (mode === PLATFORM_MODE_REBATE && minimum === maximum) {
    return (
      <FormattedMessage
        id="trade_rule.trade_rule_timeline.trade_rule_timeline_step_proposed.trade_direction_sell.trade_rule_price.flat.platform_mode_rebate"
        defaultMessage="{tradeDirection} at a discount of {price} {units}"
        values={{
          // TO DO: Implement d3-format locale support (PT-1124)
          price: <strong>{convertEnergyPrice(minimum).toFixed(3)}</strong>,
          tradeDirection: <strong>{tradeDirectionSell}</strong>,
          units,
        }}
      />
    );
  }

  if (mode !== PLATFORM_MODE_REBATE && minimum === maximum) {
    return (
      <FormattedMessage
        id="trade_rule.trade_rule_timeline.trade_rule_timeline_step_proposed.trade_direction_sell.trade_rule_price.flat.platform_mode_trade"
        defaultMessage="{tradeDirection} at a price of {price} {units}"
        values={{
          // TO DO: Implement d3-format locale support (PT-1124)
          price: <strong>{convertEnergyPrice(minimum).toFixed(3)}</strong>,
          tradeDirection: <strong>{tradeDirectionSell}</strong>,
          units,
        }}
      />
    );
  }

  if (mode === PLATFORM_MODE_REBATE && minimum !== maximum) {
    return (
      <FormattedMessage
        id="trade_rule.trade_rule_timeline.trade_rule_timeline_step_proposed.trade_direction_sell.trade_rule_price.variable.platform_mode_rebate"
        defaultMessage="{tradeDirection} at a discount of {minimum} to {maximum} {units}"
        values={{
          // TO DO: Implement d3-format locale support (PT-1124)
          maximum: <strong>{convertEnergyPrice(maximum).toFixed(3)}</strong>,
          minimum: <strong>{convertEnergyPrice(minimum).toFixed(3)}</strong>,
          tradeDirection: <strong>{tradeDirectionSell}</strong>,
          units,
        }}
      />
    );
  }

  if (mode !== PLATFORM_MODE_REBATE && minimum !== maximum) {
    return (
      <FormattedMessage
        id="trade_rule.trade_rule_timeline.trade_rule_timeline_step_proposed.trade_direction_sell.trade_rule_price.variable.platform_mode_trade"
        defaultMessage="{tradeDirection} at a price of {minimum} to {maximum} {units}"
        values={{
          // TO DO: Implement d3-format locale support (PT-1124)
          maximum: <strong>{convertEnergyPrice(maximum).toFixed(3)}</strong>,
          minimum: <strong>{convertEnergyPrice(minimum).toFixed(3)}</strong>,
          tradeDirection: <strong>{tradeDirectionSell}</strong>,
          units,
        }}
      />
    );
  }

  return null;
};
/**
 * Returns the buy proposal details
 * @param {string} tradePointLabel
 * @param {object} counterParty
 * @returns {React.ReactElement} - buy proposal details
 */
const buyTradeDetails = (tradePointLabel, counterParty) => {
  if (counterParty) {
    const counterPartyUser = getUserName(counterParty);
    return (
      <FormattedMessage
        id="trade_rule.trade_rule_timeline.trade_rule_timeline_step_proposed.trade_direction_buy_with_counterparty.details"
        defaultMessage="Buying for {tradePointLabel} from {counterPartyUser}"
        values={{
          tradePointLabel,
          counterPartyUser,
        }}
      />
    );
  }
  return (
    <FormattedMessage
      id="trade_rule.trade_rule_timeline.trade_rule_timeline_step_proposed.trade_direction_buy_no_counterparty.details"
      defaultMessage="Buying for {tradePointLabel}"
      values={{
        tradePointLabel,
      }}
    />
  );
};

/**
 * Returns the sell proposal details
 * @param {string} tradePointLabel
 * @param {string} counterParty
 * @returns {React.ReactElement} - sell proposal details
 */
const sellTradeDetails = (tradePointLabel, counterParty) => {
  if (counterParty) {
    const counterPartyUser = getUserName(counterParty);
    return (
      <FormattedMessage
        id="trade_rule.trade_rule_timeline.trade_rule_timeline_step_proposed.trade_direction_sell_with_counterparty.details"
        defaultMessage="Selling from {tradePointLabel} to {counterPartyUser}"
        values={{
          tradePointLabel,
          counterPartyUser,
        }}
      />
    );
  }
  return (
    <FormattedMessage
      id="trade_rule.trade_rule_timeline.trade_rule_timeline_step_proposed.trade_direction_sell_no_counterparty.details"
      defaultMessage="Selling from {tradePointLabel}"
      values={{
        tradePointLabel,
      }}
    />
  );
};
/**
 * Description
 * @param {any} props
 * @returns {any} - TradeRuleTimelineStepProposed component
 */
function TradeRuleTimelineStepProposed(props) {
  const intl = useIntl();
  const clauseConditionDetails = (clauseNodes) => {
    if (clauseNodes.length <= 1) {
      return { element: null, display: false };
    }

    const clauseConditionsAll = clauseNodes.map((el) => {
      const { ignoreDaylightSavings, ignorePublicHolidays, timezone } = el;
      return { ignoreDaylightSavings, ignorePublicHolidays, timezone };
    });

    const ccs = [
      ...new Set(clauseConditionsAll.map((el) => (JSON.stringify(el)))),
    ].map((el) => JSON.parse(el));

    if (ccs.length !== 1) {
      return { element: null, display: false };
    }

    return { element: <>{timeOfUseConditions(ccs[0], intl)}</>, display: true };
  };

  const clauseRender = (clause, showConditions) => {
    const { price } = clause;

    const priceLabel = (
      <FormattedMessage
        id="trade_rule.trade_rule_timeline.trade_rule_timeline_step_proposed.price.flat.label"
        defaultMessage="{price} {detailsLink}"
        values={{
          detailsLink: <abbr title={intl.formatMessage({ id: 'trade_rule.trade_rule_timeline.trade_rule_timeline_step_proposed.price.flat.energy_cost.abbr.title', defaultMessage: 'cents per kilowatt hour' })}><FormattedMessage id="trade_rule.trade_rule_timeline.trade_rule_step_proposed.price.flat.energy_cost.abbr.label" defaultMessage="c/kWh" /></abbr>,
          // TO DO: Implement d3-format locale support (PT-1124)
          price: <strong>{convertEnergyPrice(price).toFixed(3)}</strong>,
        }}
      />
    );

    if (timeOfUseIsSimple(clause)) {
      return priceLabel;
    }
    if (showConditions) {
      return (
        <li key={clause.uuid}>
          <FormattedMessage
            id="trade_rule.trade_rule_timeline.trade_rule_timeline_step_proposed.price.variable.with_conditions.label"
            defaultMessage="{priceLabel} {timeOfUseDetails} {timeOfUseConditions}"
            values={{
              priceLabel,
              timeOfUseDetails: timeOfUseDetailsI18n(intl, clause),
              timeOfUseConditions: timeOfUseConditions(clause, intl),
            }}
          />
        </li>
      );
    }
    return (
      <li key={clause.uuid}>
        <FormattedMessage
          id="trade_rule.trade_rule_timeline.trade_rule_timeline_step_proposed.price.variable.label"
          defaultMessage="{priceLabel} {timeOfUseDetails}"
          values={{
            priceLabel,
            timeOfUseDetails: timeOfUseDetailsI18n(intl, clause),
          }}
        />
      </li>
    );
  };

  const {
    buyer, clauses, hasNextStep, isCurrentStep, ruleId, seller,
    timestamp, timezone, tradeType, user,
  } = props;

  const {
    direction, trader, counterParty,
  } = getTradeData(buyer, seller, user?.uuid) || {};

  const { user: counterPartyUser } = counterParty || {};

  const mode = APIConfig().MODE;

  const clauseNodes = (!clauses || !clauses.edges)
    ? [] : clauses.edges.map((el) => (el.node)).sort((a, b) => (b.price - a.price));
  const ccd = clauseConditionDetails(clauseNodes);

  const priceRange = tradeRulePriceRange({ clauses }) || {};
  const { maximum, minimum } = priceRange;

  const tradePointLabel = getTradePointInlineLabel(trader?.tradePoint, intl);
  const units = (
    <abbr title={intl.formatMessage({ id: 'trade_rule.trade_rule_timeline.trade_rule_timeline_step_proposed.units.energy_cost.abbr.title', defaultMessage: 'cents per kilowatt hour' })}>
      <FormattedMessage id="trade_rule.trade_rule_timeline.trade_rule_timeline_step_proposed.units.energy_cost.abbr.label" defaultMessage="c/kWh" />
    </abbr>
  );

  return (
    <TradeRuleTimelineStep
      hasNextStep={hasNextStep}
      icon={<FontAwesomeIcon icon={faPlayCircle} />}
      isCurrentStep={isCurrentStep}
      ruleId={ruleId}
      state={TRADE_RULE_STATE_PROPOSED}
      tradeType={tradeType}
      timestamp={timestamp}
      timezone={timezone}
      user={user}
    >
      {tradePointLabel && (
        <li className="mb-3">
          {direction === TRADE_DIRECTION_BUY && (
            buyTradeDetails(tradePointLabel, counterPartyUser)
          )}
          {direction === TRADE_DIRECTION_SELL && (
            sellTradeDetails(tradePointLabel, counterPartyUser)
          )}

        </li>
      )}
      <li className="mb-3">
        {direction === TRADE_DIRECTION_BUY && (
          buyPriceInformation({
            maximum, minimum, mode, units,
          })
        )}
        {direction === TRADE_DIRECTION_SELL && (
          sellPriceInformation({
            maximum, minimum, mode, units,
          })
        )}
      </li>
      {clauseNodes.length > 1 && (
        <li className="mb-3">
          <ul className="ps-3">
            {clauseNodes && clauseNodes.map((clause) => (clauseRender(clause, !ccd.display)))}
            {ccd.display && ccd.element}
          </ul>
        </li>
      )}

    </TradeRuleTimelineStep>
  );
}

TradeRuleTimelineStepProposed.propTypes = {
  buyer: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  clauses: PropTypes.shape({
    edges: PropTypes.arrayOf(PropTypes.shape({
      node: (PropTypes.shape({
        ignoreDaylightSavings: PropTypes.bool.isRequired,
        ignorePublicHolidays: PropTypes.bool.isRequired,
        price: PropTypes.number.isRequired,
        timezone: PropTypes.string.isRequired,
      })),
    })),
  }).isRequired,
  isCurrentStep: PropTypes.bool.isRequired,
  hasNextStep: PropTypes.bool.isRequired,
  ruleId: PropTypes.string.isRequired,
  seller: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  timestamp: PropTypes.number.isRequired,
  timezone: PropTypes.string.isRequired,
  tradeType: PropTypes.string.isRequired,
  user: PropTypes.shape({
    uuid: PropTypes.string,
    email: PropTypes.string,
    givenName: PropTypes.string,
    familyName: PropTypes.string,
  }).isRequired,
};

export default TradeRuleTimelineStepProposed;
