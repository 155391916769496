import Big from 'big.js';

const DOLLARS_PER_WATT_HOUR_TO_CENTS_PER_KILOWATT_HOUR = 100000;

/**
 * Convert energy prices from dollars per wh to cents per kwh
 * @param {number} price
 * @returns {Big | null} - price in c/kwh
 */
export const convertEnergyPrice = (price) => {
  if (price === 0) {
    return Big(0);
  }

  if (!price || Number.isNaN(price)) {
    return null;
  }

  return (Big(price).times(DOLLARS_PER_WATT_HOUR_TO_CENTS_PER_KILOWATT_HOUR));
};

/**
 * Convert energy prices from cents per wh to dollars per kwh
 * @param {number} price
 * @returns {Big | null} - price in c/kwh
 */
export const unconvertEnergyPrice = (price) => {
  if (price === 0) {
    return Big(0);
  }

  if (!price || Number.isNaN(price)) {
    return null;
  }

  return (Big(price).div(DOLLARS_PER_WATT_HOUR_TO_CENTS_PER_KILOWATT_HOUR));
};
