import isFloat from 'validator/es/lib/isFloat';

/**
 * Checks whether a value is a number
 * @param {any} value
 * @returns {boolean} - whether the value is a number
 */
export const isNumber = (value) => typeof value === 'number' && !Number.isNaN(value);

/**
 * Checks if the given price is a valid float value greater than or equal to 0.
 * @param {string} price - The price to validate.
 * @returns {boolean} - True if the price is a valid float value greater than or equal to 0,
 * false otherwise.
 */
export const isValidPrice = (price) => {
  if (!price) {
    return false;
  }
  return isFloat(price, { min: 0 });
};
