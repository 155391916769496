import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  Button, Card, CardBody, CardFooter, CardHeader, Col,
  Form, FormFeedback, FormGroup, FormText, Input, Label, Row,
} from 'reactstrap';

import FlashesStore from 'src/stores/FlashesStore';
import {
  TRADE_DIRECTION_BUY, TRADE_DIRECTION_SELL, TRADE_DIRECTION_UNSPECIFIED,
} from 'src/util/constants';
import { convertEnergyPrice, unconvertEnergyPrice } from 'src/util/conversions';
import { getTimezone } from 'src/util/time';

import {
  validateDirection, validatePrice, validateProposerTradePointId,
  validateRecipientUserEmail, validateTradeRule,
} from './helpers';
import MetersByPropertyOptions from '../MetersByPropertyOptions';
import {
  getPropertyContainingMeterByTradePointId, getTradePointLabel,
} from '../TradeRuleHelpers';

/**
 * @param {object} props
 * @param {boolean} props.disabled whether the form is disabled.
 * @param {Function} props.handleTradeRuleProposeNominated function calling the mutation.
 * @param {Function} props.onPricingButtonClick function handling the change of
 * state between the time of use pricing and flat pricing forms.
 * @param {boolean} props.processing whether the mutation is being processed.
 * @param {Array<object>} props.properties array of properties.
 * @param {object} props.router
 * @param {Function} props.setTradeRule set trade rule function.
 * @param {object} props.tradeRule the trade rule being proposed.
 * @returns {React.ReactComponentElement} FlatPricingForm component
 */
function FlatPricingForm({
  disabled,
  handleTradeRuleProposeNominated,
  onPricingButtonClick,
  processing,
  properties,
  router,
  setTradeRule,
  tradeRule,
}) {
  const intl = useIntl();

  const {
    direction,
    clauses,
    proposerTradePointId,
    recipientUserEmail,
  } = tradeRule;

  const [price, setPrice] = useState('');

  const [directionValid, setDirectionValid] = useState(null);
  const [priceValid, setPriceValid] = useState(null);
  const [proposerTradePointIdValid, setProposerTradePointIdValid] = useState(null);
  const [recipientUserEmailValid, setRecipientUserEmailValid] = useState(null);

  const initializeValidation = () => {
    if (direction !== TRADE_DIRECTION_UNSPECIFIED) setDirectionValid(validateDirection(direction));

    if (price) setPriceValid(validatePrice(price));

    if (proposerTradePointId) {
      setProposerTradePointIdValid(
        validateProposerTradePointId(proposerTradePointId),
      );
    }

    if (recipientUserEmail) {
      setRecipientUserEmailValid(validateRecipientUserEmail(recipientUserEmail));
    }
  };

  useEffect(() => {
    if (!tradeRule || tradeRule?.clauses?.length === 0) {
      setPrice(null);
    }
    if (tradeRule?.clauses?.length === 1) {
      const { price: clausePrice } = clauses[0] || '';
      setPrice(clausePrice || '');
      setPriceValid(clausePrice ? validatePrice(clausePrice) : null);
    }
    initializeValidation();
  }, []);

  const handleDirectionChange = (event) => {
    const { value: selectedDirection } = event.target;

    setTradeRule({ ...tradeRule, direction: selectedDirection });
    setDirectionValid(validateDirection(selectedDirection));
  };

  const handlePriceChange = (event) => {
    const { value: updatedPrice } = event.target;
    const updatedPriceBig = unconvertEnergyPrice(parseFloat(updatedPrice));
    setPrice(updatedPriceBig);
    setPriceValid(validatePrice(updatedPriceBig));
    setTradeRule({
      ...tradeRule,
      clauses: [
        {
          ...clauses[0],
          price: updatedPriceBig,
        }],
    });
  };

  const handleProposerTradePointIdChange = (event) => {
    const { value: selectedProposerTradePointId } = event.target;
    setTradeRule({ ...tradeRule, proposerTradePointId: selectedProposerTradePointId });
    setProposerTradePointIdValid(validateProposerTradePointId(selectedProposerTradePointId));
  };

  const handleRecipientUserEmailChange = (event) => {
    const { value: selectedRecipientUserEmail } = event.target;
    setTradeRule({ ...tradeRule, recipientUserEmail: selectedRecipientUserEmail });
    setRecipientUserEmailValid(validateRecipientUserEmail(selectedRecipientUserEmail));
  };

  const isDirectionInvalid = directionValid !== null && !directionValid;
  const isPriceInvalid = priceValid !== null && !priceValid;
  const isProposerTradePointIdInvalid = proposerTradePointIdValid !== null
  && !proposerTradePointIdValid;
  const isRecipientUserEmailInvalid = recipientUserEmailValid !== null && !recipientUserEmailValid;

  const tradePointLabel = getTradePointLabel(intl, direction);
  const mutationName = 'proposeNominatedTrade';

  const handleSubmit = (event) => {
    event.preventDefault();

    const processingMsg = intl.formatMessage({ id: 'trade_rule.trade_rule_proposal_nominated_generalised.flat_pricing_form.response_message.processing', defaultMessage: 'We are still processing your request...' });

    const errorMsg = intl.formatMessage({ id: 'trade_rule.trade_rule_proposal_nominated_generalised.flat_pricing_form.response_message.error', defaultMessage: 'Form data not valid. Please see below.' });

    if (processing) {
      FlashesStore.flash(FlashesStore.INFO, processingMsg, mutationName);
      return;
    }

    const tradeRuleValid = validateTradeRule(tradeRule);

    if (!tradeRuleValid) {
      FlashesStore.flash(FlashesStore.ERROR, errorMsg, mutationName);
      return;
    }

    const property = getPropertyContainingMeterByTradePointId(
      properties,
      tradeRule.proposerTradePointId,
    );
    const { publicHolidayRegion, timezone: propertyTimezone, uuid: propertyId } = property || {};
    const timezone = getTimezone(propertyTimezone);

    const input = {
      direction: tradeRule.direction,
      clauses: clauses.map((clause) => ({
        ...clause,
        price,
        publicHolidayRegion,
        timezone,
      })),
      proposerTradePointId: tradeRule.proposerTradePointId,
      recipientUserEmail: tradeRule.recipientUserEmail,
    };

    handleTradeRuleProposeNominated(input, { propertyId });
  };

  const isFormValid = directionValid
  && priceValid
  && proposerTradePointIdValid
  && recipientUserEmailValid;

  const disabledOrProcessing = disabled || processing;

  return (
    <Form onSubmit={handleSubmit} disabled={disabledOrProcessing}>
      <Card>
        <CardHeader className="d-flex flex-wrap">
          <h2 className="mb-0">
            {intl.formatMessage({ id: 'trade_rule.trade_rule_proposal_nominated_generalised.flat_pricing_form.propose_peer_to_peer_trade.heading.label', defaultMessage: 'Propose a peer-to-peer trade' })}
          </h2>
          <Button onClick={onPricingButtonClick} className="ms-auto" disabled={disabled}>
            {intl.formatMessage({ id: 'trade_rule.trade_rule_proposal_nominated_generalised.flat_pricing_form.time_of_use_pricing.label', defaultMessage: 'Time-of-use pricing' })}
          </Button>
        </CardHeader>
        <CardBody>
          <FormGroup>
            <Label for="direction">{intl.formatMessage({ id: 'trade_rule.trade_rule_proposal_nominated_generalised.flat_pricing_form.direction.label', defaultMessage: 'Are you buying or selling?' })}</Label>
            <Input type="select" name="direction" id="direction" defaultValue={direction} onChange={handleDirectionChange} disabled={disabledOrProcessing} valid={directionValid} invalid={isDirectionInvalid}>
              <option value="">
                {intl.formatMessage({ id: 'trade_rule.trade_rule_proposal_nominated_generalised.flat_pricing_form.direction.placeholder', defaultMessage: "Select 'Buy' or 'Sell'" })}
              </option>
              <option value={TRADE_DIRECTION_BUY}>
                {intl.formatMessage({ id: 'trade_rule.trade_rule_proposal_nominated_generalised.flat_pricing_form.direction.trade_direction_buy.text', defaultMessage: 'Buy' })}
              </option>
              <option value={TRADE_DIRECTION_SELL}>
                {intl.formatMessage({ id: 'trade_rule.trade_rule_proposal_nominated_generalised.flat_pricing_form.direction.trade_direction_sell.text', defaultMessage: 'Sell' })}
              </option>
            </Input>
            <FormFeedback>{intl.formatMessage({ id: 'trade_rule.trade_rule_proposal_nominated_generalised.flat_pricing_form.direction.invalid_direction.feedback', defaultMessage: 'Invalid direction' })}</FormFeedback>
            <FormText>
              {intl.formatMessage({ id: 'trade_rule.trade_rule_proposal_nominated_generalised.flat_pricing_form.direction.hint', defaultMessage: 'Are you proposing to sell your energy, or buy energy from a friend?' })}
            </FormText>
          </FormGroup>
          <FormGroup>
            <Label for="price">{intl.formatMessage({ id: 'trade_rule.trade_rule_proposal_nominated_generalised.flat_pricing_form.price.label', defaultMessage: 'Price' })}</Label>
            <Input type="number" name="price" id="price" value={convertEnergyPrice(price)} onChange={handlePriceChange} disabled={disabledOrProcessing} valid={priceValid} invalid={isPriceInvalid} />
            <FormFeedback>{intl.formatMessage({ id: 'trade_rule.trade_rule_proposal_nominated_generalised.flat_pricing_form.price.invalid_price.feedback', defaultMessage: 'Invalid price' })}</FormFeedback>
            <FormText>
              {intl.formatMessage({ id: 'trade_rule.trade_rule_proposal_nominated_generalised.flat_pricing_formm.price.hint', defaultMessage: 'What price, in c/kWh, do you want to propose?' })}
            </FormText>
          </FormGroup>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label for="proposerTradePointId">{tradePointLabel}</Label>
                <Input type="select" name="select" id="proposerTradePointId" defaultValue={proposerTradePointId} onChange={handleProposerTradePointIdChange} disabled={disabledOrProcessing} valid={proposerTradePointIdValid} invalid={isProposerTradePointIdInvalid}>
                  <MetersByPropertyOptions properties={properties} />
                </Input>
                <FormFeedback>{intl.formatMessage({ id: 'trade_rule.trade_rule_proposal_nominated_generalised.flat_pricing_form.proposer_trade_point_id.invalid_trade_point.feedback', defaultMessage: 'Invalid trade point selection' })}</FormFeedback>
                <FormText>
                  {intl.formatMessage({ id: 'trade_rule.trade_rule_proposal_nominated_generalised.flat_pricing_form.proposer_trade_point_id.hint', defaultMessage: 'Which trade point are you using?' })}
                </FormText>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="recipientUserEmail">{intl.formatMessage({ id: 'trade_rule.trade_rule_proposal_nominated_generalised.flat_pricing_form.recipient_user_email.label', defaultMessage: 'Recipient email' })}</Label>
                <Input type="email" name="recipientUserEmail" id="recipientUserEmail" value={recipientUserEmail} onChange={handleRecipientUserEmailChange} disabled={disabledOrProcessing} valid={recipientUserEmailValid} invalid={isRecipientUserEmailInvalid} />
                <FormFeedback>{intl.formatMessage({ id: 'trade_rule.trade_rule_proposal_nominated_generalised.flat_pricing_form.recipient_user_email.invalid_email.feedback', defaultMessage: 'Invalid email' })}</FormFeedback>
                <FormText>
                  {intl.formatMessage({ id: 'trade_rule.trade_rule_proposal_nominated_generalised.flat_pricing_form.recipient_user_email.hint', defaultMessage: 'Who do you want to propose to trade with?' })}
                </FormText>
              </FormGroup>
            </Col>
          </Row>
        </CardBody>
        <CardFooter>
          <Button disabled={disabledOrProcessing || !isFormValid}>
            {intl.formatMessage({ id: 'trade_rule.trade_rule_proposal_nominated_generalised.flat_pricing_form.submit.label', defaultMessage: 'Propose' })}
          </Button>
          <Button color="" onClick={() => (router.go(-1))} disabled={processing}>
            {intl.formatMessage({ id: 'trade_rule.trade_rule_proposal_nominated_generalised.flat_pricing_form.cancel.label', defaultMessage: 'Cancel' })}
          </Button>
        </CardFooter>
      </Card>
    </Form>
  );
}

FlatPricingForm.propTypes = {
  disabled: PropTypes.bool.isRequired,
  handleTradeRuleProposeNominated: PropTypes.func.isRequired,
  onPricingButtonClick: PropTypes.func.isRequired,
  processing: PropTypes.bool.isRequired,
  properties: PropTypes.arrayOf(PropTypes.shape({
    property: PropTypes.shape({
      meters: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.shape({
          node: PropTypes.shape({
            active: PropTypes.shape({
              start: PropTypes.number,
              finish: PropTypes.number,
            }),
            identifier: PropTypes.string,
            title: PropTypes.string,
            tradePointId: PropTypes.string,
            uuid: PropTypes.string,
          }),
        })),
      }),
    }),
  })).isRequired,
  router: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  setTradeRule: PropTypes.func.isRequired,
  tradeRule: PropTypes.shape({
    clauses: PropTypes.arrayOf(PropTypes.shape({
      price: PropTypes.number,
      timezone: PropTypes.string,
      publicHolidayRegion: PropTypes.string,
      ignoreDaylighSavings: PropTypes.bool,
      ignorePublicHolidays: PropTypes.bool,
      monthOfYear: PropTypes.arrayOf(PropTypes.string),
      dayOfWeek: PropTypes.arrayOf(PropTypes.string),
      timeOfDay: PropTypes.shape({
        start: PropTypes.shape({
          hour: PropTypes.number,
          minute: PropTypes.number,
          seconds: PropTypes.number,
        }),
        finish: PropTypes.shape({
          hour: PropTypes.number,
          minute: PropTypes.number,
          seconds: PropTypes.number,
        }),
      }),
    })),
    direction: PropTypes.string,
    proposerTradePointId: PropTypes.string,
    recipientUserEmail: PropTypes.string,
  }).isRequired,
};

export default FlatPricingForm;
