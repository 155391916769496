import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  Button, Card, CardBody, CardFooter, CardHeader, Col,
  Form, FormFeedback, FormGroup, FormText, Input, Label, Row,
} from 'reactstrap';

import { ClauseEdit, ClauseShow } from 'src/components/TimeOfUse';
import {
  getPropertyContainingMeterByTradePointId,
  getTradePointLabel,
} from 'src/components/TradeRule/TradeRuleHelpers';
import FlashesStore from 'src/stores/FlashesStore';
import {
  DAY_PUBLIC_HOLIDAY, Days, Months, TRADE_DIRECTION_BUY,
  TRADE_DIRECTION_SELL, TRADE_DIRECTION_UNSPECIFIED,
} from 'src/util/constants';
import { getTimezone } from 'src/util/time';
import { timeOfDayToDuration } from 'src/util/timeOfUse';

import {
  validateDirection, validateProposerTradePointId,
  validateRecipientUserEmail, validateTradeRule,
} from './helpers';
import MetersByPropertyOptions from '../MetersByPropertyOptions';

/**
 * @param {object} props
 * @param {boolean} props.disabled whether the form is disabled.
 * @param {Function} props.handleTradeRuleProposeNominated function calling the mutation.
 * @param {Function} props.onPricingButtonClick function handling the change of
 * state between the time of use pricing and flat pricing forms.
 * @param {boolean} props.processing whether the mutation is being processed.
 * @param {Array<object>} props.properties array of properties.
 * @param {object} props.router
 * @param {Function} props.setTradeRule set trade rule function.
 * @param {object} props.tradeRule the trade rule being proposed.
 * @returns {React.ReactComponentElement} TimeOfUsePricingForm component
 */
function TimeOfUsePricingForm(
  {
    disabled,
    handleTradeRuleProposeNominated,
    onPricingButtonClick,
    processing,
    properties,
    router,
    setTradeRule,
    tradeRule,
  },
) {
  const {
    direction, proposerTradePointId, recipientUserEmail,
  } = tradeRule;

  const [directionValid, setDirectionValid] = useState(null);
  const [proposerTradePointIdValid, setProposerTradePointIdValid] = useState(null);
  const [recipientUserEmailValid, setRecipientUserEmailValid] = useState(null);

  const [missingClause, setMissingClause] = useState(false);
  const [missingPrice, setMissingPrice] = useState(false);
  const [ignoreDaylightSavings, setIgnoreDaylightSavings] = useState(false);
  const [ignorePublicHolidays, setIgnorePublicHolidays] = useState(false);
  const [clauses, setClauses] = useState(tradeRule?.clauses);

  const isDirectionInvalid = directionValid !== null && !directionValid;
  const isProposerTradePointIdInvalid = proposerTradePointIdValid !== null
  && !proposerTradePointIdValid;
  const isRecipientUserEmailInvalid = recipientUserEmailValid !== null && !recipientUserEmailValid;

  const handleDirectionChange = (event) => {
    const { value: selectedDirection } = event.target;

    setTradeRule({ ...tradeRule, direction: selectedDirection });
    setDirectionValid(validateDirection(selectedDirection));
  };

  const handleProposerTradePointIdChange = (event) => {
    const { value: selectedProposerTradePointId } = event.target;
    setTradeRule({ ...tradeRule, proposerTradePointId: selectedProposerTradePointId });
    setProposerTradePointIdValid(validateProposerTradePointId(selectedProposerTradePointId));
  };

  const handleRecipientUserEmailChange = (event) => {
    const { value: selectedRecipientUserEmail } = event.target;
    setTradeRule({ ...tradeRule, recipientUserEmail: selectedRecipientUserEmail });
    setRecipientUserEmailValid(validateRecipientUserEmail(selectedRecipientUserEmail));
  };

  const handleIgnoreDaylightSavingsChange = (event) => {
    const { checked: selectedIgnoreDaylightSavings } = event.target;
    setIgnoreDaylightSavings(selectedIgnoreDaylightSavings);
  };

  const handleIgnorePublicHolidaysChange = (event) => {
    const { checked: selectedIgnorePublicHolidays } = event.target;
    setIgnorePublicHolidays(selectedIgnorePublicHolidays);
  };

  const [editing, setEditing] = useState(null); // { index }

  const intl = useIntl();

  let lastClauseId = 0;

  /**
   * Checks if the provided array of clauses is valid. A valid set of clauses must
   * meet the following criteria:
   * - The array must not be empty
   * - The clauses must not overlap in terms of month, day, and time of day
   * - If `ignorePublicHolidays` is true, public holidays will be excluded from the
   *   list of valid days
   * @param {Array<object>} selectedClauses - The array of clauses to validate
   * @returns {boolean} - True if the provided clauses are valid, false otherwise
   */
  const validClauses = (selectedClauses) => {
    if (selectedClauses.length === 0) {
      return false;
    }
    const relevantDays = ignorePublicHolidays
      ? Days.filter((day) => day !== DAY_PUBLIC_HOLIDAY) : Days;
    const combinations = [];
    selectedClauses.forEach(({ monthsOfYear, daysOfWeek, timesOfDay }) => {
      monthsOfYear.forEach((month) => {
        daysOfWeek.forEach((day) => {
          if (ignorePublicHolidays && day === DAY_PUBLIC_HOLIDAY) {
            return;
          }
          timesOfDay.forEach(({ start, finish }) => {
            combinations.push({
              month: Months.indexOf(month),
              day: relevantDays.indexOf(day),
              time: {
                start: timeOfDayToDuration(start).valueOf(),
                finish: timeOfDayToDuration(finish).valueOf(),
              },
            });
          });
        });
      });
    });
    combinations.sort((a, b) => (a.month - b.month || a.day - b.day
        || a.time.start - b.time.start || a.time.finish - b.time.finish));
    for (let i = 1; i < combinations.length; i += 1) {
      const curr = combinations[i];
      const prev = combinations[i - 1];
      const comparison = curr.month - prev.month || curr.day - prev.day
          || curr.time.start - prev.time.finish;
      if (comparison < 0) {
        return false;
      }
    }
    return true;
  };

  /**
   * Generates a unique clause ID by incrementing the `lastClauseId` variable
   * and returning it as a string. This function is used to assign unique IDs
   * to new clauses added to the `clauses` state.
   * @returns {string} A unique clause ID.
   */
  const generateClauseId = () => {
    lastClauseId += 1;
    return lastClauseId.toString();
  };

  /**
   * Checks if the component is in editing mode for the given clause index.
   * If no index is provided, it checks if the component is in any editing mode.
   * @param {number|undefined} index - The index of the clause being edited, or
   * undefined to check for any editing mode.
   * @returns {boolean} - True if the component is in editing mode for the given
   * clause index, or any editing mode if no index is provided.
   */
  const isEditing = (index) => {
    if (index === undefined) {
      return !!editing;
    }

    return editing && editing.index === index;
  };

  /**
   * Sets the `editing` state to the provided `index`, indicating that the clause
   * at the given index is currently being edited.
   * @param {number} index - The index of the clause being edited.
   */
  const editClause = (index) => {
    setEditing({ index });
  };

  /**
   * Sets the `editing` state to `null`, indicating that the component is no longer in editing mode.
   */
  const addClause = () => {
    setEditing({ index: null });
  };

  /**
   * Removes the clause at the specified index from the `clauses` state.
   * @param {number} index - The index of the clause to be removed.
   */
  const removeClause = (index) => {
    const newClauses = [...clauses];
    newClauses.splice(index, 1);
    setTradeRule({ ...tradeRule, clauses: newClauses });
    setClauses(newClauses);
  };

  /**
   * Updates the `clauses` state by replacing the clause at the specified index
   * with the provided `newClause`. The new clause is assigned the `id` of the
   * existing clause at the same index. After the update, the `editing` state is
   * set to `null` to indicate that the component is no longer in editing mode.
   * @param {number} index - The index of the clause to be updated.
   * @param {object} newClause - The new clause object to be used for the update.
   */
  const editClauseSet = (index, newClause) => {
    const newClauses = [...clauses];
    newClauses[index] = { ...newClause, id: clauses[index].uuid };
    setClauses(newClauses);
    setTradeRule({ ...tradeRule, clauses: newClauses });
    setEditing(null);
  };

  /**
   * Adds a new clause to the `clauses` state and sets the `editing` state to `null`
   * to indicate that the component is no longer in editing mode.
   * @param {object} newClause - The new clause object to be added to the `clauses` state.
   */
  const addClauseSet = (newClause) => {
    const newClauses = [...clauses];
    newClauses.push({ ...newClause, id: generateClauseId() });
    setClauses(newClauses);
    setTradeRule({ ...tradeRule, clauses: newClauses });
    setEditing(null);
    setMissingClause(false);
  };

  /**
   * Sets the `editing` state to `null`, indicating that the component is no longer in editing mode.
   */
  const cancelEdit = () => {
    setEditing(null);
  };

  const tradePointLabel = getTradePointLabel(intl, direction);
  const mutationName = 'proposeNominatedTrade';

  const handleSubmit = (event) => {
    event.preventDefault();

    const processingMsg = intl.formatMessage({ id: 'trade_rule.trade_rule_proposal_nominated_generalised.time_of_use_pricing_form.response_message.processing', defaultMessage: 'We are still processing your request...' });

    const errorMsg = intl.formatMessage({ id: 'trade_rule.trade_rule_proposal_nominated_generalised.time_of_use_pricing_form.response_message.error', defaultMessage: 'Form data not valid. Please see below.' });

    if (processing) {
      FlashesStore.flash(FlashesStore.INFO, processingMsg, mutationName);
      return;
    }

    if (clauses.length === 0) {
      setMissingClause(true);
      FlashesStore.flash(FlashesStore.ERROR, errorMsg, mutationName);
      return;
    }
    setMissingClause(false);

    const tradeRuleValid = validateTradeRule(tradeRule);

    if (!tradeRuleValid) {
      FlashesStore.flash(FlashesStore.ERROR, errorMsg, mutationName);
      return;
    }

    const property = getPropertyContainingMeterByTradePointId(
      properties,
      tradeRule.proposerTradePointId,
    );
    const { publicHolidayRegion, timezone: propertyTimezone, uuid: propertyId } = property;
    const timezone = getTimezone(propertyTimezone);

    const formatClause = (clause) => {
      const {
        price, monthsOfYear, daysOfWeek, timesOfDay,
      } = clause;

      return {
        price,
        timezone,
        publicHolidayRegion,
        ignoreDaylightSavings,
        ignorePublicHolidays,
        monthsOfYear,
        daysOfWeek,
        timesOfDay,
      };
    };

    const input = {
      direction: tradeRule.direction,
      clauses: clauses.map(formatClause),
      proposerTradePointId: tradeRule.proposerTradePointId,
      recipientUserEmail: tradeRule.recipientUserEmail,
    };

    handleTradeRuleProposeNominated(input, { propertyId });
  };

  const isFormValid = directionValid
  && proposerTradePointIdValid
  && recipientUserEmailValid
  && clauses?.length > 0
  && validClauses(clauses)
  && clauses.find(({ price }) => !!price);

  useEffect(() => {
    if (!clauses[0]?.price) {
      setMissingPrice(true);
    }
    if (clauses[0]?.price) {
      setMissingPrice(false);
    }
    if (!validClauses(clauses) && clauses?.length > 0) {
      const clausesOverlappingErrorMsg = intl.formatMessage({ id: 'trade_rule.trade_rule_proposal_nominated_generalised.time_of_use_pricing_form.invalid_clauses.response_message.error', defaultMessage: 'Form data not valid. Clauses can not have overlapping times.' });
      FlashesStore.flash(FlashesStore.ERROR, clausesOverlappingErrorMsg, mutationName);
    }
    if (proposerTradePointId) {
      setProposerTradePointIdValid(
        validateProposerTradePointId(proposerTradePointId),
      );
    }
    if (recipientUserEmail) {
      setRecipientUserEmailValid(
        validateRecipientUserEmail(recipientUserEmail),
      );
    }
    if (direction !== TRADE_DIRECTION_UNSPECIFIED) {
      setDirectionValid(validateDirection(direction));
    }
  }, [clauses]);

  return (
    <Form onSubmit={handleSubmit} disabled={processing || disabled}>
      <Card>
        <CardHeader className="d-flex flex-wrap">
          <h2 className="mb-0">
            {intl.formatMessage({ id: 'trade_rule.trade_rule_proposal_nominated_generalised.time_of_use_pricing_form.propose_peer_to_peer_trade.heading.label', defaultMessage: 'Propose a peer-to-peer trade' })}
          </h2>
          <Button onClick={onPricingButtonClick} className="ms-auto" disabled={disabled}>
            {intl.formatMessage({ id: 'trade_rule.trade_rule_proposal_nominated_generalised.time_of_use_pricing_form.trade_rule_flat_pricing.label', defaultMessage: 'Flat pricing' })}
          </Button>
        </CardHeader>
        <CardBody>
          <FormGroup>
            <Label for="direction">{intl.formatMessage({ id: 'trade_rule.trade_rule_proposal_nominated_generalised.time_of_use_pricing_form.direction.label', defaultMessage: 'Are you buying or selling?' })}</Label>
            <Input type="select" name="direction" id="direction" defaultValue={direction} onChange={handleDirectionChange} disabled={processing || disabled} valid={directionValid} invalid={isDirectionInvalid}>
              <option value="">
                {intl.formatMessage({ id: 'trade_rule.trade_rule_proposal_nominated_generalised.time_of_use_pricing_form.direction.placeholder', defaultMessage: "Select 'Buy' or 'Sell'" })}
              </option>
              <option value={TRADE_DIRECTION_BUY}>
                {intl.formatMessage({ id: 'trade_rule.trade_rule_proposal_nominated_generalised.time_of_use_pricing_form.direction.trade_direction_buy.text', defaultMessage: 'Buy' })}
              </option>
              <option value={TRADE_DIRECTION_SELL}>
                {intl.formatMessage({ id: 'trade_rule.trade_rule_proposal_nominated_generalised.time_of_use_pricing_form.direction.trade_direction_sell.text', defaultMessage: 'Sell' })}
              </option>
            </Input>
            <FormFeedback>{intl.formatMessage({ id: 'trade_rule.trade_rule_proposal_nominated_generalised.time_of_use_pricing_form.direction.invalid_direction.feedback', defaultMessage: 'Invalid direction' })}</FormFeedback>
            <FormText>
              {intl.formatMessage({ id: 'trade_rule.trade_rule_proposal_nominated_generalised.time_of_use_pricing_form.direction.hint', defaultMessage: 'Are you proposing to sell your energy, or buy energy from a friend?' })}
            </FormText>
          </FormGroup>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label for="proposerTradePointId">{tradePointLabel}</Label>
                <Input type="select" name="select" id="proposerTradePointId" defaultValue={proposerTradePointId} onChange={handleProposerTradePointIdChange} disabled={processing || disabled} valid={proposerTradePointIdValid} invalid={isProposerTradePointIdInvalid}>
                  <MetersByPropertyOptions properties={properties} />
                </Input>
                <FormFeedback>{intl.formatMessage({ id: 'trade_rule.trade_rule_proposal_nominated_generalised.time_of_use_pricing_form.trade_point.invalid_trade_point.feedback', defaultMessage: 'Invalid trade point selection' })}</FormFeedback>
                <FormText>
                  {intl.formatMessage({ id: 'trade_rule.trade_rule_proposal_nominated_generalised.time_of_use_pricing_form.tradepoint.hint', defaultMessage: 'Which trade point are you using?' })}
                </FormText>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="recipientUserEmail">{intl.formatMessage({ id: 'trade_rule.trade_rule_proposal_nominated_generalised.time_of_use_pricing_form.recipient_user_email.label', defaultMessage: 'Recipient email' })}</Label>
                <Input type="email" name="recipientUserEmail" id="recipientUserEmail" value={recipientUserEmail} onChange={handleRecipientUserEmailChange} disabled={processing || disabled} valid={recipientUserEmailValid} invalid={isRecipientUserEmailInvalid} />
                <FormFeedback>{intl.formatMessage({ id: 'trade_rule.trade_rule_proposal_nominated_generalised.time_of_use_pricing_form.recipient_user_email.invalid_email.feedback', defaultMessage: 'Invalid email' })}</FormFeedback>
                <FormText>
                  {intl.formatMessage({ id: 'trade_rule.trade_rule_proposal_nominated_generalised.time_of_use_pricing_form.recipient_user_email.hint', defaultMessage: 'Who do you want to propose to trade with?' })}
                </FormText>
              </FormGroup>
            </Col>
          </Row>
          <FormGroup>
            {clauses?.map((clause, index) => {
              if (isEditing(index)) {
                return (
                  <ClauseEdit
                    clause={clause}
                    setClause={
                      (newClause) => editClauseSet(index, newClause)
                    }
                    cancelEdit={cancelEdit}
                    priceHelpText={intl.formatMessage({ id: 'trade_rule.trade_rule_proposal_nominated_generalised.time_of_use_pricing_form.price.hint', defaultMessage: 'What price, in c/kWh, do you want to propose?' })}
                    priceLabel={intl.formatMessage({ id: 'trade_rule.trade_rule_proposal_nominated_generalised.time_of_use_pricing_form.price.label', defaultMessage: 'Price' })}
                    key={clause.uuid}
                  />
                );
              }
              return (
                <>
                  <ClauseShow
                    clause={clause}
                    showControls={!isEditing()}
                    editClause={() => editClause(index)}
                    removeClause={() => removeClause(index)}
                    key={clause.uuid}
                  />
                  {missingPrice && (
                  <small className="d-block mb-1 text-danger">
                    {intl.formatMessage({ id: 'trade_rule.trade_rule_proposal_nominated_generalised.time_of_use_pricing_form.missing_clause_price.feedback', defaultMessage: 'Clause must have a price' })}
                  </small>
                  )}
                </>
              );
            })}
            {isEditing(null) && (
              <ClauseEdit
                setClause={(newClause) => addClauseSet(newClause)}
                cancelEdit={cancelEdit}
                priceHelpText={intl.formatMessage({ id: 'trade_rule.trade_rule_proposal_nominated_generalised.time_of_use_pricing_form.price.hint', defaultMessage: 'What price, in c/kWh, do you want to propose?' })}
                priceLabel={intl.formatMessage({ id: 'trade_rule.trade_rule_proposal_nominated_generalised.time_of_use_pricing_form.price.label', defaultMessage: 'Price' })}
              />
            )}
            {!isEditing() && (
              <div className="d-flex flex-column align-items-start mt-2">
                <Button color="darken" onClick={() => addClause()}>
                  <FontAwesomeIcon icon={faPlus} className="me-2" />
                  {intl.formatMessage({ id: 'trade_rule.trade_rule_proposal_nominated_generalised.time_of_use_pricing_form.add_clause.label', defaultMessage: 'Add clause' })}
                </Button>
                {missingClause && (
                <small className="d-block mt-1 text-danger">
                  {intl.formatMessage({ id: 'trade_rule.trade_rule_proposal_nominated_generalised.time_of_use_pricing_form.missing_clause.feedback', defaultMessage: 'You must add at least one clause' })}
                </small>
                )}
              </div>
            )}
          </FormGroup>
          <FormGroup>
            <Label for="ignoreDaylightSavings">
              {intl.formatMessage({ id: 'trade_rule.trade_rule_proposal_nominated_generalised.time_of_use_pricing_form.ignore_day_light_savings.label', defaultMessage: 'Ignore daylight savings' })}
              <Input
                type="checkbox"
                name="ignoreDaylightSavings"
                id="ignoreDaylightSavings"
                label="Ignore daylight savings"
                className="mb-2"
                value={ignoreDaylightSavings}
                checked={ignoreDaylightSavings}
                onChange={handleIgnoreDaylightSavingsChange}
                disabled={processing || disabled}
              />
            </Label>
            <Label for="ignorePublicHolidays">
              {intl.formatMessage({ id: 'trade_rule.trade_rule_proposal_nominated_generalised.time_of_use_pricing_form.ignore_public_holidays.label', defaultMessage: 'Ignore public holidays' })}
              <Input
                type="checkbox"
                name="ignorePublicHolidays"
                id="ignorePublicHolidays"
                label="Ignore public holidays"
                className="mb-2"
                value={ignorePublicHolidays}
                checked={ignorePublicHolidays}
                onChange={handleIgnorePublicHolidaysChange}
                disabled={processing || disabled}
              />
            </Label>
          </FormGroup>
        </CardBody>
        <CardFooter className="d-flex">
          <Button disabled={processing || isEditing() || !isFormValid || disabled}>
            {intl.formatMessage({ id: 'trade_rule.trade_rule_proposal_nominated_generalised.time_of_use_pricing_form.submit.label', defaultMessage: 'Propose' })}
          </Button>
          <Button color="" onClick={() => (router.go(-1))} disabled={processing || isEditing() || disabled}>
            {intl.formatMessage({ id: 'trade_rule.trade_rule_proposal_nominated_generalised.time_of_use_pricing_form.cancel.label', defaultMessage: 'Cancel' })}
          </Button>
        </CardFooter>
      </Card>
    </Form>
  );
}

TimeOfUsePricingForm.propTypes = {
  disabled: PropTypes.bool.isRequired,
  handleTradeRuleProposeNominated: PropTypes.func.isRequired,
  onPricingButtonClick: PropTypes.func.isRequired,
  processing: PropTypes.bool.isRequired,
  properties: PropTypes.arrayOf(PropTypes.shape({
    property: PropTypes.shape({
      meters: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.shape({
          node: PropTypes.shape({
            active: PropTypes.shape({
              start: PropTypes.number,
              finish: PropTypes.number,
            }),
            identifier: PropTypes.string,
            title: PropTypes.string,
            tradePointId: PropTypes.string,
            uuid: PropTypes.string,
          }),
        })),
      }),
    }),
  })).isRequired,
  router: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  setTradeRule: PropTypes.func.isRequired,
  tradeRule: PropTypes.shape({
    clauses: PropTypes.arrayOf(PropTypes.shape({
      price: PropTypes.number,
      timezone: PropTypes.string,
      publicHolidayRegion: PropTypes.string,
      ignoreDaylighSavings: PropTypes.bool,
      ignorePublicHolidays: PropTypes.bool,
      monthOfYear: PropTypes.arrayOf(PropTypes.string),
      dayOfWeek: PropTypes.arrayOf(PropTypes.string),
      timeOfDay: PropTypes.shape({
        start: PropTypes.shape({
          hour: PropTypes.number,
          minute: PropTypes.number,
          seconds: PropTypes.number,
        }),
        finish: PropTypes.shape({
          hour: PropTypes.number,
          minute: PropTypes.number,
          seconds: PropTypes.number,
        }),
      }),
    })),
    direction: PropTypes.string,
    proposerTradePointId: PropTypes.string,
    recipientUserEmail: PropTypes.string,
  }).isRequired,
};

export default TimeOfUsePricingForm;
