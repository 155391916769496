import { faBan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';

import { TRADE_RULE_STATE_CANCELLED } from 'src/util/constants';

import TradeRuleTimelineStep from './TradeRuleTimelineStep';

/**
 * Description
 * @param {any} props
 * @returns {any} - TradeRuleTimelineStepCancelled component
 */
function TradeRuleTimelineStepCancelled(props) {
  const {
    isCurrentStep, hasNextStep, propertyTimeZone, ruleId, timestamp,
    tradeType, user,
  } = props;

  return (
    <TradeRuleTimelineStep
      timezone={propertyTimeZone}
      ruleId={ruleId}
      tradeType={tradeType}
      state={TRADE_RULE_STATE_CANCELLED}
      timestamp={timestamp}
      user={user}
      icon={<FontAwesomeIcon icon={faBan} />}
      isCurrentStep={isCurrentStep}
      hasNextStep={hasNextStep}
    />
  );
}

TradeRuleTimelineStepCancelled.propTypes = {
  isCurrentStep: PropTypes.bool.isRequired,
  hasNextStep: PropTypes.bool.isRequired,
  propertyTimeZone: PropTypes.string.isRequired,
  ruleId: PropTypes.string.isRequired,
  tradeType: PropTypes.string.isRequired,
  timestamp: PropTypes.number.isRequired,
  user: PropTypes.shape({
    uuid: PropTypes.string,
    email: PropTypes.string,
    givenName: PropTypes.string,
    familyName: PropTypes.string,
  }).isRequired,
};

export default TradeRuleTimelineStepCancelled;
