import PropTypes from 'prop-types';
import React from 'react';
import { Alert } from 'reactstrap';

import FlashesStore from 'src/stores/FlashesStore';

export default class Flash extends React.Component {
  // eslint-disable-next-line react/no-unused-class-component-methods
  handleCloseClick = () => {
    const { flash, onRemoveClick } = this.props;
    onRemoveClick(flash);
  };

  render() {
    const { flash } = this.props;
    const { message, type } = flash;

    let color;
    switch (type) {
      case FlashesStore.ERROR:
        color = 'danger';
        break;
      case FlashesStore.SUCCESS:
        color = 'success';
        break;
      case FlashesStore.WARNING:
        color = 'warning';
        break;
      default:
        color = 'info';
    }

    return (
      <Alert color={color} toggle={this.handleCloseClick}>
        {message}
      </Alert>
    );
  }
}

Flash.propTypes = {
  flash: PropTypes.shape({
    message: PropTypes.oneOfType([
      PropTypes.element,
      PropTypes.string,
    ]),
    type: PropTypes.string,
  }).isRequired,
  onRemoveClick: PropTypes.func.isRequired,
};
