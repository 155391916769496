import { faStopCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { getTradePointInlineLabel } from 'src/helpers/tradeHelpers';
import { TRADE_RULE_STATE_CLOSED } from 'src/util/constants';

import TradeRuleTimelineStep from './TradeRuleTimelineStep';

/**
 * Description
 * @param {any} props
 * @returns {any} - TradeRuleTimelineStepClosed component
 */
function TradeRuleTimelineStepClosed(props) {
  const {
    buyer,
    isCurrentStep,
    hasNextStep,
    metersMap,
    propertyTimeZone,
    ruleId,
    seller,
    timestamp,
    tradeType,
    user,
  } = props;

  const intl = useIntl();

  let description;

  if (buyer.tradePoint.uuid in metersMap) {
    description = (
      <FormattedMessage
        id="trade_rule.trade_rule_timeline.trade_rule_timeline_step_closed.buy.details"
        defaultMessage="{buyerTradepoint} no longer buying from {sellerTradepoint}"
        values={{
          buyerTradepoint: getTradePointInlineLabel(buyer.tradePoint, intl),
          sellerTradepoint: getTradePointInlineLabel(seller.tradePoint, intl),
        }}
      />
    );
  } else if (seller.tradePoint.uuid in metersMap) {
    description = (
      <FormattedMessage
        id="trade_rule.trade_rule_timeline.trade_rule_timeline_step_closed.sell.details"
        defaultMessage="{sellerTradepoint} no longer selling to {buyerTradepoint}"
        values={{
          buyerTradepoint: getTradePointInlineLabel(buyer.tradePoint, intl),
          sellerTradepoint: getTradePointInlineLabel(seller.tradePoint, intl),
        }}
      />
    );
  } else {
    description = (
      <FormattedMessage
        id="trade_rule.trade_rule_timeline.trade_rule_timeline_step_closed.trade_direction_unspecified.details"
        defaultMessage="{buyerTradepoint} no longer buying from {sellerTradepoint}"
        values={{
          buyerTradepoint: getTradePointInlineLabel(buyer.tradePoint, intl),
          sellerTradepoint: getTradePointInlineLabel(seller.tradePoint, intl),
        }}
      />
    );
  }

  return (
    <TradeRuleTimelineStep
      timezone={propertyTimeZone}
      ruleId={ruleId}
      tradeType={tradeType}
      state={TRADE_RULE_STATE_CLOSED}
      timestamp={timestamp}
      user={user}
      icon={<FontAwesomeIcon icon={faStopCircle} />}
      isCurrentStep={isCurrentStep}
      hasNextStep={hasNextStep}
    >
      <li className="mb-3">
        {description}
      </li>
    </TradeRuleTimelineStep>
  );
}

TradeRuleTimelineStepClosed.propTypes = {
  buyer: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  isCurrentStep: PropTypes.bool.isRequired,
  hasNextStep: PropTypes.bool.isRequired,
  metersMap: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  propertyTimeZone: PropTypes.string.isRequired,
  ruleId: PropTypes.string.isRequired,
  seller: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  tradeType: PropTypes.string.isRequired,
  timestamp: PropTypes.number.isRequired,
  user: PropTypes.shape({
    uuid: PropTypes.string,
    email: PropTypes.string,
    givenName: PropTypes.string,
    familyName: PropTypes.string,
  }).isRequired,
};

TradeRuleTimelineStepClosed.defaultProps = {
};

export default TradeRuleTimelineStepClosed;
