import Link from 'found/Link';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { createFragmentContainer, graphql } from 'react-relay';
import {
  Card, CardHeader, Col, Row, Table,
} from 'reactstrap';

import BadgeActive from 'src/components/Badge/BadgeActive';
import BadgeTradeRuleState from 'src/components/Badge/BadgeTradeRuleState';
import BadgeTradeType from 'src/components/Badge/BadgeTradeType';
import Loading from 'src/components/Loading';
import UUID from 'src/components/UUID';
import { APIConfig } from 'src/config';
import Breadcrumbs from 'src/enosikit/components/Breadcrumbs';
import { PLATFORM_MODE_REBATE } from 'src/util/constants';
import { convertEnergyPrice } from 'src/util/conversions';
import username from 'src/util/decorators/username';
import { tradeRulePriceRange } from 'src/util/tradeRule';

import AdminTradeRuleShowTrader from './AdminTradeRuleShowTrader';

/**
 * Description
 * @param {object} props
 * @returns {React.ReactElement} - AdminTradeRuleShow component
 */
function AdminTradeRuleShow(props) {
  if (!props) {
    return <Loading />;
  }

  const { rule } = props;
  const {
    uuid, tradeType, start, finish,
    state, priority, sequenceId, buyer,
    seller, proposedAt, proposedBy, cancelledAt,
    cancelledBy, acceptedAt, acceptedBy, rejectedAt,
    rejectedBy, closedAt, closedBy,
  } = rule;

  const priceRange = tradeRulePriceRange(rule);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`Enosi Admin - Trade Rule - ${uuid}`}</title>
      </Helmet>

      <Breadcrumbs
        breadcrumbs={[
          { name: 'Admin', path: '/admin' },
          { name: 'Trade Rules', path: '/admin/trade-rules' },
          { name: `Trade Rule: ${uuid}` },
        ]}
      />

      <h1>
        {uuid}
      </h1>

      <Row className="clear-both">
        <Col xs={12} sm={6} md={4}>
          <Card>
            <CardHeader>
              Trade rule details
            </CardHeader>
            <Table responsive>
              <tbody>
                <tr>
                  <th scope="row" className="text-right">Active status</th>
                  <td>
                    <BadgeActive start={start} finish={finish} />
                  </td>
                </tr>
                <tr>
                  <th scope="row" className="text-right">ID</th>
                  <td>
                    <UUID uuid={uuid} />
                  </td>
                </tr>
                <tr>
                  <th scope="row" className="text-right">Start</th>
                  <td>
                    {start && DateTime.fromSeconds(start).toLocaleString(DateTime.DATETIME_FULL)}
                  </td>
                </tr>
                <tr>
                  <th scope="row" className="text-right">Finish</th>
                  <td>
                    {finish && DateTime.fromSeconds(finish).toLocaleString(DateTime.DATETIME_FULL)}
                  </td>
                </tr>
                <tr>
                  <th scope="row" className="text-right">Type</th>
                  <td>
                    <BadgeTradeType type={tradeType} />
                  </td>
                </tr>
                <tr>
                  <th scope="row" className="text-right">State</th>
                  <td>
                    <BadgeTradeRuleState state={state} />
                  </td>
                </tr>
                <tr>
                  <th scope="row" className="text-right">
                    {APIConfig().MODE === PLATFORM_MODE_REBATE ? 'Discount' : 'Price'}
                  </th>
                  <td>
                    <strong>
                      {convertEnergyPrice(priceRange.minimum).toFixed(3)}
                    </strong>
                    {priceRange.maximum !== priceRange.minimum && (
                      <>
                        {' '}
                        to
                        {' '}
                        <strong>
                          {convertEnergyPrice(priceRange.maximum).toFixed(3)}
                        </strong>
                      </>
                    )}
                    {' '}
                    <abbr title="cents per kilowatt hour">c/kWh</abbr>
                  </td>
                </tr>
                <tr>
                  <th scope="row" className="text-right">Priority</th>
                  <td>
                    {priority}
                  </td>
                </tr>
                <tr>
                  <th scope="row" className="text-right">Sequence ID</th>
                  <td>
                    {sequenceId}
                  </td>
                </tr>
              </tbody>
            </Table>
          </Card>
        </Col>

        <Col xs={12} sm={6} md={8}>
          <Card className="mb-4">
            <CardHeader>
              Buyer
            </CardHeader>
            <AdminTradeRuleShowTrader trader={buyer} />
          </Card>

          <Card className="mb-4">
            <CardHeader>
              Seller
            </CardHeader>
            <AdminTradeRuleShowTrader trader={seller} />
          </Card>

          <Card className="mb-4">
            <CardHeader>
              States
            </CardHeader>
            <Table responsive>
              <thead>
                <tr>
                  <th scope="col">Proposed</th>
                  <th scope="col">Cancelled</th>
                  <th scope="col">Accepted</th>
                  <th scope="col">Rejected</th>
                  <th scope="col">Closed</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {proposedAt
                      && DateTime.fromSeconds(proposedAt).toLocaleString(DateTime.DATETIME_FULL)}
                    <br />
                    {proposedBy && (
                      <>
                        <Link to={`/admin/users/${proposedBy.uuid}`}>
                          {username(proposedBy)}
                        </Link>
                        <br />
                        {proposedBy.email}
                        <br />
                        <BadgeActive
                          start={proposedBy.active.start}
                          finish={proposedBy.active.finish}
                        />
                      </>
                    )}
                  </td>
                  <td>
                    {cancelledAt
                      && DateTime.fromSeconds(cancelledAt).toLocaleString(DateTime.DATETIME_FULL)}
                    <br />
                    {cancelledBy && (
                      <>
                        <Link to={`/admin/users/${cancelledBy.uuid}`}>
                          {username(cancelledBy)}
                        </Link>
                        <br />
                        {cancelledBy.email}
                        <br />
                        <BadgeActive
                          start={cancelledBy.active.start}
                          finish={cancelledBy.active.finish}
                        />
                      </>
                    )}
                  </td>
                  <td>
                    {acceptedAt
                      && DateTime.fromSeconds(acceptedAt).toLocaleString(DateTime.DATETIME_FULL)}
                    <br />
                    {acceptedBy && (
                      <>
                        <Link to={`/admin/users/${acceptedBy.uuid}`}>
                          {username(acceptedBy)}
                        </Link>
                        <br />
                        {acceptedBy.email}
                        <br />
                        <BadgeActive
                          start={acceptedBy.active.start}
                          finish={acceptedBy.active.finish}
                        />
                      </>
                    )}
                  </td>
                  <td>
                    {rejectedAt
                      && DateTime.fromSeconds(rejectedAt).toLocaleString(DateTime.DATETIME_FULL)}
                    <br />
                    {rejectedBy && (
                      <>
                        <Link to={`/admin/users/${rejectedBy.uuid}`}>
                          {username(rejectedBy)}
                        </Link>
                        <br />
                        {rejectedBy.email}
                        <br />
                        <BadgeActive
                          start={rejectedBy.active.start}
                          finish={rejectedBy.active.finish}
                        />
                      </>
                    )}
                  </td>
                  <td>
                    {closedAt
                      && DateTime.fromSeconds(closedAt).toLocaleString(DateTime.DATETIME_FULL)}
                    <br />
                    {closedBy && (
                      <>
                        <Link to={`/admin/users/${closedBy.uuid}`}>
                          {username(closedBy)}
                        </Link>
                        <br />
                        {closedBy.email}
                        <br />
                        <BadgeActive
                          start={closedBy.active.start}
                          finish={closedBy.active.finish}
                        />
                      </>
                    )}
                  </td>
                </tr>
              </tbody>
            </Table>
          </Card>
        </Col>
      </Row>
    </>
  );
}

AdminTradeRuleShow.propTypes = {
  relay: PropTypes.shape({
    hasMore: PropTypes.func,
    isLoading: PropTypes.func,
    loadMore: PropTypes.func,
  }).isRequired,
  rule: PropTypes.shape({
    uuid: PropTypes.string,
    tradeType: PropTypes.string,
    clauses: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            price: PropTypes.number,
          }),
        }),
      ),
    }),
    start: PropTypes.number,
    finish: PropTypes.number,
    state: PropTypes.string,
    priority: PropTypes.number,
    sequenceId: PropTypes.number,
    buyer: PropTypes.shape({
      tradePoint: PropTypes.shape({
        uuid: PropTypes.string,
        type: PropTypes.string,
      }),
      community: PropTypes.shape({
        uuid: PropTypes.string,
        title: PropTypes.string,
        active: PropTypes.shape({
          start: PropTypes.number,
          finish: PropTypes.number,
        }),
      }),
      user: PropTypes.shape({
        uuid: PropTypes.string,
        email: PropTypes.string,
        givenName: PropTypes.string,
        familyName: PropTypes.string,
        active: PropTypes.shape({
          start: PropTypes.number,
          finish: PropTypes.number,
        }),
      }),
    }),
    seller: PropTypes.shape({
      tradePoint: PropTypes.shape({
        uuid: PropTypes.string,
        type: PropTypes.string,
      }),
      community: PropTypes.shape({
        uuid: PropTypes.string,
        title: PropTypes.string,
        active: PropTypes.shape({
          start: PropTypes.number,
          finish: PropTypes.number,
        }),
      }),
      user: PropTypes.shape({
        uuid: PropTypes.string,
        email: PropTypes.string,
        givenName: PropTypes.string,
        familyName: PropTypes.string,
        active: PropTypes.shape({
          start: PropTypes.number,
          finish: PropTypes.number,
        }),
      }),
    }),
    proposedAt: PropTypes.number,
    proposedBy: PropTypes.shape({
      uuid: PropTypes.string,
      email: PropTypes.string,
      givenName: PropTypes.string,
      familyName: PropTypes.string,
      active: PropTypes.shape({
        start: PropTypes.number,
        finish: PropTypes.number,
      }),
    }),
    cancelledAt: PropTypes.number,
    cancelledBy: PropTypes.shape({
      uuid: PropTypes.string,
      email: PropTypes.string,
      givenName: PropTypes.string,
      familyName: PropTypes.string,
      active: PropTypes.shape({
        start: PropTypes.number,
        finish: PropTypes.number,
      }),
    }),
    acceptedAt: PropTypes.number,
    acceptedBy: PropTypes.shape({
      uuid: PropTypes.string,
      email: PropTypes.string,
      givenName: PropTypes.string,
      familyName: PropTypes.string,
      active: PropTypes.shape({
        start: PropTypes.number,
        finish: PropTypes.number,
      }),
    }),
    rejectedAt: PropTypes.number,
    rejectedBy: PropTypes.shape({
      uuid: PropTypes.string,
      email: PropTypes.string,
      givenName: PropTypes.string,
      familyName: PropTypes.string,
      active: PropTypes.shape({
        start: PropTypes.number,
        finish: PropTypes.number,
      }),
    }),
    closedAt: PropTypes.number,
    closedBy: PropTypes.shape({
      uuid: PropTypes.string,
      email: PropTypes.string,
      givenName: PropTypes.string,
      familyName: PropTypes.string,
      active: PropTypes.shape({
        start: PropTypes.number,
        finish: PropTypes.number,
      }),
    }),
  }).isRequired,
};

export default createFragmentContainer(
  AdminTradeRuleShow,
  {
    rule: graphql`
      fragment AdminTradeRuleShow_rule on TradeRule {
        uuid
        tradeType
        clauses {
          edges {
            node {
              price
            }
          }
        }
        start
        finish
        state
        priority
        sequenceId
        buyer {
          tradePoint { uuid type }
          community {
            uuid
            title
            active { start finish }
          }
          user {
            uuid
            email
            givenName
            familyName
            active { start finish }
          }
        }
        seller {
          tradePoint { uuid type }
          community {
            uuid
            title
            active { start finish }
          }
          user {
            uuid
            email
            givenName
            familyName
            active { start finish }
          }
        }
        proposedAt
        proposedBy {
          uuid
          email
          givenName
          familyName
          active { start finish }
        }
        cancelledAt
        cancelledBy {
          uuid
          email
          givenName
          familyName
          active { start finish }
        }
        acceptedAt
        acceptedBy {
          uuid
          email
          givenName
          familyName
          active { start finish }
        }
        rejectedAt
        rejectedBy {
          uuid
          email
          givenName
          familyName
          active { start finish }
        }
        closedAt
        closedBy {
          uuid
          email
          givenName
          familyName
          active { start finish }
        }
      }
    `,
  },
);
