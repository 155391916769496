import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import {
  DATA_AGGREGATE_BY_METER, DATA_AGGREGATE_BY_PORTFOLIO,
  DATA_AGGREGATE_BY_PROPERTY, DATA_GROUP_BY_COUNTERPARTY,
  DATA_GROUP_BY_TRADE_TYPE,
} from 'src/util/constants';

import {
  METER, TRADE,
} from './chartConstants';
import { getTooltipPosition } from '../helpers/tooltip';
// Styles
const base = 2.5;

const TooltipContainer = styled.div`
width: max-content;
fontSize: ${base * 0.25}rem;
fontFamily: inherit;
color: #212529;
padding: ${base * 0.3}rem;
background-color: #fff;
border: ${base * 0.1}rem solid #f5f7fa;
border-radius: ${base * 0.1}rem;
`;

const MainHeader = styled.h5`
font-size: ${base * 0.4}rem;
margin-bottom: ${base * 0.1}rem;
`;

// End of child components rendering

/**
 * Description
 * @param {any} props
 * @returns {React.ReactElement} - ChartTooltip component
 */
function ChartTooltip(props) {
  if (!props) {
    return null;
  }
  const {
    chartType, chartWidth, chartView, datum,
    isCarbon, tooltipData, tooltipDateFormat,
    tooltipFormat, tooltipLabelFunc, tooltipTimestamp,
    tooltipUpdate, unitLabel, yAxisFormat, x,
  } = props;

  if (!datum) {
    return null;
  }

  const { fullDateObj: datumTimestamp } = datum;

  if (!tooltipTimestamp && !datumTimestamp) {
    return null;
  }

  // This happens for property with 0 volume usage.
  if (!tooltipTimestamp) {
    tooltipUpdate(datumTimestamp);
  }
  const { ts: tooltipTimestampValue } = tooltipTimestamp || datumTimestamp; // Is required property.

  if (datumTimestamp) {
    const { ts: datumTimestampValue } = datumTimestamp;

    // This happens when the tooltip is trigerred from the whitespace above the bars.
    // We need to update the parent component with the correct timestamp to get the tooltip data
    if (datumTimestampValue && datumTimestampValue !== tooltipTimestampValue) {
      tooltipUpdate(datumTimestamp);
    }
  }

  return (
    <g>
      <foreignObject x={getTooltipPosition(x, chartWidth)} width="1" height="100%" className="overflow-visible">
        <TooltipContainer className={`${chartType}-tooltip`}>
          <MainHeader>{tooltipTimestamp?.toLocaleString(tooltipDateFormat)}</MainHeader>
          {
            tooltipLabelFunc(
              isCarbon,
              tooltipData,
              yAxisFormat,
              unitLabel,
              tooltipFormat,
              chartView,
            )
          }
        </TooltipContainer>
      </foreignObject>
    </g>
  );
}

export default ChartTooltip;

ChartTooltip.propTypes = {
  chartView: PropTypes.shape({
    groupBy: PropTypes.oneOf([
      DATA_GROUP_BY_COUNTERPARTY,
      DATA_GROUP_BY_TRADE_TYPE,
    ]).isRequired,
    aggregateBy: PropTypes.oneOf([
      DATA_AGGREGATE_BY_METER,
      DATA_AGGREGATE_BY_PORTFOLIO,
      DATA_AGGREGATE_BY_PROPERTY,
    ]).isRequired,
  }).isRequired,
  chartType: PropTypes.oneOf([METER, TRADE]).isRequired,
  chartWidth: PropTypes.number.isRequired,
  datum: PropTypes.oneOfType([PropTypes.object]),
  isCarbon: PropTypes.bool.isRequired,
  tooltipData: PropTypes.oneOfType([PropTypes.object]).isRequired,
  tooltipDateFormat: PropTypes.oneOfType([PropTypes.object]).isRequired,
  tooltipLabelFunc: PropTypes.func.isRequired,
  tooltipFormat: PropTypes.string,
  tooltipTimestamp: PropTypes.instanceOf(DateTime),
  tooltipUpdate: PropTypes.func.isRequired,
  unitLabel: PropTypes.string.isRequired,
  yAxisFormat: PropTypes.string.isRequired,
  x: PropTypes.number,
};
ChartTooltip.defaultProps = {
  tooltipFormat: null,
  datum: undefined,
  x: undefined,
  tooltipTimestamp: undefined,
};
