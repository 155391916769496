import Link from 'found/Link';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import Address from 'src/components/Address';
import BadgeActive from 'src/components/Badge/BadgeActive';
import LoadMoreButton from 'src/components/LoadMoreButton';
import Alert from 'src/enosikit/components/Alert';
import LinkInI18nBlock from 'src/enosikit/components/LinkInI18nBlock';
import { ACTIVE_STATE_ACTIVE, ACTIVE_STATE_INACTIVE, LIST_FILTER_ALL } from 'src/util/constants';
import activePeriodText from 'src/util/i18n/activePeriodText';

/**
 * Renders an alert component to inform the user that there is no property in the portfolio.
 * @returns {React.ReactElement} The alert component.
 */
export function noPropertyMsg() {
  const intl = useIntl();
  return (
    <Alert color="warning" className="mb-2 w-100">
      <FormattedMessage
        id="portfolio.portfolio_show.portfolio_property_member_list.table.no_property.alert"
        defaultMessage="<strong>No property memberships found for this portfolio.</strong> Unfortunately we do not currently have any property memberships for this portfolio. If you believe that this is an error please make use of our support site to raise an issue."
        values={{
          strong: (chunks) => <strong>{chunks}</strong>,
          link: (chunks) => (
            <LinkInI18nBlock
              url={
                intl.formatMessage({
                  id: 'portfolio.portfolio_show.portfolio_property_member_list.table.no_property.alert.url',
                  defaultMessage: 'portfolio.portfolio_show.portfolio_property_member_list.table.no_property.alert.url',
                })
              }
              urlKey="portfolio.portfolio_show.portfolio_property_member_list.table.no_property.alert.url"
              chunks={chunks}
            />
          ),
        }}
      />
    </Alert>
  );
}

/**
 * Renders an alert component to inform the user that there is no active property in the portfolio.
 * @returns {React.ReactElement} The alert component.
 */
export function noActivePropertiesMsg() {
  const intl = useIntl();
  return (
    <Alert color="warning" className="mb-2 w-100">
      <FormattedMessage
        id="portfolio.portfolio_show.portfolio_property_member_list.table.no_active_property.alert"
        defaultMessage="<strong>No active property memberships found in this portfolio.</strong> Unfortunately we do not currently have any active property memberships for this portfolio. If you believe that this is an error please make use of our support site to raise an issue."
        values={{
          strong: (chunks) => <strong>{chunks}</strong>,
          link: (chunks) => (
            <LinkInI18nBlock
              url={
                intl.formatMessage({
                  id: 'portfolio.portfolio_show.portfolio_property_member_list.table.no_active_property.alert.url',
                  defaultMessage: 'portfolio.portfolio_show.portfolio_property_member_list.table.no_active_property.alert.url',
                })
              }
              urlKey="portfolio.portfolio_show.portfolio_property_member_list.table.no_active_property.alert.url"
              chunks={chunks}
            />
          ),
        }}
      />
    </Alert>
  );
}

/**
 * Renders an alert component to inform the user that there is
 * no inactive property in the portfolio.
 * @returns {React.ReactElement} The alert component.
 */
export function noInactivePropertiesMsg() {
  const intl = useIntl();
  return (
    <Alert color="warning" className="mb-2 w-100">
      <FormattedMessage
        id="portfolio.portfolio_show.portfolio_property_member_list.table.no_inactive_property.alert"
        defaultMessage="<strong>No inactive property memberships found in this portfolio.</strong> Unfortunately we do not currently have any inactive property memberships for this portfolio. If you believe that this is an error please make use of our support site to raise an issue."
        values={{
          strong: (chunks) => <strong>{chunks}</strong>,
          link: (chunks) => (
            <LinkInI18nBlock
              url={
                intl.formatMessage({
                  id: 'portfolio.portfolio_show.portfolio_property_member_list.table.no_inactive_property.alert.url',
                  defaultMessage: 'portfolio.portfolio_show.portfolio_property_member_list.table.no_inactive_property.alert.url',
                })
              }
              urlKey="portfolio.portfolio_show.portfolio_property_member_list.table.no_inactive_property.alert.url"
              chunks={chunks}
            />
          ),
        }}
      />
    </Alert>
  );
}

/**
 * Renders an alert component to inform the user
 *  that there is no property in the portfolio (with a given state)
 * @param {ACTIVE_STATE_ACTIVE | ACTIVE_STATE_INACTIVE} state
 * @returns {React.ReactElement} - alert component with a message
 */
export function getNoPropertiesMsg(state) {
  switch (state) {
    case ACTIVE_STATE_ACTIVE:
      return noActivePropertiesMsg();
    case ACTIVE_STATE_INACTIVE:
      return noInactivePropertiesMsg();
    default:
      return noPropertyMsg();
  }
}

/**
 * @param {object} props
 * @param {Array} props.data property members to be displayed in the table.
 * @param {ACTIVE_STATE_ACTIVE | ACTIVE_STATE_INACTIVE | LIST_FILTER_ALL} props.state
 * @param {boolean} props.hasNext
 * @param {Function} props.loadMore
 * @returns {React.ReactComponentElement} property members list table component.
 */
function PortfolioPropertyMemberListTable({
  data, hasNext, loadMore, state,
}) {
  if (!data || data?.length === 0) {
    return getNoPropertiesMsg(state);
  }

  const intl = useIntl();

  const copyLoadMore = intl.formatMessage({ id: 'portfolio.portfolio_show.portfolio_property_member_list.table.load_more_button.state_load_more.label', defaultMessage: 'Load more...' });
  const copyNoMore = intl.formatMessage({ id: 'portfolio.portfolio_show.portfolio_property_member_list.table.load_more_button.state_no_more.label', defaultMessage: 'No more property members to load' });

  return (
    <div className="mt-4 mb-4 card">
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th scope="col">
                <FormattedMessage id="portfolio.portfolio_show.portfolio_property_member_list.table.head.title.label" defaultMessage="Title" />
              </th>
              <th scope="col">
                <FormattedMessage id="portfolio.portfolio_show.portfolio_property_member_list.table.head.address.label" defaultMessage="Address" />
              </th>
              <th scope="col">
                <FormattedMessage id="portfolio.portfolio_show.portfolio_property_member_list.table.head.active.label" defaultMessage="Active" />
              </th>
            </tr>
          </thead>
          <tbody>
            {
              data.map((datum) => {
                const {
                  active, uuid, title, address,
                } = datum || {};
                const { start, finish } = active || {};
                const addr = address ? new Address(address) : '';
                const finalAddress = addr ? addr.simpleString() : 'PROPERTY ADDRESS';
                const redirectLink = `/properties/${uuid}`;
                return (
                  <tr key={uuid}>
                    <th scope="row" colSpan={2}>
                      <Link to={redirectLink}>
                        {title}
                      </Link>
                      <br />
                      <div>{finalAddress}</div>
                    </th>
                    <td>
                      <BadgeActive start={start} finish={finish} />
                      <br />
                      {activePeriodText(intl, active)}
                    </td>
                  </tr>
                );
              })
            }
          </tbody>
        </table>
      </div>
      <div className="card-footer">
        <div>
          <LoadMoreButton
            copyLoadMore={copyLoadMore}
            copyNoMore={copyNoMore}
            onClick={loadMore}
            hasNext={hasNext}
          />
        </div>
      </div>
    </div>
  );
}

PortfolioPropertyMemberListTable.propTypes = {
  data: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  hasNext: PropTypes.bool.isRequired, // eslint-disable-line react/forbid-prop-types
  loadMore: PropTypes.func.isRequired, // eslint-disable-line react/forbid-prop-types
  state: PropTypes.string.isRequired,
};

export default PortfolioPropertyMemberListTable;
