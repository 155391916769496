import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ButtonGroup } from 'reactstrap';

import {
  PLATFORM_MODE_REBATE, TRADE_DIRECTION_BUY,
  TRADE_DIRECTION_SELL, TRADE_DIRECTION_UNSPECIFIED,
  TRADE_RULE_STATE_ACCEPTED, TRADE_RULE_STATE_CANCELLED,
  TRADE_RULE_STATE_CLOSED, TRADE_RULE_STATE_PROPOSED,
  TRADE_RULE_STATE_REJECTED, TRADE_TYPE_RESIDUAL,
  TradeRuleStates,
} from 'src/util/constants';
import { convertEnergyPrice } from 'src/util/conversions';
import { getLocale, i18nDecimalFormat } from 'src/util/i18n/handler';
import isActive from 'src/util/isActive';
import { getMemberNodes } from 'src/util/mainDataBuilder';

import {
  ActionSpinner, ActionSpinnerWrapper, Step,
} from './tradeRuleConstants';

/**
 * Determines the current state of a trade rule based on the timestamp of the last trade rule
 * action.
 * @param {object} tradeRule - The trade rule object to analyze.
 * @returns {TradeRuleStates | null} The current state of the trade rule,
 * or null if the input is invalid.
 */
export const getTradeRuleState = (tradeRule) => {
  if (!tradeRule) {
    return null;
  }
  const {
    acceptedAt, rejectedAt, cancelledAt, closedAt,
  } = tradeRule;

  if (rejectedAt) {
    return TRADE_RULE_STATE_REJECTED;
  }
  if (cancelledAt) {
    return TRADE_RULE_STATE_CANCELLED;
  }
  if (closedAt) {
    return TRADE_RULE_STATE_CLOSED;
  }
  if (acceptedAt) {
    return TRADE_RULE_STATE_ACCEPTED;
  }
  return TRADE_RULE_STATE_PROPOSED;
};

export const getUserName = (userData) => {
  if (!userData) {
    return null;
  }
  const { email, familyName, givenName } = userData;
  const userName = [givenName, familyName].filter(Boolean).join(' ');
  if (userName) {
    return userName;
  }
  if (email) {
    return email;
  }
  return null;
};

/**
 * Attempt to determine the trade direction based on the buyer and seller details,
 * and a map of meters the user viewing the page has access to.
 * @param {object} buyer
 * @param {object} seller
 * @param {string} actorId the person who proposed the trade rule
 * @returns {object} an object containing the direction of the trade and counterparty
 */
export const getTradeData = (buyer, seller, actorId) => {
  if (actorId === buyer?.user?.uuid) {
    const buyerData = {
      direction: TRADE_DIRECTION_BUY,
      trader: buyer,
      counterParty: seller,
    };
    return buyerData;
  }
  if (actorId === seller?.user?.uuid) {
    const sellerData = {
      direction: TRADE_DIRECTION_SELL,
      trader: seller,
      counterParty: buyer,
    };
    return sellerData;
  }
  return { direction: TRADE_DIRECTION_UNSPECIFIED };
};

export const tradeRuleActions = (buttons) => {
  if (!buttons) {
    return null;
  }
  return (
    <Step className="align-items-center">
      <ActionSpinnerWrapper>
        <ActionSpinner type="grow" />
      </ActionSpinnerWrapper>
      <div className="d-flex ms-3 mt-2 mb-2">
        <ButtonGroup>
          {buttons}
        </ButtonGroup>
      </div>
    </Step>
  );
};

/**
 * Generates the trade rule(buy) price information for a given trade rule
 * @param {object} root0
 * @param {React.ReactElement} root0.detailsLink - Link to a trade rule (further details)
 * @param {number} root0.maximum
 * @param {number} root0.minimum
 * @param {'PLATFORM_MODE_REBATE'|'PLATFORM_TRADE'} root0.mode
 * @param {'TRADE_TYPE_CONTRACTED'|'TRADE_TYPE_NOMINATED'|'TRADE_TYPE_COMMUNITY'|
 * 'TRADE_TYPE_RESIDUAL'} root0.tradeType
 * @param {React.ReactElement} root0.units
 * @returns {React.ReactComponentElement} - i18n component that carries the (buy) pricing info
 */
export const buyPriceInformation = ({
  detailsLink, maximum, minimum, mode, tradeType, units,
}) => {
  if (mode === PLATFORM_MODE_REBATE && tradeType === TRADE_TYPE_RESIDUAL) {
    return (
      <em><FormattedMessage id="trade_rule.trade_rule_active.trade_rule_active_residual.buy.agreed_tariffs.label" defaultMessage="Agreed energy tariffs apply" /></em>
    );
  }
  if (mode === PLATFORM_MODE_REBATE && minimum === maximum) {
    return (
      <FormattedMessage
        id="trade_rule.trade_rule_active.trade_rule_helpers.buy.trade_rule_price.flat.platform_mode_rebate"
        defaultMessage="Discount of {price} {units} ({detailsLink})"
        values={{
          detailsLink,
          // TO DO: Implement d3-format locale support (PT-1124)
          price: <strong>{convertEnergyPrice(minimum)?.toFixed(3)}</strong>,
          units,
        }}
      />
    );
  }

  if (mode !== PLATFORM_MODE_REBATE && minimum === maximum) {
    return (
      <FormattedMessage
        id="trade_rule.trade_rule_active.trade_rule_helpers.buy.trade_rule_price.flat.platform_mode_trade"
        defaultMessage="Buy price of {price} {units} ({detailsLink})"
        values={{
          detailsLink,
          // TO DO: Implement d3-format locale support (PT-1124)
          price: <strong>{convertEnergyPrice(minimum)?.toFixed(3)}</strong>,
          units,
        }}
      />
    );
  }

  if (mode === PLATFORM_MODE_REBATE && minimum !== maximum) {
    return (
      <FormattedMessage
        id="trade_rule.trade_rule_active.trade_rule_helpers.buy.trade_rule_price.variable.platform_mode_rebate"
        defaultMessage="Discount of {minimum} to {maximum} {units} ({detailsLink})"
        values={{
          detailsLink,
          // TO DO: Implement d3-format locale support (PT-1124)
          maximum: <strong>{convertEnergyPrice(maximum)?.toFixed(3)}</strong>,
          minimum: <strong>{convertEnergyPrice(minimum)?.toFixed(3)}</strong>,
          units,
        }}
      />
    );
  }

  if (mode !== PLATFORM_MODE_REBATE && minimum !== maximum) {
    return (
      <FormattedMessage
        id="trade_rule.trade_rule_active.trade_rule_helpers.buy.trade_rule_price.variable.platform_mode_trade"
        defaultMessage="Buy price of {minimum} to {maximum} {units} ({detailsLink})"
        values={{
          detailsLink,
          // TO DO: Implement d3-format locale support (PT-1124)
          maximum: <strong>{convertEnergyPrice(maximum)?.toFixed(3)}</strong>,
          minimum: <strong>{convertEnergyPrice(minimum)?.toFixed(3)}</strong>,
          units,
        }}
      />
    );
  }

  return null;
};

/**
 * Generates the trade rule(sell) price information for a given trade rule
 * @param {object} root0
 * @param {any} root0.detailsLink - Link to a trade rule (further details)
 * @param {any} root0.maximum
 * @param {any} root0.minimum
 * @param {any} root0.mode
 * @param {any} root0.units
 * @param {'TRADE_TYPE_CONTRACTED'|'TRADE_TYPE_NOMINATED'|'TRADE_TYPE_COMMUNITY'|
 * 'TRADE_TYPE_RESIDUAL'} root0.tradeType
 * @returns {React.ReactComponentElement} - i18n component that carries the (sell) pricing info
 */

export const sellPriceInformation = ({
  detailsLink, maximum, minimum, mode, tradeType, units,
}) => {
  if (mode === PLATFORM_MODE_REBATE && tradeType === TRADE_TYPE_RESIDUAL) {
    return (
      <em><FormattedMessage id="trade_rule.trade_rule_active.trade_rule_active_residual.sell.agreed_tariffs.label" defaultMessage="Agreed energy tariffs apply" /></em>
    );
  }
  if (mode === PLATFORM_MODE_REBATE && minimum === maximum) {
    return (
      <FormattedMessage
        id="trade_rule.trade_rule_active.trade_rule_helpers.sell.rule.price.flat.platform_mode_rebate"
        defaultMessage="Discount of {price} {units} ({detailsLink})"
        values={{
          detailsLink,
          // TO DO: Implement d3-format locale support (PT-1124)
          price: <strong>{convertEnergyPrice(minimum).toFixed(3)}</strong>,
          units,
        }}
      />
    );
  }

  if (mode !== PLATFORM_MODE_REBATE && minimum === maximum) {
    return (
      <FormattedMessage
        id="trade_rule.trade_rule_active.trade_rule_helpers.sell.rule.price.flat.platform_mode_trade"
        defaultMessage="Sell price of {price} {units} ({detailsLink})"
        values={{
          detailsLink,
          // TO DO: Implement d3-format locale support (PT-1124)
          price: <strong>{i18nDecimalFormat(convertEnergyPrice(minimum).toFixed(3))}</strong>,
          units,
        }}
      />
    );
  }

  if (mode === PLATFORM_MODE_REBATE && minimum !== maximum) {
    return (
      <FormattedMessage
        id="trade_rule.trade_rule_active.trade_rule_helpers.sell.rule.price.variable.platform_mode_rebate"
        defaultMessage="Discount of {minimum} to {maximum} {units} ({detailsLink})"
        values={{
          detailsLink,
          // TO DO: Implement d3-format locale support (PT-1124)
          maximum: <strong>{convertEnergyPrice(maximum).toFixed(3)}</strong>,
          minimum: <strong>{convertEnergyPrice(minimum).toFixed(3)}</strong>,
          units,
        }}
      />
    );
  }

  if (mode !== PLATFORM_MODE_REBATE && minimum !== maximum) {
    return (
      <FormattedMessage
        id="trade_rule.trade_rule_active.trade_rule_helpers.sell.rule.price.variable.platform_mode_trade"
        defaultMessage="Sell price of {minimum} to {maximum} {units} ({detailsLink})"
        values={{
          detailsLink,
          // TO DO: Implement d3-format locale support (PT-1124)
          maximum: <strong>{convertEnergyPrice(maximum).toFixed(3)}</strong>,
          minimum: <strong>{convertEnergyPrice(minimum).toFixed(3)}</strong>,
          units,
        }}
      />
    );
  }

  return null;
};

/**
 * Finds a property from a list of user properties based on the given trade point ID.
 * @param {Array<object>} properties - An array of eligible properties.
 * @param {string} tradePointId - The ID of the trade point to find the property for.
 * @returns {object | undefined} - The property object with that trade point id if found,
 * or undefined if not found.
 */
export const getPropertyContainingMeterByTradePointId = (
  properties,
  tradePointId,
) => (properties?.find((property) => property.meters.edges.some(
  (meterEdge) => meterEdge.node.tradePointId === tradePointId,
)));

/**
 * Retrieves the appropriate trade point label based on the given trade direction.
 * @param {import('react-intl').IntlShape} intl - i18n react-intl.
 * @param {TRADE_DIRECTION_BUY | TRADE_DIRECTION_SELL
 * | TRADE_DIRECTION_UNSPECIFIED} direction - The trade direction.
 * @returns {string} The appropriate trade point label.
 */
export const getTradePointLabel = (intl, direction) => {
  let tradePointLabel;

  const tradePointLabelBuy = intl.formatMessage({ id: 'trade_rule.trade_rule_helpers.proposer_trade_point_id.buyer.text', defaultMessage: 'Choose the Trade Point you are buying for' });
  const tradePointLabelSell = intl.formatMessage({ id: 'trade_rule.trade_rule_helpers.proposer_trade_point_id.seller.text', defaultMessage: 'Choose the Trade Point you are selling from' });
  const tradePointLabelGeneral = intl.formatMessage({ id: 'trade_rule.trade_rule_helpers.proposer_trade_point_id.general.text', defaultMessage: 'Choose the Trade Point' });

  switch (direction) {
    case TRADE_DIRECTION_BUY:
      tradePointLabel = tradePointLabelBuy;
      break;
    case TRADE_DIRECTION_SELL:
      tradePointLabel = tradePointLabelSell;
      break;
    default:
      tradePointLabel = tradePointLabelGeneral;
      break;
  }
  return tradePointLabel;
};

/**
 * Retrieves a list of eligible properties and a list of eligible trade point IDs
 * from the provided array of `PropertyUser`s.
 * Eligible properties are those that have an active property user, are active
 * and have at least one active meter.
 * Eligible trade point IDs are the eligible properties' trade point IDs.
 * @param {Array<object>} propertyUsers - An array of property users objects.
 * @returns {object} - An object of eligible property and eligible trade point ids array.
 */
export const getEligiblePropertiesAndTradePointIds = (propertyUsers) => {
  const eligibleProperties = [];
  const eligibleTradePointIds = [];

  if (!propertyUsers?.length) {
    return { eligibleProperties, eligibleTradePointIds };
  }

  propertyUsers?.forEach((propertyUserEdge) => {
    const { active: activePropertyUser, property } = propertyUserEdge;
    const { meters, active: activeProperty } = property || {};
    if (!isActive(activePropertyUser)) { return; }
    if (!isActive(activeProperty)) { return; }

    let hasValidTradePoints = false;
    getMemberNodes(meters).forEach(({ active: activeMeter, tradePointId } = {}) => {
      if (!isActive(activeMeter)) { return; }
      eligibleTradePointIds.push(tradePointId);
      hasValidTradePoints = true;
    });

    if (hasValidTradePoints) { eligibleProperties.push(property); }
  });

  return { eligibleProperties, eligibleTradePointIds };
  // TO_DO: append properties accessible via the portfolios.
};

const locale = getLocale();

/**
 * Sorts an array of properties by their title in the current locale.
 * @param {Array<object>} properties - An array of property objects.
 * @returns {Array<object>} - The sorted array of property objects.
 */
export const sortPropertiesByTitle = (properties) => properties.sort(
  (a, b) => a.title.localeCompare(b.title, locale),
);
