import PropTypes from 'prop-types';
import React from 'react';

import { APIConfig } from 'src/config';

import ExternalNavLink from '../ExternalNavLink';

/**
 * Returns the ExternalNavLink component if applicable
 * (ie) there is a url and urlKey and they are not the same
 * @param {object} props
 * @param {string} props.url url to be redirected.
 * @param {string} props.urlKey i18n key for the url.
 * @param {React.ReactNode} props.chunks chunks to be rendered.
 * @param {string} props.target  where the link should open.
 * @returns {React.ReactComponentElement | React.ReactElement} - ExternalNavLink component
 */
function LinkInI18nBlock(props) {
  const {
    chunks, url, urlKey,
  } = props || {};

  if (!url || !urlKey || url === urlKey) return chunks;

  const target = APIConfig().getLinkTarget(url);

  return (
    <ExternalNavLink className="d-inline text-primary" href={url} target={target}>
      {chunks}
    </ExternalNavLink>
  );
}
export default LinkInI18nBlock;

LinkInI18nBlock.propTypes = {
  props: PropTypes.shape({
    chunks: PropTypes.node,
    target: PropTypes.string,
    url: PropTypes.string.isRequired,
    urlKey: PropTypes.string.isRequired,
  }),
};

LinkInI18nBlock.defaultProps = {
  props: {
    chunks: '',
    target: '',
  },
};
