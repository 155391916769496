/**
 * @generated SignedSource<<55a8d439979fbc653b3245104f274a49>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "tradePointId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "uuid"
},
v2 = [
  {
    "kind": "Variable",
    "name": "tradePointId",
    "variableName": "tradePointId"
  },
  {
    "kind": "Variable",
    "name": "uuid",
    "variableName": "uuid"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uuid",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "acceptedAt",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "givenName",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "familyName",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AcceptNominatedTradeMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "TradeRule",
        "kind": "LinkedField",
        "name": "acceptNominatedTrade",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "acceptedBy",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "AcceptNominatedTradeMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "TradeRule",
        "kind": "LinkedField",
        "name": "acceptNominatedTrade",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "acceptedBy",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8de1b13530f07d3039ee87cfbd225542",
    "id": null,
    "metadata": {},
    "name": "AcceptNominatedTradeMutation",
    "operationKind": "mutation",
    "text": "mutation AcceptNominatedTradeMutation(\n  $uuid: ID!\n  $tradePointId: String!\n) {\n  acceptNominatedTrade(uuid: $uuid, tradePointId: $tradePointId) {\n    id\n    uuid\n    acceptedAt\n    acceptedBy {\n      uuid\n      email\n      givenName\n      familyName\n      id\n    }\n  }\n}\n"
  }
};
})();

node.hash = "d70f874904340bb2bd8cbe1300c666a5";

module.exports = node;
