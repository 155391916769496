import { commitMutation, graphql } from 'react-relay';

import environment from 'src/util/environment';

const mutation = graphql`
  mutation ProposeNominatedTradeMutation($input: NominatedTradeRuleProposal!) {
    proposeNominatedTrade(input: $input) {
      id
      uuid
    }
  }
`;

const ProposeNominatedTradeMutation = (input, onCompleted, onError, options = {}) => {
  const variables = { input };

  commitMutation(
    environment,
    {
      mutation,
      variables,
      onCompleted: (response, errors) => { onCompleted(response, errors, options); },
      onError,
    },
  );
};

export default ProposeNominatedTradeMutation;
