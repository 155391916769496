import isIn from 'validator/es/lib/isIn';
import isUUID from 'validator/es/lib/isUUID';
import isEmail from 'validator/lib/isEmail';

import { TradeDirections } from 'src/util/constants';
import { getMemberNodes } from 'src/util/mainDataBuilder';
import { isValidPrice } from 'src/util/math';

/**
 * Validates the provided trade direction against the list of valid trade directions.
 * @param {string} direction - The trade direction to validate.
 * @returns {boolean} - True if the provided direction is part of the provided trade directions,
 * false otherwise.
 */
export const validateDirection = (direction) => isIn(direction, TradeDirections);

/**
 * Validates the provided price against a helper function.
 * @param {number} price - The price to validate.
 * @returns {boolean} - True if the provided price is valid, false otherwise.
 */
export const validatePrice = (price) => isValidPrice(price?.toString());

/**
 * Validates the provided proposer trade point ID against the UUID format.
 * @param {string} proposerTradePointId - The proposer trade point ID to validate.
 * @returns {boolean} - True if the provided ID is a valid UUID, false otherwise.
 */
export const validateProposerTradePointId = (proposerTradePointId) => (
  proposerTradePointId ? isUUID(proposerTradePointId) : false);

/**
 * Validates the provided recipient user email against the email format.
 * @param {string} recipientUserEmail - The recipient user email to validate.
 * @returns {boolean} - True if the provided email is valid, false otherwise.
 */
export const validateRecipientUserEmail = (recipientUserEmail) => isEmail(recipientUserEmail);

/**
 * Validates a trade rule by checking the following:
 * - The trade direction is valid
 * - The proposer trade point ID is a valid UUID
 * - The recipient user email is valid
 * - All clause prices are valid
 * @param {object} tradeRule - The trade rule to validate.
 * @returns {boolean} - True if the trade rule is valid, false otherwise.
 */
export const validateTradeRule = (tradeRule) => {
  if (!tradeRule) {
    return false;
  }
  return validateDirection(tradeRule.direction)
  && validateProposerTradePointId(tradeRule.proposerTradePointId)
  && validateRecipientUserEmail(tradeRule.recipientUserEmail)
  && tradeRule.clauses.every(({ price }) => validatePrice(price));
};

/**
 * Sets the initial proposer trade point ID based on the provided query parameter
 * and the available trade point properties.
 * If the query parameter is provided, it is returned.
 * If there are no trade point properties, the trade point id is not set.
 * If there is only one trade point property, its trade point ID is returned.
 * @param {string} queryProposerTradePointId - The proposer trade point ID from the query parameter.
 * @param {Array<object>} properties - The available trade point properties.
 * @param {Array<string>} eligibleTradePointIds - arary of eligible trade point IDs.
 * @returns {object} - The initial proposer trade point ID, or null if it cannot be determined.
 */
export const setInitialProposerTradePointId = (
  queryProposerTradePointId,
  properties,
  eligibleTradePointIds,
) => {
  // if trade point cannot be managed, display a message indicating it
  if (properties?.length === 0) {
    return { proposerTradePointId: null, message: { type: 'noActiveTradePoint' } };
  }

  if (queryProposerTradePointId) {
    if (eligibleTradePointIds?.includes(queryProposerTradePointId)) {
      return { proposerTradePointId: queryProposerTradePointId, message: null };
    }

    return { proposerTradePointId: null, message: { type: 'proposerTradePointIdInvalid' } };
  }

  // if only one trade point can be managed, prefill the form with that trade point
  if (properties?.length === 1) {
    const { meters } = properties[0] || {};
    const meterNodes = getMemberNodes(meters);
    if (meterNodes?.length === 1) {
      return { proposerTradePointId: meterNodes[0]?.tradePointId, message: null };
    }
  }

  return { proposerTradePointId: null, message: null };
};
