import PropTypes from 'prop-types';
import React, {
  createContext, useContext, useEffect, useMemo, useState,
} from 'react';

import FlashesStore from 'src/stores/FlashesStore';

export const FlashesContext = createContext(null);

/**
 * When a mutation succeeds, clear any existing error messages that have a
 * matching mutation name from the flashes store.
 * @param {Array<object>} flashes - flashes to filter
 * @param {string} mutationName - name of the mutation
 * @param {string} outcome - outcome of the mutation
 * @returns {Array<object>} - list of flashes
 */
export const removeErrorFlashesOnSuccess = (flashes, mutationName, outcome) => flashes.filter(
  (flash) => {
    if (!flash.mutationName
      || !mutationName
      || flash.mutationName !== mutationName) {
      return true;
    }

    if (flash.type !== 'error' || outcome !== 'success') {
      return true;
    }

    return false;
  },
);

/**
 *  Custom hook to access the FlashesContext values from any component in the tree.
 * @returns {object} The current FlashesContext value containing flashes state and methods
 */
export function useFlashes() {
  return useContext(FlashesContext);
}

/**
 * Provider component that manages flash messages state and functionality.
 * Provides flash-related state and methods to child components via context.
 * @param {object} props - Component props
 * @param {React.ReactNode} props.children - Child components that will have access to
 * the flashes context
 * @returns {React.ReactNode} The provider component wrapping the children with the FlashesContext
 */
function FlashesProvider({ children }) {
  const [flashes, setFlashes] = useState([]);
  const [lastConnected, setLastConnected] = useState(true);

  useEffect(() => {
    if (FlashesStore.preloaded) {
      setFlashes(FlashesStore.preloaded);
    }

    const handleStoreChange = (payload) => {
      setFlashes((prevFlashes) => {
        const updatedFlashes = removeErrorFlashesOnSuccess(
          prevFlashes,
          payload.mutationName,
          payload.type,
        );
        return [...updatedFlashes, payload];
      });
    };

    const handleStoreReset = () => {
      setFlashes((prevFlashes) => {
        const hasConnectionError = prevFlashes.length > 0
          && prevFlashes[0].id === 'FLASH_CONN_ERROR';
        return hasConnectionError ? prevFlashes.slice(0, 1) : [];
      });
    };

    FlashesStore.on('flash', handleStoreChange);
    FlashesStore.on('reset', handleStoreReset);

    // clean up on unmount
    return () => {
      FlashesStore.off('flash', handleStoreChange);
      FlashesStore.off('reset', handleStoreReset);
    };
  }, []);

  const removeFlash = (flash) => {
    setFlashes((prevFlashes) => (prevFlashes.filter((f) => flash.id !== f.id)));
  };

  const value = useMemo(() => ({
    flashes,
    removeFlash,
    lastConnected,
    setLastConnected,
  }), [flashes, lastConnected]);

  return (
    <FlashesContext.Provider value={value}>
      {children}
    </FlashesContext.Provider>
  );
}

FlashesProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FlashesProvider;
